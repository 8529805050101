.c-gallery,
.c-gallery_nav {
	max-width: 1062px;
	margin: 0 auto;
}
.c-gallery_media {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	aspect-ratio: 16/9;

	&.-video {
		&:before {
			content: "";
			position: absolute;
			inset: 0;
			width: 24px;
			height: 24px;
			margin: auto;
			background-image: url("../images/svg/play.svg");
			background-size: contain;
			z-index: 1;
		}
	}

	img {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
	}
}

.c-gallery_nav {
	margin-top: 24px;
}

.c-gallery_nav-item {
	margin-right: 12px;
	padding: 4px;
	border: 2px solid transparent;
	border-radius: 8px;
	cursor: pointer;

	&.slick-current {
		border-color: $red;
	}
}
