.o-icon {
	display: inline-flex;
	align-items: center;

	&.-date {
		&::before {
			content: url("../images/svg/calendar.svg");
			width: 16px;
			margin-top: 5px;
			margin-right: 14px;
		}
	}

	&.-type {
		&::before {
			content: url("../images/svg/schedule.svg");
			width: 16px;
			margin-top: 5px;
			margin-right: 14px;
		}
	}

	&.-city {
		&::before {
			content: url("../images/svg/location.svg");
			width: 16px;
			margin-top: 5px;
			margin-right: 14px;
		}
	}

	&.-grey1 {
		&:before {
			background-color: $grey-1;
		}
	}

	&.-grey3 {
		&:before {
			background-color: $grey-3;
		}
	}

	&.-download {
		&::before {
			content: "";
			width: 24px;
			height: 24px;
			mask-image: url("../images/svg/download.svg");
			mask-repeat: no-repeat;
			mask-size: contain;
			mask-position: center;
		}
	}

	&.-document {
		&::before {
			content: url("../images/svg/document.svg");
			width: 24px;
			height: 24px;
			mask-repeat: no-repeat;
			mask-size: contain;
			mask-position: center;
		}
	}

	&.-money {
		&::before {
			content: url("../images/svg/money.svg");
			width: 24px;
			height: 24px;
			mask-repeat: no-repeat;
			mask-size: contain;
			mask-position: center;
		}
	}

	&.-sell {
		&::before {
			content: url("../images/svg/sell.svg");
			width: 24px;
			height: 24px;
			mask-repeat: no-repeat;
			mask-size: contain;
			mask-position: center;
		}
	}

	&.-arrow-left {
		width: 24px;
		height: 24px;
		&::before {
			content: "";
			position: absolute;
			width: 24px;
			height: 24px;
			mask-image: url("../images/svg/arrow-left.svg");
			mask-repeat: no-repeat;
			mask-size: contain;
			mask-position: center;
		}
	}

	&.-arrow-right {
		width: 24px;
		height: 24px;
		&::before {
			content: "";
			position: absolute;
			width: 24px;
			height: 24px;
			mask-image: url("../images/svg/arrow-right.svg");
			mask-repeat: no-repeat;
			mask-size: contain;
			mask-position: center;
		}
	}
}
