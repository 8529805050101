.c-header {
	position: relative;
	display: flex;
	align-items: center;
	flex-flow: row wrap;
	justify-content: space-between;
	height: 75px;
	//margin-left: 75px;
	padding-left: 105px;
	box-shadow: 0px 0px 50px rgba($grey-3, 0.08);
	background: white;
	z-index: 11;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

	&.is-sticky {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		background: white;
		animation: headerIn 500ms ease forwards;
	}

	&__top {
		position: relative;
		display: flex;
		align-items: center;
		flex-flow: row wrap;
		justify-content: center;
		height: 35px;
		background: $red;
		color: white;
		text-align: center;
		text-transform: uppercase;
		font-family: $helv-regular;
		font-size: 15px;

		@media (max-width: 600px) {
			height: 45px;
			font-size: 13px;
		}

		& + .c-header__burger {
			top: 35px;

			@media (max-width: 600px) {
				top: 45px;
			}
		}
	}

	&.commercial {
		margin-left: 0px;

		@media screen and (max-width: 550px) {
			flex-flow: column nowrap;
			height: 150px;
			padding-left: 0;
		}

		.c-header__nav ul {
			justify-content: center;
			padding: 0px 10px;

			@media screen and (max-width: 900px) {
				display: none;
			}
		}

		.c-header__logo {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			height: 75px;

			img {
				width: 235px;

				@include mq(xsmall) {
					width: 155px;
				}
			}
		}

		.c-header__red {
			@media screen and (max-width: 550px) {
				width: 100%;
				height: 75px;
			}
		}
	}

	&__red {
		height: 100%;

		a {
			display: flex;
			align-items: center;
			flex-flow: row wrap;
			justify-content: center;
			height: 100%;
			padding: 10px 35px;
			background-color: $red;
			color: white;
			text-transform: uppercase;
			font-size: 1.5rem;

			&:hover {
				background-color: lighten($red, 8%);
			}
		}
	}

	&__burger {
		position: absolute;
		top: 0;
		left: 0;
		width: 75px;
		height: 75px;
		opacity: 1;
		background: $blue;
		cursor: pointer;
		transition: all 300ms ease;
		z-index: 99999;

		&:hover {
			background: lighten($blue, 10%);
		}

		&.is-sticky {
			position: fixed;
			top: 0;
			left: 0;
			animation: headerIn 500ms ease forwards;
		}

		&.active {
			.bar {
				&:nth-of-type(1) {
					transform: rotate(45deg) translateX(7px);
				}

				&:nth-of-type(2) {
					display: none;
					opacity: 0;
				}

				&:nth-of-type(3) {
					transform: rotate(135deg) translateX(-5px);
				}
			}
		}

		.icon {
			display: flex;
			align-items: center;
			flex-flow: column wrap;
			justify-content: center;
			width: 100%;
			height: 100%;

			.bar {
				display: block;
				width: 35px;
				height: 2px;
				margin: 4px 0px;
				background: white;
				transition: all 300ms ease;
			}
		}
	}

	&__logo {
		img {
			width: 207px;

			@include mq(xsmall) {
				width: 155px;
			}

			@media screen and (min-width: 1440px) {
				width: 235px;
			}
		}
	}

	&__nav {
		display: flex;
		align-items: center;
		flex-direction: row;
		flex-grow: 1;
		height: 100%;

		@media screen and (max-width: 1080px) {
			display: none;
			.c-header.commercial & {
				display: flex;
				ul {
					display: none;
				}
				.wpml-ls ul {
					display: block;
				}
			}
		}

		.wpml-ls {
			padding: 0;
			border: inherit;
			background-color: inherit;
			a {
				padding: 0;
				&:hover {
					background: none;
				}
			}
		}

		ul:not(.sub-menu) {
			display: flex;
			align-items: center;
			flex-direction: row;
			flex-grow: 1;
			justify-content: flex-end;
			margin: 0;
			list-style-type: none;

			& > li {
				& > a {
					position: relative;
					display: flex;
					align-items: center;
					flex-flow: row wrap;
					margin: 0px 20px;
					color: $grey-3;
					text-transform: uppercase;
					font-size: 1.6rem;
					line-height: 2.4rem;
					letter-spacing: 0.5px;

					/*@media screen and (max-width: 1300px) {*/
					@media screen and (max-width: 1450px) {
						margin: 0 10px;
					}

					@media screen and (max-width: 1200px) {
						margin: 0 5px;
					}

					&:hover {
						color: $grey-1;
					}

					i {
						margin: -5px 0 0 5px;
					}
				}

				.sub-menu {
					position: absolute;
					display: flex;
					align-items: flex-start;
					flex-flow: column nowrap;
					min-width: 300px;
					margin-left: 20px;
					padding-top: 40px;
					visibility: hidden;
					opacity: 0;
					border-radius: 0px 0px 8px 8px;
					box-shadow: 0px 40px 50px rgba($grey-3, 0.3);
					background: white;
					overflow: hidden;
					list-style-type: none;
					transition: all 300ms ease;
					pointer-events: none;

					li {
						margin: 20px 0 0;
						padding: 0 40px;

						&:last-child {
							a {
								margin-bottom: 40px;
							}
						}

						&:before {
							display: none;
						}

						span {
							width: 35px;
							margin-right: 15px;
						}

						&:nth-of-type(1) {
							img {
								width: 20px;
							}
						}

						&:nth-of-type(2) {
							img {
								width: 20px;
							}
						}

						&:nth-of-type(3) {
							img {
								width: 25px;
							}
						}

						&:nth-of-type(4) {
							img {
								width: 25px;
							}
						}

						&.sub-menu-button {
							flex-grow: 1;
							width: 100%;
							margin-top: 15px;
							margin-bottom: 0px;

							a {
								display: block;
								width: 100%;
								padding: 12px 30px;
								border-radius: 50px;
								background: $blue;
								color: white;
								text-align: center;
								text-align: center;
								font-family: $helv-regular;
								font-size: 1.6rem;
								line-height: 2rem;

								&:hover {
									&:after {
										color: white;
									}
								}
							}
						}
					}

					a {
						display: flex;
						align-items: center;
						flex-flow: row nowrap;
						flex-flow: row nowrap;
						margin: 0;
						color: $grey-3;
						text-transform: uppercase;
						font-size: 1.6rem;
						line-height: 2.4rem;

						&:hover {
							color: $red;
						}

						&:after {
							content: "\f0dd";
							display: inline-block;
							transform: rotate(-90deg);
							opacity: 0;
							transition: all 300ms ease;
							font-family: FontAwesome;
							font-size: 1.6rem;
						}
					}
				}

				&.menu-item-has-children {
					position: relative;

					a {
						&::after {
							content: url("../images/svg/arrow_down.svg");
							margin-left: 8px;
							transition: all 300ms ease-in-out;
							padding-bottom: 6px;
							
						}
					}

					&:hover {
						a {
							&::after {
								transform: rotateZ(180deg);
							}
						}

						.sub-menu {
							visibility: visible;
							opacity: 1;
							pointer-events: initial;

							li {
								margin: 20px 0 0;
								padding: 0 40px;
							}

							a {
								&::after {
									content: none;
								}
							}

							.last-element {
								display: flex;
								align-self: center;
								justify-content: center;
								width: 100%;
								margin-top: 40px;
								margin-bottom: 0;
								padding: 0;
								background: $grey-3;

								a {
									display: flex;
									align-items: center;
									justify-content: center;
									width: 100%;
									height: 100%;
									margin-bottom: 0;
									padding: 32px 0;
									color: $white;
								}

								&:hover {
									a {
										opacity: 0.5;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.menu {
		.border-right {
			padding-right: 8px;
			margin-right: 8px;
			border-right: 1px solid #d0dce8;
		}
	}

	.c-header_info {
		width: 235px;
		height: 100%;
		margin-left: 40px;
		background-color: $blue;
		transition: background-color 300ms ease-in-out;

		&:hover {
			background-color: lighten($blue, 10%);
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			color: $white;
			text-transform: uppercase;
			font-size: 1.6rem;
		}
	}

	.c-header__service {
		position: relative;
		display: flex;
		flex-flow: column wrap;
		width: 80px;
		background-color: $red;

		&:hover {
			background-color: lighten($red, 10%);
		}

		.grow {
			position: relative;
			display: flex;
			flex-flow: column wrap;
			flex-grow: 1;
			justify-content: center;
		}
	}

	.c-header__phone {
		display: flex;
		align-items: center;
		flex-flow: row wrap;
		justify-content: center;
		height: 75px;

		i {
			color: $white;
			font-size: 2.2rem;
		}
	}

	&__search {
		display: flex;
		align-items: center;
		flex-flow: row wrap;
		justify-content: center;
		width: 35px;
		height: 35px;
		margin-right: 20px;
		margin-left: 20px;
		border-radius: 100%;
		box-shadow: 0px 0px 25px rgba($grey-3, 0.35);
		background: $blue;

		@media screen and (max-width: 1300px) {
			margin-right: 0px;
		}

		@media screen and (max-width: 1080px) {
			margin-left: auto;
		}

		@include mq(small) {
			display: none;
		}

		i {
			color: white;
			font-size: 1.6rem;
		}
	}

	&__cart {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		margin-left: auto;
		padding: 0 20px;
		background-color: rgba($grey-1, 0.1);

		@media screen and (max-width: 1440px) {
			padding: 0 10px 0 5px;
		}

		@include mq(xsmall) {
			display: none;
		}

		a {
			font-size: 2rem;

			i {
				color: black;
			}
		}

		&-count {
			position: absolute;
			top: 16px;
			right: 10px;
			color: $blue;
			font-size: 13px;
		}
	}

	&__store {
		display: flex;
		align-items: center;
		flex-flow: row wrap;
		justify-content: center;
		//border-left:1px solid $grey-border;
		height: 100%;
		padding: 0px 30px;
		background-color: $blue;

		@include mq(xsmall) {
			display: none;
		}

		@media screen and (max-width: 1200px) {
			padding: 0 10px;
		}

		a {
			color: white;
			text-transform: uppercase;
			font-size: 1.4rem;
			line-height: 2rem;
			letter-spacing: 0.5px;
		}
	}
}

@keyframes headerIn {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}

