.c-search{
	display:flex;
	width:100%;
	justify-content: flex-end;

	&__form{
		width:100%;
	}

	&__text{
		border-radius: 50px;
		width:calc(100% - 140px);
		padding: 6px 30px 8px;
		border: 0;
		background: #ebecec;
		font-family: Helvetica-neue-condensed-regular;
		font-size: 1.6rem;
		line-height: 2.4rem;
		color: #3f474e;
		margin-top:5px;
		margin-bottom: 40px;
		outline: 0;

		@media (min-width: 768px) {
			width:33%;
		}
	}

	&__submit{
		background-color: #0054a4;
		border: 1px solid #fff;
		border-radius: 50px;
		padding: 10px 30px;
		font-size: 1.4rem;
		font-family: Helvetica-neue-condensed-regular;
		text-transform: uppercase;
		cursor: pointer;
		margin-top: 5px;
		margin-bottom: 5px;
		color: #fff;

		float:right;

		@media (min-width: 768px) {
			float:none;
		}
	}

	.c-form{
		padding-top:0;
		padding-bottom:0;
		background-color:transparent;
	}

	
}