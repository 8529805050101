.c-ferroviaire {
    &__block-text-image {
        padding: 150px 0 50px;

        .c-button {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            -webkit-transform: translate(-50%, 50%);
            display: inline-table;
        }
        .row{
          &.img-right{
            flex-direction: row-reverse;
          }
          @media screen and (max-width: 767px) {
            display: block;
            .col_1x2{
              width: 100%;
            }
          }
        }
    }
    &__cta{
      background: $blue;
      padding: 100px 0 1px;
      color: $white;
      .row{
        @media screen and (max-width: 767px) {
          display: block;
          .col_1x2{
            width: 100%;
          }
        }
      }
      h2{
        color: $white;
      }
      &-img{
        img{
          height: 100%;
          object-fit: cover;
        }
      }
      &-col{
        padding-left: 60px;
        @media screen and (max-width: 767px) {
          padding-left: 0px;
          margin-top: 40px;
        }
        .surtitle{
          font-size: 2rem;
          margin-bottom: 1rem;
          display: inline-block;
          font-family: $helv-regular;
        }
      }
      &-content{
        margin-top: 2rem;
        .content{
          p{
            color: $white;
            font-size: 1.5rem;
          }
        }
      }
      &-or{
        position: relative;
        text-align: center;
        display: block;
        margin: 0 auto;
        width: 100%;
        margin-bottom: 1.5rem;
        i{
          display: inline-block;
          background: $blue;
          padding: 0 20px;
          font-style: inherit;
          font-size: 2.2rem;
          position: relative;
          font-family: $helv-regular;
        }
        &:before{
          width: 100%;
          background: $white;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 1px;
          position: absolute;
          content: '';
          left: 0;
        }
      }
    }

}