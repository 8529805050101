.c-filter_item {
	padding: 16px;
	border-radius: 8px;
	background-color: $lightblue;
	cursor: pointer;
	transition: all 0.3s ease;
	text-align: center;

	&.is-active {
		background-color: $red;
	}

	&:hover {
		background-color: $darkblue;
	}

	input {
		position: absolute;
		left: 0;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		border: 0;
		overflow: hidden;
		white-space: nowrap;
		clip: rect(0, 0, 0, 0);
	}
}

.c-filters_title {
	margin-top: 8px;
	margin-bottom: 0;
	color: $darkblue;
	transition: color 0.3s ease;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;

	.c-filter_item:hover &,
	.c-filter_item.is-active & {
		color: white;
	}
}

.c-filters_media {
	position: relative;
	width: 24px;
	height: 24px;
	margin: 0 auto;

	img {
		position: absolute;
		inset: 0;
		transition: filter 0.3s ease;
		object-fit: contain;

		.c-filter_item:hover &,
		.c-filter_item.is-active & {
			filter: brightness(0) invert(1);
		}
	}
}

[data-filter-item]:not(.is-match),
.data-filter-item[data-pagination-item="false"] {
	display: none !important;
}
