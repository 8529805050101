.c-content {
	padding:100px 0px;
	&.no-pb {
		padding-bottom: 0px;
	}

	&.service-expertise {

		@include mq(medium) {
			padding-bottom:40px;
		}

		.row {
			@include mq(medium) {
				margin-bottom:40px;
			}
		}
		
		.col_1x2 {

			@include mq(medium) {
				width:100%;
			}

			&:last-of-type {

				@include mq(medium) {
					display:none;
				}
			}
		}

	}

	&.grey {
		background-color:$grey-bg-2;
	}

	img {
		width:100%;
	}

	.no-gutter {

		.col_1x2 {
			width:45%;
		}
	}

	&__mw {
		max-width:950px;
		margin: 0 auto 100px;

		@include mq(small) {
			margin-bottom:20px;
		}
	}

	.row {
		@include mq(small) {
			flex-flow:column nowrap;
		}

		.col_1x2 {
			
			@include mq(small) {
				width:100%;
				margin-bottom:20px;
			}
		}

		&.half-mb {
			@include mq(xsmall) {
				margin-bottom:30px;
			}
		}
	}
}
.c-content-page{
	background-color: white;
	.c-produits__categories-banner{
		margin-bottom: 40px;
	}
	.c-content--info{
		margin-bottom: 60px;
	}
}