.c-media-text {
	.c-media-text_content {
		display: flex;
		justify-content: space-between;

		@media (max-width: 768px) {
			flex-direction: column;
			gap: 20px;
		}

		&.-center {
			align-items: center;
		}

		&.-left {
			flex-direction: row-reverse;

			@media (max-width: 768px) {
				flex-direction: column;
			}
		}

		&.-gallery {
			gap: 132px;

			@media (max-width: 768px) {
				gap: 48px;
			}
		}
	}

	.c-media-text_desc {
		width: 40%;

		@media (max-width: 768px) {
			width: 100%;
		}

		&.-compact {
			width: 30%;

			@media (max-width: 768px) {
				width: 100%;
			}
		}

		h4 {
			color: $blue;
			text-transform: uppercase;
		}
	}

	.c-media-text_img {
		width: 40%;

		@media (max-width: 768px) {
			width: 100%;
		}

		img {
			width: 100%;
			border-radius: 8px;
		}

		&.-big {
			width: 40%;
			max-height: 600px;

			@media (max-width: 768px) {
				display: none;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.c-media-text_second-img {
		width: 30%;

		@media (max-width: 768px) {
			width: 100%;
		}

		img {
			width: 100%;
			height: 70%;
			border-radius: 8px;
			object-fit: cover;
		}
	}
}
