.c-financement {
	padding: 100px 0px;
	background: $grey-bg;

	ul {
		li {
			@include mq(small) {
				font-size: 1.6rem;
				line-height: 2.4rem;
			}
		}
	}

	.c-financement_btn {
		margin-top: 50px;
	}
}
