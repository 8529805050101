.c-promo-listing {
	position: relative;
	padding: 120px 80px;
	overflow: hidden;
	background-color: $grey-3;

	@media (max-width: 768px) {
		padding: 60px 40px;
	}
}

.c-promo-listing_header {
	max-width: 1200px;
	margin: auto;
	margin-bottom: 80px;

	@media (max-width: 500px) {
		margin-bottom: 160px;
	}

	h2 {
		max-width: 100%;
		color: $white;
		text-transform: uppercase;
		font-family: "Helvetica-neue-condensed-black";
		font-size: 7em;

		@media (max-width: 768px) {
			font-size: 4em;
		}
	}

	p {
		width: 50%;
		font-size: 24px;

		@media (max-width: 500px) {
			width: 100%;
		}
	}
}

.c-promo-listing_slider {
	// max-width: 1200px;
	// margin: auto;
	width: 1px;
	width: 100%;
	min-width: 100%;

	.slick-track {
		display: flex !important;
	}

	.slick-slide {
		height: inherit !important;
	}

	.slick-list {
		overflow: inherit;
	}

	img {
		opacity: 1 !important;
	}

	.slick-arrow {
		position: absolute;
		top: -195px;
		right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		height: 80px;
		background-color: $white;
		cursor: pointer;
		transition: all 150ms ease-in-out;

		@media (max-width: 768px) {
			width: 40px;
			height: 40px;
		}

		@media (max-width: 500px) {
			top: -125px;
			border-radius: 8px !important;
		}

		&:hover {
			background-color: $red;
		}

		&::after {
			content: url("../images/svg/arrow_forward_black.svg");
		}
	}

	.prevArrow {
		right: 90px;
		border-right: 1px solid #d0dce8;
		border-radius: 8px 0 0 8px;

		@media (max-width: 500px) {
			right: unset;
			left: 10px;
			border: none;
		}
	}

	.nextArrow {
		border-radius: 0 8px 8px 0;

		&::after {
			margin-bottom: 4px;
			transform: scale(-1);
		}
	}
}

.c-promo-listing_slide {
	margin-right: 24px;
}

.c-promo-listing_single {
	display: flex !important;
	flex-direction: column;
	height: 700px;
	margin-right: 12px;
	margin-left: 12px;
}
