/* =======================================================================
Core
========================================================================== */
/* =======================================================================
Responsive
========================================================================== */
/* =======================================================================
Font-face
========================================================================== */
@font-face {
  font-family: "Helvetica-neue-condensed-regular";
  src: url("../fonts/HelveticaNeueCondensed/HelveticaNeue-Condensed.ttf") format("truetype"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-Condensed.woff") format("woff"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-Condensed.woff2") format("woff2"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-Condensed.eot"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-Condensed.eot#iefix") format("embedded-opentype");
}

@font-face {
  font-family: "Helvetica-neue-condensed-bold";
  src: url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBold.ttf") format("truetype"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBold.woff") format("woff"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBold.woff2") format("woff2"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBold.eot"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBold.eot#iefix") format("embedded-opentype");
}

@font-face {
  font-family: "Helvetica-neue-condensed-black";
  src: url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBlack.ttf") format("truetype"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBlack.woff") format("woff"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBlack.woff2") format("woff2"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBlack.eot"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBlack.eot#iefix") format("embedded-opentype");
}

/* =======================================================================
Variables
========================================================================== */
/* =======================================================================
Helpers
========================================================================== */
/* line 19, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.h-centered {
  text-align: center;
}

/* line 23, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.h-clearfix:before,
.h-clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

/* line 29, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.h-clearfix:after {
  clear: both;
}

/* line 33, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.h-clearfix {
  *zoom: 1;
}

/* line 37, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.h-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 46, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.h-visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

/* line 57, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.h-embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

/* line 65, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.h-embed-responsive iframe,
.h-embed-responsive embed,
.h-embed-responsive object,
.h-embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* line 78, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

/* line 82, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-4by3 {
  padding-bottom: 75%;
}

/* line 172, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid {
  display: grid;
}

/* line 176, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

/* line 180, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

/* line 176, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

/* line 180, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

/* line 176, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

/* line 180, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

/* line 176, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

/* line 180, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

/* line 176, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

/* line 180, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

/* line 176, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

/* line 180, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

/* line 176, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

/* line 180, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}

/* line 176, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

/* line 180, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

@media (max-width: 1440px) {
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-1\@desktop {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-1\@desktop {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-2\@desktop {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-2\@desktop {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-3\@desktop {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-3\@desktop {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-4\@desktop {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-4\@desktop {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-5\@desktop {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-5\@desktop {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-6\@desktop {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-6\@desktop {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-7\@desktop {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-7\@desktop {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-8\@desktop {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-8\@desktop {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }
}

@media (max-width: 1000px) {
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-1\@laptop {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-1\@laptop {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-2\@laptop {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-2\@laptop {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-3\@laptop {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-3\@laptop {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-4\@laptop {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-4\@laptop {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-5\@laptop {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-5\@laptop {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-6\@laptop {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-6\@laptop {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-7\@laptop {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-7\@laptop {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-8\@laptop {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-8\@laptop {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }
}

@media (max-width: 700px) {
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-1\@tablet {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-1\@tablet {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-2\@tablet {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-2\@tablet {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-3\@tablet {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-3\@tablet {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-4\@tablet {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-4\@tablet {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-5\@tablet {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-5\@tablet {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-6\@tablet {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-6\@tablet {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-7\@tablet {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-7\@tablet {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-8\@tablet {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-8\@tablet {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }
}

@media (max-width: 425px) {
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-1\@mobile {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-1\@mobile {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-2\@mobile {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-2\@mobile {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-3\@mobile {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-3\@mobile {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-4\@mobile {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-4\@mobile {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-5\@mobile {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-5\@mobile {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-6\@mobile {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-6\@mobile {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-7\@mobile {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-7\@mobile {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }
  /* line 187, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-cols-8\@mobile {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  /* line 190, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-rows-8\@mobile {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }
}

/* line 197, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-xxs {
  gap: 8px;
}

/* line 201, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-y-xxs {
  row-gap: 8px;
}

/* line 205, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-x-xxs {
  column-gap: 8px;
}

/* line 197, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-xs {
  gap: 16px;
}

/* line 201, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-y-xs {
  row-gap: 16px;
}

/* line 205, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-x-xs {
  column-gap: 16px;
}

/* line 197, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-sm {
  gap: 24px;
}

/* line 201, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-y-sm {
  row-gap: 24px;
}

/* line 205, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-x-sm {
  column-gap: 24px;
}

/* line 197, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap {
  gap: 32px;
}

/* line 201, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-y {
  row-gap: 32px;
}

/* line 205, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-x {
  column-gap: 32px;
}

/* line 197, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-lg {
  gap: 40px;
}

/* line 201, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-y-lg {
  row-gap: 40px;
}

/* line 205, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-x-lg {
  column-gap: 40px;
}

/* line 197, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-xl {
  gap: 48px;
}

/* line 201, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-y-xl {
  row-gap: 48px;
}

/* line 205, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-x-xl {
  column-gap: 48px;
}

/* line 197, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-xxl {
  gap: 64px;
}

/* line 201, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-y-xxl {
  row-gap: 64px;
}

/* line 205, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-x-xxl {
  column-gap: 64px;
}

/* line 197, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-xxxl {
  gap: 80px;
}

/* line 201, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-y-xxxl {
  row-gap: 80px;
}

/* line 205, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
.o-grid.-gap-x-xxxl {
  column-gap: 80px;
}

@media (max-width: 1440px) {
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xxs\@desktop {
    gap: 8px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xxs\@desktop {
    row-gap: 8px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xxs\@desktop {
    column-gap: 8px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xs\@desktop {
    gap: 16px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xs\@desktop {
    row-gap: 16px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xs\@desktop {
    column-gap: 16px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-sm\@desktop {
    gap: 24px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-sm\@desktop {
    row-gap: 24px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-sm\@desktop {
    column-gap: 24px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap\@desktop {
    gap: 32px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y\@desktop {
    row-gap: 32px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x\@desktop {
    column-gap: 32px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-lg\@desktop {
    gap: 40px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-lg\@desktop {
    row-gap: 40px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-lg\@desktop {
    column-gap: 40px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xl\@desktop {
    gap: 48px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xl\@desktop {
    row-gap: 48px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xl\@desktop {
    column-gap: 48px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xxl\@desktop {
    gap: 64px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xxl\@desktop {
    row-gap: 64px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xxl\@desktop {
    column-gap: 64px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xxxl\@desktop {
    gap: 80px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xxxl\@desktop {
    row-gap: 80px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xxxl\@desktop {
    column-gap: 80px;
  }
}

@media (max-width: 1000px) {
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xxs\@laptop {
    gap: 8px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xxs\@laptop {
    row-gap: 8px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xxs\@laptop {
    column-gap: 8px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xs\@laptop {
    gap: 16px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xs\@laptop {
    row-gap: 16px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xs\@laptop {
    column-gap: 16px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-sm\@laptop {
    gap: 24px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-sm\@laptop {
    row-gap: 24px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-sm\@laptop {
    column-gap: 24px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap\@laptop {
    gap: 32px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y\@laptop {
    row-gap: 32px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x\@laptop {
    column-gap: 32px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-lg\@laptop {
    gap: 40px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-lg\@laptop {
    row-gap: 40px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-lg\@laptop {
    column-gap: 40px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xl\@laptop {
    gap: 48px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xl\@laptop {
    row-gap: 48px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xl\@laptop {
    column-gap: 48px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xxl\@laptop {
    gap: 64px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xxl\@laptop {
    row-gap: 64px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xxl\@laptop {
    column-gap: 64px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xxxl\@laptop {
    gap: 80px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xxxl\@laptop {
    row-gap: 80px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xxxl\@laptop {
    column-gap: 80px;
  }
}

@media (max-width: 700px) {
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xxs\@tablet {
    gap: 8px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xxs\@tablet {
    row-gap: 8px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xxs\@tablet {
    column-gap: 8px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xs\@tablet {
    gap: 16px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xs\@tablet {
    row-gap: 16px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xs\@tablet {
    column-gap: 16px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-sm\@tablet {
    gap: 24px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-sm\@tablet {
    row-gap: 24px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-sm\@tablet {
    column-gap: 24px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap\@tablet {
    gap: 32px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y\@tablet {
    row-gap: 32px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x\@tablet {
    column-gap: 32px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-lg\@tablet {
    gap: 40px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-lg\@tablet {
    row-gap: 40px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-lg\@tablet {
    column-gap: 40px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xl\@tablet {
    gap: 48px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xl\@tablet {
    row-gap: 48px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xl\@tablet {
    column-gap: 48px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xxl\@tablet {
    gap: 64px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xxl\@tablet {
    row-gap: 64px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xxl\@tablet {
    column-gap: 64px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xxxl\@tablet {
    gap: 80px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xxxl\@tablet {
    row-gap: 80px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xxxl\@tablet {
    column-gap: 80px;
  }
}

@media (max-width: 425px) {
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xxs\@mobile {
    gap: 8px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xxs\@mobile {
    row-gap: 8px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xxs\@mobile {
    column-gap: 8px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xs\@mobile {
    gap: 16px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xs\@mobile {
    row-gap: 16px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xs\@mobile {
    column-gap: 16px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-sm\@mobile {
    gap: 24px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-sm\@mobile {
    row-gap: 24px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-sm\@mobile {
    column-gap: 24px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap\@mobile {
    gap: 32px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y\@mobile {
    row-gap: 32px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x\@mobile {
    column-gap: 32px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-lg\@mobile {
    gap: 40px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-lg\@mobile {
    row-gap: 40px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-lg\@mobile {
    column-gap: 40px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xl\@mobile {
    gap: 48px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xl\@mobile {
    row-gap: 48px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xl\@mobile {
    column-gap: 48px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xxl\@mobile {
    gap: 64px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xxl\@mobile {
    row-gap: 64px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xxl\@mobile {
    column-gap: 64px;
  }
  /* line 212, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-xxxl\@mobile {
    gap: 80px;
  }
  /* line 216, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-y-xxxl\@mobile {
    row-gap: 80px;
  }
  /* line 220, public/wp-content/themes/performair/assets/src/scss/core/_helpers.scss */
  .o-grid.-gap-x-xxxl\@mobile {
    column-gap: 80px;
  }
}

/* =======================================================================
Global
========================================================================== */
/* line 1, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
html {
  overflow-x: hidden;
  font-size: 10px;
}

/* line 6, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
body {
  margin: 0px;
  background: #fbfbfb;
}

/* line 13, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
html.hidden,
body.hidden {
  overflow: hidden;
}

/* line 18, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 28, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h1,
h2,
h3,
h4 {
  margin-top: 0px;
}

/* line 34, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h1.darkblue,
h2.darkblue,
h3.darkblue,
h4.darkblue {
  color: #0f46a0;
  text-transform: uppercase;
}

/* line 39, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h1.red,
h2.red,
h3.red,
h4.red {
  color: #eb0a0f;
  text-transform: uppercase;
}

/* line 44, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h1.white,
h2.white,
h3.white,
h4.white {
  color: #fff;
  text-transform: uppercase;
}

/* line 50, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h1 {
  max-width: 600px;
  margin-bottom: 20px;
  color: #3f474e;
  text-transform: uppercase;
  font-size: 7rem;
  line-height: 7rem;
  font-family: "Helvetica-neue-condensed-black";
}

@media (max-width: 992px) {
  /* line 50, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h1 {
    font-size: 5rem;
    line-height: 5.5rem;
  }
}

@media (max-width: 992px) {
  /* line 50, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h1 {
    font-size: 4rem;
    line-height: 4.5rem;
  }
}

@media (max-width: 479px) {
  /* line 50, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h1 {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

/* line 74, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h1 span {
  display: block;
  color: #3f474e;
  text-transform: uppercase;
  font-size: 5rem;
  line-height: 6rem;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 83, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h1.-blue {
  color: #0f46a0;
}

/* line 87, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h1.medium {
  max-width: inherit;
  color: #3f474e;
  text-transform: initial;
  font-size: 4.8rem;
  line-height: 5.5rem;
  font-family: "Helvetica-neue-condensed-bold";
}

@media (max-width: 768px) {
  /* line 87, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h1.medium {
    font-size: 3.8rem;
    line-height: 4.5rem;
  }
}

@media (max-width: 479px) {
  /* line 87, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h1.medium {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

/* line 103, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h1.medium.left {
  text-align: left;
}

/* line 108, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h1.small {
  max-width: inherit;
  color: #3f474e;
  text-transform: initial;
  font-size: 4rem;
  line-height: 4.5rem;
  font-family: "Helvetica-neue-condensed-bold";
}

@media screen and (max-width: 1200px) {
  /* line 108, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h1.small {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

@media screen and (max-width: 1200px) {
  /* line 108, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h1.small {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

@media (max-width: 479px) {
  /* line 108, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h1.small {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

/* line 132, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h2 {
  margin-bottom: 20px;
  color: #3f474e;
  font-size: 4.8rem;
  line-height: 5.4rem;
  font-family: "Helvetica-neue-condensed-bold";
}

@media screen and (max-width: 1200px) {
  /* line 132, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h2 {
    font-size: 4rem;
    line-height: 4.5rem;
  }
}

@media (max-width: 768px) {
  /* line 132, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h2 {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

/* line 149, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h2.medium {
  font-size: 4rem;
  line-height: 4.5rem;
}

@media (max-width: 992px) {
  /* line 149, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h2.medium {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

@media (max-width: 768px) {
  /* line 149, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h2.medium {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }
}

/* line 164, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h2.small {
  font-size: 2.4rem;
  line-height: 3rem;
}

/* line 169, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h2.white {
  color: white;
  font-size: 2.2rem;
  line-height: 2.8rem;
}

/* line 176, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h3 {
  margin-bottom: 0px;
  color: #3f474e;
  font-size: 3rem;
  line-height: 3.8rem;
  font-family: "Helvetica-neue-condensed-black";
}

/* line 183, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h3.large {
  display: block;
  font-size: 4rem;
  line-height: 4.6rem;
  font-family: "Helvetica-neue-condensed-bold";
}

@media (max-width: 992px) {
  /* line 183, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h3.large {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

/* line 195, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h3.small {
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-family: "Helvetica-neue-condensed-bold";
}

@media (max-width: 768px) {
  /* line 195, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h3.small {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
}

/* line 206, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h3 span {
  display: block;
  font-size: 2.4rem;
  line-height: 3rem;
}

/* line 212, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h3.white {
  color: white;
}

/* line 217, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
h4 {
  margin-bottom: 10px;
  color: #3f474e;
  font-size: 2.6rem;
  line-height: 3.4rem;
  font-family: "Helvetica-neue-condensed-bold";
}

@media (max-width: 992px) {
  /* line 217, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  h4 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

/* line 230, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
p {
  margin-top: 0px;
  color: #89949f;
  font-size: 2rem;
  line-height: 3rem;
  font-family: "Helvetica-neue-condensed-regular";
}

@media (max-width: 768px) {
  /* line 230, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

/* line 242, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
p.large {
  font-size: 2rem;
  line-height: 3rem;
}

@media (max-width: 768px) {
  /* line 242, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  p.large {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}

/* line 252, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
p.medium {
  font-size: 2.2rem;
  line-height: 3rem;
}

@media (max-width: 992px) {
  /* line 252, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  p.medium {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

/* line 262, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
p.small {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

/* line 267, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
p.white {
  color: white;
  font-size: 1.3rem;
  line-height: 1.9rem;
}

/* line 274, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
a {
  transition: all 300ms ease;
  text-decoration: none;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 280, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
img {
  max-width: 100%;
}

/* line 284, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
ul {
  margin: 0 0 20px 18px;
  padding: 0;
  list-style-type: circle;
  list-style-position: outside;
}

/* line 290, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
ul li {
  color: #89949f;
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 298, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
textarea {
  resize: vertical;
}

/* line 302, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
span.blue {
  color: #0f46a0;
}

/* line 306, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
span.orange {
  color: #eb0a0f;
}

/* line 310, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
.pre-title {
  display: block;
  margin-bottom: 10px;
  color: #89949f;
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 319, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
.pre-title:before {
  content: "";
  display: inline-block;
  width: 0px;
  height: 0px;
  margin-bottom: -12px;
  margin-left: 10px;
  transform: rotate(45deg);
  border-top: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #0f46a0;
}

/* line 334, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
.arrow-list {
  margin: 30px 0px 50px;
}

/* line 337, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
.arrow-list li {
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin: 5px 0px;
  color: #89949f;
  font-size: 2.2rem;
  line-height: 3rem;
  font-family: "Helvetica-neue-condensed-regular";
}

@media (max-width: 479px) {
  /* line 337, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
  .arrow-list li {
    color: #3f474e;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}

/* line 354, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
.arrow-list li:before {
  content: "\f0dd";
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  transform: rotate(-90deg);
  color: #0f46a0;
  text-rendering: auto;
  font: normal normal normal 22px/1 FontAwesome;
}

/* line 392, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
.page-content {
  position: relative;
  height: 100%;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 99999;
}

/* line 399, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
.page-content.active {
  opacity: 1;
}

@keyframes loading {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  20% {
    visibility: visible;
    opacity: 1;
  }
  70% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes fadeIcon {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* line 436, public/wp-content/themes/performair/assets/src/scss/global/_base.scss */
.cky-modal > * {
  font-family: "Helvetica-neue-condensed-regular" !important;
}

/* =======================================================================
Layout
========================================================================== */
/* line 5, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.wrapper {
  position: relative;
}

/* line 8, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.wrapper.c-home__about {
  padding: 200px 0px 50px;
}

@media (max-width: 992px) {
  /* line 8, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .wrapper.c-home__about {
    padding: 120px 0px 50px;
  }
}

/* line 19, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section {
  padding: 120px 0;
  opacity: 0;
  transition: all 1s ease-in-out;
}

@media (max-width: 768px) {
  /* line 19, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-section {
    padding: 60px 0;
  }
}

/* line 30, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section.-white.-collapse + .o-section.-white.-collapse {
  padding-top: 0;
}

/* line 30, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section.-ligthblue.-collapse + .o-section.-ligthblue.-collapse {
  padding-top: 0;
}

/* line 36, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section.is-inview, .o-section.-not-anim {
  opacity: 1;
}

/* line 41, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section.-compact {
  padding: 60px 0;
}

@media (max-width: 768px) {
  /* line 41, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-section.-compact {
    padding: 30px 0;
  }
}

/* line 49, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section.-no-spacing-top {
  padding: 0 0px 120px 0px;
}

@media (max-width: 768px) {
  /* line 49, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-section.-no-spacing-top {
    padding: 0 0 60px 0;
  }
}

/* line 57, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section.-no-spacing-bottom {
  padding: 120px 0px 0px 0px;
}

@media (max-width: 768px) {
  /* line 57, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-section.-no-spacing-bottom {
    padding: 60px 0 0px 0;
  }
}

/* line 65, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section.-lightblue {
  background-color: #eef4ff;
}

/* line 69, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section.-white {
  background-color: #fff;
}

/* line 73, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section.-hidden {
  overflow: hidden;
}

/* line 78, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section_header {
  display: flex;
  gap: 25px;
  padding-bottom: 48px;
}

@media (max-width: 1000px) {
  /* line 78, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-section_header {
    flex-direction: column;
  }
}

@media (min-width: 1001px) {
  /* line 87, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-section_header.-carousel {
    align-items: center;
  }
}

/* line 94, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section_overline {
  margin-bottom: 24px;
  color: #212121;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 32px;
}

/* line 101, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section_title {
  max-width: inherit;
  color: #24388a;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 96px;
  line-height: 1;
}

@media (max-width: 1000px) {
  /* line 101, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-section_title {
    font-size: 64px;
  }
}

@media (max-width: 700px) {
  /* line 101, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-section_title {
    font-size: 56px;
  }
}

/* line 116, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section_title.-md {
  font-size: 64px;
}

@media (max-width: 700px) {
  /* line 116, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-section_title.-md {
    font-size: 48px;
  }
}

@media (min-width: 1001px) {
  /* line 125, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-section_heading {
    width: 66%;
  }
}

/* line 131, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section_description {
  flex: 1;
}

/* line 134, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section_description.-border {
  padding-top: 24px;
  border-top: 1px solid #d0dce8;
}

/* line 140, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section_actions {
  display: flex;
  flex: 1;
  gap: 4px;
}

@media (min-width: 1001px) {
  /* line 140, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-section_actions {
    justify-content: end;
  }
}

/* line 150, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-section_filters {
  padding-bottom: 32px;
}

/* line 154, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-container {
  padding: 0 80px;
}

@media (max-width: 768px) {
  /* line 154, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-container {
    padding: 0 30px;
  }
}

/* line 161, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-container.-no-spacing {
  padding: 0;
}

/* line 165, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.o-container.-xs {
  padding: 0 296px;
}

@media (max-width: 991px) {
  /* line 165, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-container.-xs {
    padding: 0 100px;
  }
}

@media (max-width: 768px) {
  /* line 165, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .o-container.-xs {
    padding: 0 30px;
  }
}

/* line 178, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-right: 40px;
  padding-left: 40px;
}

@media (max-width: 479px) {
  /* line 178, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

/* line 191, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-right: -2%;
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  /* line 191, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
  .row {
    margin-bottom: 60px;
  }
}

/* line 202, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.row.half-mb {
  margin-bottom: 50px;
}

/* line 206, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.row.align-center {
  align-items: center;
}

/* line 210, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.row.align-start {
  align-items: flex-start;
}

/* line 214, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.row.no-gutter {
  margin-right: 0;
}

/* line 217, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.row.no-gutter .col_1x2 {
  width: 50%;
  margin-right: 0;
}

/* line 221, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.row.no-gutter .col_1x3 {
  width: 33.33%;
  margin-right: 0;
}

/* line 225, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.row.no-gutter .col_2x3 {
  width: 65%;
  margin-right: 0;
}

/* line 230, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.row.no-gutter .col_1x4 {
  width: 25%;
  margin-right: 0;
}

/* line 235, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.row.no-gutter .col_2x5 {
  width: 40%;
  margin-right: 0;
}

/* line 240, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.row.no-gutter .col_3x5 {
  width: 60%;
  margin-right: 0;
}

/* line 246, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.row.no-mb {
  margin-bottom: 0px;
}

/* line 251, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.grow {
  flex-grow: 1;
}

/* line 255, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.col_1x1 {
  width: 100%;
}

/* line 258, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.col_1x1.center {
  text-align: center;
}

/* line 262, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.col_1x1.right {
  text-align: right;
}

/* line 267, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.col_1x2,
.col_1x3,
.col_1x4 {
  position: relative;
}

/* line 273, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.col_1x2 {
  width: 48%;
  margin-right: 2%;
}

/* line 277, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.col_1x2.right {
  text-align: right;
}

/* line 282, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.col_1x3 {
  width: 31%;
  margin-right: 2%;
}

/* line 287, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.col_1x4 {
  width: 23%;
  margin-right: 2%;
}

/* line 292, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.col_2x5 {
  width: 38%;
  margin-right: 2%;
}

/* line 297, public/wp-content/themes/performair/assets/src/scss/global/_layout.scss */
.col_3x5 {
  width: 58%;
  margin-right: 2%;
}

/* =======================================================================
Components
========================================================================== */
/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;
  height: 75px;
  padding-left: 105px;
  box-shadow: 0px 0px 50px rgba(33, 33, 33, 0.08);
  background: white;
  z-index: 11;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header.is-sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  animation: headerIn 500ms ease forwards;
}

/* line 24, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__top {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  height: 35px;
  background: #eb0a0f;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 15px;
}

@media (max-width: 600px) {
  /* line 24, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__top {
    height: 45px;
    font-size: 13px;
  }
}

/* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__top + .c-header__burger {
  top: 35px;
}

@media (max-width: 600px) {
  /* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__top + .c-header__burger {
    top: 45px;
  }
}

/* line 52, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header.commercial {
  margin-left: 0px;
}

@media screen and (max-width: 550px) {
  /* line 52, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header.commercial {
    flex-flow: column nowrap;
    height: 150px;
    padding-left: 0;
  }
}

/* line 61, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header.commercial .c-header__nav ul {
  justify-content: center;
  padding: 0px 10px;
}

@media screen and (max-width: 900px) {
  /* line 61, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header.commercial .c-header__nav ul {
    display: none;
  }
}

/* line 70, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header.commercial .c-header__logo {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 75px;
}

/* line 76, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header.commercial .c-header__logo img {
  width: 235px;
}

@media (max-width: 479px) {
  /* line 76, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header.commercial .c-header__logo img {
    width: 155px;
  }
}

@media screen and (max-width: 550px) {
  /* line 85, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header.commercial .c-header__red {
    width: 100%;
    height: 75px;
  }
}

/* line 93, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__red {
  height: 100%;
}

/* line 96, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__red a {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  height: 100%;
  padding: 10px 35px;
  background-color: #eb0a0f;
  color: white;
  text-transform: uppercase;
  font-size: 1.5rem;
}

/* line 108, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__red a:hover {
  background-color: #f6282d;
}

/* line 114, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__burger {
  position: absolute;
  top: 0;
  left: 0;
  width: 75px;
  height: 75px;
  opacity: 1;
  background: #0f46a0;
  cursor: pointer;
  transition: all 300ms ease;
  z-index: 99999;
}

/* line 126, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__burger:hover {
  background: #135acf;
}

/* line 130, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__burger.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  animation: headerIn 500ms ease forwards;
}

/* line 139, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__burger.active .bar:nth-of-type(1) {
  transform: rotate(45deg) translateX(7px);
}

/* line 143, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__burger.active .bar:nth-of-type(2) {
  display: none;
  opacity: 0;
}

/* line 148, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__burger.active .bar:nth-of-type(3) {
  transform: rotate(135deg) translateX(-5px);
}

/* line 154, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__burger .icon {
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* line 162, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__burger .icon .bar {
  display: block;
  width: 35px;
  height: 2px;
  margin: 4px 0px;
  background: white;
  transition: all 300ms ease;
}

/* line 174, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__logo img {
  width: 207px;
}

@media (max-width: 479px) {
  /* line 174, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__logo img {
    width: 155px;
  }
}

@media screen and (min-width: 1440px) {
  /* line 174, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__logo img {
    width: 235px;
  }
}

/* line 187, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
}

@media screen and (max-width: 1080px) {
  /* line 187, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__nav {
    display: none;
  }
  /* line 196, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header.commercial .c-header__nav {
    display: flex;
  }
  /* line 198, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header.commercial .c-header__nav ul {
    display: none;
  }
  /* line 201, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header.commercial .c-header__nav .wpml-ls ul {
    display: block;
  }
}

/* line 207, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav .wpml-ls {
  padding: 0;
  border: inherit;
  background-color: inherit;
}

/* line 211, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav .wpml-ls a {
  padding: 0;
}

/* line 213, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav .wpml-ls a:hover {
  background: none;
}

/* line 219, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  margin: 0;
  list-style-type: none;
}

/* line 229, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li > a {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin: 0px 20px;
  color: #212121;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.5px;
  /*@media screen and (max-width: 1300px) {*/
}

@media screen and (max-width: 1450px) {
  /* line 229, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__nav ul:not(.sub-menu) > li > a {
    margin: 0 10px;
  }
}

@media screen and (max-width: 1200px) {
  /* line 229, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__nav ul:not(.sub-menu) > li > a {
    margin: 0 5px;
  }
}

/* line 250, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li > a:hover {
  color: #89949f;
}

/* line 254, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li > a i {
  margin: -5px 0 0 5px;
}

/* line 259, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu {
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
  min-width: 300px;
  margin-left: 20px;
  padding-top: 40px;
  visibility: hidden;
  opacity: 0;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 40px 50px rgba(33, 33, 33, 0.3);
  background: white;
  overflow: hidden;
  list-style-type: none;
  transition: all 300ms ease;
  pointer-events: none;
}

/* line 277, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu li {
  margin: 20px 0 0;
  padding: 0 40px;
}

/* line 282, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu li:last-child a {
  margin-bottom: 40px;
}

/* line 287, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu li:before {
  display: none;
}

/* line 291, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu li span {
  width: 35px;
  margin-right: 15px;
}

/* line 297, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu li:nth-of-type(1) img {
  width: 20px;
}

/* line 303, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu li:nth-of-type(2) img {
  width: 20px;
}

/* line 309, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu li:nth-of-type(3) img {
  width: 25px;
}

/* line 315, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu li:nth-of-type(4) img {
  width: 25px;
}

/* line 320, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu li.sub-menu-button {
  flex-grow: 1;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0px;
}

/* line 326, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu li.sub-menu-button a {
  display: block;
  width: 100%;
  padding: 12px 30px;
  border-radius: 50px;
  background: #0f46a0;
  color: white;
  text-align: center;
  text-align: center;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.6rem;
  line-height: 2rem;
}

/* line 340, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu li.sub-menu-button a:hover:after {
  color: white;
}

/* line 348, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu a {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  flex-flow: row nowrap;
  margin: 0;
  color: #212121;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

/* line 359, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu a:hover {
  color: #eb0a0f;
}

/* line 363, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li .sub-menu a:after {
  content: "\f0dd";
  display: inline-block;
  transform: rotate(-90deg);
  opacity: 0;
  transition: all 300ms ease;
  font-family: FontAwesome;
  font-size: 1.6rem;
}

/* line 375, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li.menu-item-has-children {
  position: relative;
}

/* line 379, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li.menu-item-has-children a::after {
  content: url("../images/svg/arrow_down.svg");
  margin-left: 8px;
  transition: all 300ms ease-in-out;
  padding-bottom: 6px;
}

/* line 390, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li.menu-item-has-children:hover a::after {
  transform: rotateZ(180deg);
}

/* line 395, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li.menu-item-has-children:hover .sub-menu {
  visibility: visible;
  opacity: 1;
  pointer-events: initial;
}

/* line 400, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li.menu-item-has-children:hover .sub-menu li {
  margin: 20px 0 0;
  padding: 0 40px;
}

/* line 406, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li.menu-item-has-children:hover .sub-menu a::after {
  content: none;
}

/* line 411, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li.menu-item-has-children:hover .sub-menu .last-element {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 0;
  padding: 0;
  background: #212121;
}

/* line 421, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li.menu-item-has-children:hover .sub-menu .last-element a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  padding: 32px 0;
  color: #fff;
}

/* line 433, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__nav ul:not(.sub-menu) > li.menu-item-has-children:hover .sub-menu .last-element:hover a {
  opacity: 0.5;
}

/* line 446, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header .menu .border-right {
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid #d0dce8;
}

/* line 453, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header .c-header_info {
  width: 235px;
  height: 100%;
  margin-left: 40px;
  background-color: #0f46a0;
  transition: background-color 300ms ease-in-out;
}

/* line 460, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header .c-header_info:hover {
  background-color: #135acf;
}

/* line 464, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header .c-header_info a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.6rem;
}

/* line 476, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header .c-header__service {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  width: 80px;
  background-color: #eb0a0f;
}

/* line 483, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header .c-header__service:hover {
  background-color: #f63236;
}

/* line 487, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header .c-header__service .grow {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
  justify-content: center;
}

/* line 496, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header .c-header__phone {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  height: 75px;
}

/* line 503, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header .c-header__phone i {
  color: #fff;
  font-size: 2.2rem;
}

/* line 509, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__search {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 100%;
  box-shadow: 0px 0px 25px rgba(33, 33, 33, 0.35);
  background: #0f46a0;
}

@media screen and (max-width: 1300px) {
  /* line 509, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__search {
    margin-right: 0px;
  }
}

@media screen and (max-width: 1080px) {
  /* line 509, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__search {
    margin-left: auto;
  }
}

@media (max-width: 768px) {
  /* line 509, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__search {
    display: none;
  }
}

/* line 534, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__search i {
  color: white;
  font-size: 1.6rem;
}

/* line 540, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__cart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: auto;
  padding: 0 20px;
  background-color: rgba(137, 148, 159, 0.1);
}

@media screen and (max-width: 1440px) {
  /* line 540, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__cart {
    padding: 0 10px 0 5px;
  }
}

@media (max-width: 479px) {
  /* line 540, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__cart {
    display: none;
  }
}

/* line 558, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__cart a {
  font-size: 2rem;
}

/* line 561, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__cart a i {
  color: black;
}

/* line 566, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__cart-count {
  position: absolute;
  top: 16px;
  right: 10px;
  color: #0f46a0;
  font-size: 13px;
}

/* line 575, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__store {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  height: 100%;
  padding: 0px 30px;
  background-color: #0f46a0;
}

@media (max-width: 479px) {
  /* line 575, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__store {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  /* line 575, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
  .c-header__store {
    padding: 0 10px;
  }
}

/* line 593, public/wp-content/themes/performair/assets/src/scss/component/_c-header.scss */
.c-header__store a {
  color: white;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.5px;
}

@keyframes headerIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-home-banner.scss */
.c-home-banner {
  height: calc(100% - 75px);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 0px 50px rgba(33, 33, 33, 0.1);
  z-index: 10;
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-home-banner.scss */
  .c-home-banner {
    height: auto;
  }
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider {
  flex-grow: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  background: white;
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider {
    flex-flow: column nowrap;
  }
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider {
    justify-content: flex-start;
  }
}

/* line 20, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider .slick-slide:focus {
  outline: 0;
}

/* line 25, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider h2 {
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-black";
  font-weight: 800;
  color: #3f474e;
  font-size: 7rem;
  line-height: 7rem;
}

@media screen and (max-width: 1250px) {
  /* line 25, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider h2 {
    font-size: 5rem;
    line-height: 5.5rem;
  }
}

@media (max-width: 479px) {
  /* line 25, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider h2 {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

@media screen and (max-width: 1250px) {
  /* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider p.medium {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@media (max-width: 479px) {
  /* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider p.medium {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

/* line 56, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--image, .c-slider--content {
  width: 50%;
  height: 100%;
}

/* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--content {
  padding: 40px;
  overflow: hidden;
  background: white;
}

@media (max-width: 992px) {
  /* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider--content {
    width: 100%;
    height: 60%;
  }
}

@media (max-width: 768px) {
  /* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider--content {
    height: auto;
    margin-top: 40px;
    padding-bottom: 60px;
  }
}

/* line 78, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--content .slick-list {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  overflow: visible;
}

/* line 91, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--image {
  position: relative;
}

@media (max-width: 992px) {
  /* line 91, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider--image {
    order: -1;
    width: 100%;
    height: 40%;
  }
}

@media (max-width: 768px) {
  /* line 91, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider--image {
    height: 360px;
  }
}

@media (max-width: 768px) {
  /* line 91, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider--image {
    height: 320px;
  }
}

/* line 108, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--image:before {
  content: '';
  display: block;
  width: 88px;
  height: 108px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: url("../images/svg/sliced-rectangle.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 9999;
}

@media (max-width: 992px) {
  /* line 108, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider--image:before {
    display: none;
  }
}

/* line 126, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--image__slide {
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* line 133, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--image .slick-track,
.c-slider--image .slick-list {
  height: 100%;
}

/* line 139, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--nav {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 50px;
  height: 200px;
  background: white;
  border-radius: 50px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

@media (max-width: 992px) {
  /* line 139, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider--nav {
    transform: rotate(-90deg);
    top: -20%;
  }
}

@media (max-width: 768px) {
  /* line 139, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider--nav {
    top: 225px;
    bottom: initial;
  }
}

/* line 166, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--nav ul {
  list-style-type: none;
  margin: 0;
}

/* line 171, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--nav li {
  margin: 8px 0px;
  position: relative;
}

/* line 175, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--nav li:before {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  border-top: 5px solid transparent;
  border-right: 5px solid #0f46a0;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  background: transparent;
  position: absolute;
  left: -20px;
  top: 0px;
  bottom: 0px;
  margin: auto 0;
  opacity: 0;
  transition: all 300ms ease;
}

/* line 196, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--nav li.slick-active:before {
  opacity: 1;
  left: -30px;
}

/* line 202, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--nav li button {
  border: 0px;
  background: #0f46a0;
  font-size: 18px;
  color: white;
  border-radius: 50%;
  padding: 6px 10px 4px;
}

@media (max-width: 992px) {
  /* line 202, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
  .c-slider--nav li button {
    transform: rotate(90deg);
  }
}

/* line 223, public/wp-content/themes/performair/assets/src/scss/component/_c-slider.scss */
.c-slider--nav li button:focus {
  outline: 0;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-button.scss */
.c-button {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 22px 40px;
  border-radius: 8px;
  background-color: #eb0a0f;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}

/* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-button.scss */
.c-button:hover {
  background-color: #f63236;
}

/* line 14, public/wp-content/themes/performair/assets/src/scss/component/_c-button.scss */
.c-button.btn-white {
  background: #fff;
  color: #0f46a0;
}

/* line 19, public/wp-content/themes/performair/assets/src/scss/component/_c-button.scss */
.c-button.-disabled {
  cursor: wait;
}

/* line 24, public/wp-content/themes/performair/assets/src/scss/component/_c-button.scss */
.btn-red {
  padding: 22px 40px;
  border-radius: 8px;
  background-color: #eb0a0f;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}

/* line 32, public/wp-content/themes/performair/assets/src/scss/component/_c-button.scss */
.btn-red:hover {
  background-color: #f63236;
}

/* line 37, public/wp-content/themes/performair/assets/src/scss/component/_c-button.scss */
.btn-blue {
  padding: 22px 40px;
  border-radius: 8px;
  background-color: #0f46a0;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}

/* line 45, public/wp-content/themes/performair/assets/src/scss/component/_c-button.scss */
.btn-blue:hover {
  background-color: #135acf;
}

/* line 50, public/wp-content/themes/performair/assets/src/scss/component/_c-button.scss */
.btn-black {
  flex-shrink: 0;
  padding: 22px 40px;
  border-radius: 8px;
  background-color: #212121;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}

/* line 59, public/wp-content/themes/performair/assets/src/scss/component/_c-button.scss */
.btn-black:hover {
  background-color: #3b3a3a;
}

/* line 3, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
.c-home__about {
  background-color: #fbfbfb;
}

/* line 6, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
.c-home__about p {
  max-width: 445px;
  margin-bottom: 50px;
}

/* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
.c-home__about-rebate {
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  /* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
  .c-home__about-rebate {
    margin-bottom: 50px;
  }
}

@media (max-width: 479px) {
  /* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
  .c-home__about-rebate {
    margin-bottom: 30px;
  }
}

/* line 24, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
.c-home__about-rebate img:first-of-type {
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  /* line 30, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
  .c-home__about .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
.c-home__about .row:first-of-type {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  /* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
  .c-home__about .row:first-of-type {
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  /* line 46, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
  .c-home__about .col_1x2 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  /* line 52, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
  .c-home__about .col_1x2:last-of-type {
    margin-top: 40px;
  }
}

/* line 60, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
.c-home__about-1968 {
  position: absolute;
  top: -80px;
  right: 40px;
  max-width: none;
  width: 100%;
  backface-visibility: hidden;
}

@media screen and (max-width: 1200px) {
  /* line 60, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
  .c-home__about-1968 {
    width: 90%;
  }
}

@media (max-width: 992px) {
  /* line 60, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
  .c-home__about-1968 {
    top: 0;
  }
}

@media (max-width: 768px) {
  /* line 60, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
  .c-home__about-1968 {
    position: initial;
    width: 100%;
  }
}

/* line 82, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
.c-home__about-corner {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 900px;
}

@media (max-width: 992px) {
  /* line 82, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
  .c-home__about-corner {
    max-width: 600px;
  }
}

@media (max-width: 768px) {
  /* line 82, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
  .c-home__about-corner {
    max-width: 500px;
  }
}

@media (max-width: 479px) {
  /* line 82, public/wp-content/themes/performair/assets/src/scss/component/_c-home.scss */
  .c-home__about-corner {
    max-width: 470px;
  }
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch {
  position: relative;
  box-shadow: 0px 0px 50px rgba(33, 33, 33, 0.08);
  background-color: white;
  z-index: 2;
}

/* line 7, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch .col_1x4,
.c-dispatch .col_1x3 {
  border-right: 1px solid #e0e0e0;
  overflow: hidden;
}

@media (max-width: 992px) {
  /* line 7, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
  .c-dispatch .col_1x4,
  .c-dispatch .col_1x3 {
    width: 100% !important;
  }
}

/* line 18, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch .col_1x4:nth-of-type(1) .c-dispatch__item-icon img,
.c-dispatch .col_1x3:nth-of-type(1) .c-dispatch__item-icon img {
  width: 40px;
}

/* line 26, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch .col_1x4:nth-of-type(2) .c-dispatch__item-icon img,
.c-dispatch .col_1x3:nth-of-type(2) .c-dispatch__item-icon img {
  width: 40px;
}

/* line 34, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch .col_1x4:nth-of-type(3) .c-dispatch__item-icon img,
.c-dispatch .col_1x3:nth-of-type(3) .c-dispatch__item-icon img {
  width: 50px;
}

/* line 42, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch .col_1x4:nth-of-type(4) .c-dispatch__item-icon img,
.c-dispatch .col_1x3:nth-of-type(4) .c-dispatch__item-icon img {
  width: 60px;
}

/* line 49, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item {
  padding: 120px 40px;
  text-align: center;
}

/* line 53, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item:before {
  content: "";
  position: absolute;
  right: -800px;
  bottom: -800px;
  display: block;
  width: 1000px;
  height: 1000px;
  transform: rotate(45deg);
  background: #eb0a0f;
  transition: all 500ms ease;
}

@media (max-width: 992px) {
  /* line 53, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
  .c-dispatch__item:before {
    right: -1560px;
    bottom: -1750px;
    width: 2000px;
    height: 2000px;
  }
}

@media (max-width: 479px) {
  /* line 53, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
  .c-dispatch__item:before {
    right: -800px;
    bottom: -800px;
    width: 1000px;
    height: 1000px;
  }
}

/* line 81, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item:hover:before {
  right: -300px;
  bottom: -300px;
  margin: auto;
}

/* line 87, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item:hover .c-dispatch__item-hover {
  opacity: 1;
  transition-delay: 200ms;
}

/* line 91, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item:hover .c-dispatch__item-hover:before {
  opacity: 1;
  transition-delay: 200ms;
}

/* line 98, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item h3 {
  font-size: 2.5rem;
  line-height: 3rem;
}

/* line 103, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-icon {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 115px;
  height: 115px;
  margin: 0 auto 30px;
  border: 3px solid #89949f;
  border-radius: 100%;
  align-item: center;
}

/* line 115, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-plus {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 24px;
  height: 24px;
  align-item: center;
}

/* line 126, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-plus:before, .c-dispatch__item-plus:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 18px;
  height: 2px;
  background: white;
}

/* line 140, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-plus:after {
  transform: rotate(90deg);
}

/* line 145, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-repeat: no-repeat;
  background-size: cover;
  align-center: center;
}

/* line 161, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(236, 63, 52, 0.6);
  transition: all 500ms ease;
  transition-delay: 100ms;
  z-index: 1;
  align-center: center;
}

/* line 181, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-hover > * {
  position: relative;
  z-index: 2;
}

/* line 186, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-hover img {
  margin: 0 auto 30px;
}

/* line 189, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-hover img.one {
  width: 70px;
}

/* line 193, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-hover img.two {
  width: 70px;
}

/* line 197, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-hover img.three {
  width: 80px;
}

/* line 201, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-hover img.four {
  width: 90px;
}

/* line 207, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-hover h3 span {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: center;
  color: white;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 215, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-hover h3 span:after {
  content: "\f0dd";
  display: inline-block;
  width: 0px;
  height: 0px;
  margin-bottom: -16px;
  transform: rotate(-90deg);
  color: #0f46a0;
  font-family: fontAwesome;
}

/* line 232, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch.commercial .c-dispatch__item-hover {
  align-items: flex-start;
  padding: 30px 50px;
}

/* line 236, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch.commercial .c-dispatch__item-hover h3 {
  margin-bottom: 10px;
  color: white;
}

/* line 240, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch.commercial .c-dispatch__item-hover h3 span {
  text-align: left;
  line-height: 2.4rem;
}

/* line 244, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch.commercial .c-dispatch__item-hover h3 span:after {
  display: none;
}

/* line 250, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch.commercial .c-dispatch__item-hover p {
  color: white;
  text-align: left;
  font-size: 1.5rem;
  line-height: 2.2rem;
}

/* line 260, public/wp-content/themes/performair/assets/src/scss/component/_c-dispatch.scss */
.c-dispatch__item-icon.-refrigeration img {
  padding: 12px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid {
  position: relative;
  padding: 150px 0px 50px;
  background-color: white;
}

/* line 6, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 330px;
  background: #fbfbfb;
}

@media (max-width: 768px) {
  /* line 16, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
  .c-grid .col_1x2 {
    width: 100%;
  }
}

/* line 22, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid .pre-title {
  color: white;
}

/* line 26, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  padding: 40px;
  box-shadow: 0px 0px 50px rgba(33, 33, 33, 0.5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
}

@media (max-width: 479px) {
  /* line 26, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
  .c-grid__item {
    padding: 35px 25px;
  }
}

/* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item h3 {
  font-size: 3.2rem;
  line-height: 4rem;
}

@media (max-width: 479px) {
  /* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
  .c-grid__item h3 {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
}

@media (max-width: 479px) {
  /* line 52, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
  .c-grid__item h3 br {
    display: none;
  }
}

/* line 59, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item * {
  z-index: 1;
}

/* line 64, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item:hover .c-grid__item-plus {
  right: -75px;
  bottom: -75px;
}

/* line 70, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 300ms ease;
}

/* line 80, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item.grey {
  justify-content: flex-end;
}

/* line 83, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item.grey:before {
  background: rgba(33, 33, 33, 0.5);
}

/* line 88, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item.grey:hover:before {
  background: rgba(33, 33, 33, 0.75);
}

/* line 94, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item.orange {
  justify-content: center;
}

/* line 97, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item.orange:before {
  background: rgba(235, 10, 15, 0.9);
}

/* line 102, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item.orange:hover:before {
  background: #eb0a0f;
}

/* line 108, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item.orange .pre-title:before {
  border-left: 12px solid white;
}

/* line 113, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item.orange .c-grid__item-plus {
  background: white;
}

/* line 116, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item.orange .c-grid__item-plus:before, .c-grid__item.orange .c-grid__item-plus:after {
  background: #eb0a0f;
}

/* line 123, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item.full {
  height: 700px;
}

@media (max-width: 768px) {
  /* line 123, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
  .c-grid__item.full {
    height: 500px;
    margin-bottom: 20px;
  }
}

@media (max-width: 479px) {
  /* line 123, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
  .c-grid__item.full {
    height: 400px;
    margin-bottom: 20px;
  }
}

/* line 137, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item.half {
  height: 340px;
}

/* line 140, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item.half:last-child {
  margin-top: 20px;
}

/* line 145, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item-plus {
  position: absolute;
  right: -150px;
  bottom: -150px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 150px;
  height: 150px;
  transform: rotate(45deg);
  background: #eb0a0f;
  transition: all 300ms ease;
  align-item: center;
}

/* line 159, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item-plus:before, .c-grid__item-plus:after {
  content: "";
  position: absolute;
  top: 0;
  right: 80px;
  bottom: 0;
  left: 0;
  display: block;
  width: 18px;
  height: 2px;
  margin: auto;
  background: white;
}

/* line 174, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item-plus:before {
  transform: rotate(45deg);
}

/* line 178, public/wp-content/themes/performair/assets/src/scss/component/_c-grid.scss */
.c-grid__item-plus:after {
  transform: rotate(135deg);
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest {
  padding: 50px 0px;
  background: #fbfbfb;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest .row {
  justify-content: flex-start;
  margin-right: -4%;
  margin-bottom: 35px;
}

@media (max-width: 768px) {
  /* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
  .c-latest .col_1x3 {
    width: 100%;
    margin-bottom: 25px;
  }
}

/* line 18, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item {
  height: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 0px 50px rgba(33, 33, 33, 0.2);
}

/* line 23, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-image {
  position: relative;
  display: block;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

@media (max-width: 992px) {
  /* line 23, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
  .c-latest__item-image {
    height: 230px;
  }
}

@media (max-width: 768px) {
  /* line 23, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
  .c-latest__item-image {
    height: 300px;
  }
}

/* line 40, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-image:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 300ms ease;
}

/* line 51, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-image:hover:before {
  background: rgba(33, 33, 33, 0.75);
}

/* line 55, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-image:hover .c-latest__item-plus {
  right: -75px;
  bottom: -75px;
}

/* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-content {
  padding: 40px 40px 20px;
}

/* line 65, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-content .date {
  display: block;
  margin-bottom: 8px;
  color: #89949f;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 1.5rem;
  line-height: 2rem;
}

/* line 75, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-content h4 {
  margin-bottom: 20px;
  font-size: 2.2rem;
  line-height: 2.8rem;
}

/* line 80, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-content h4 a {
  color: #3f474e;
}

/* line 83, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-content h4 a:hover {
  color: #212121;
}

/* line 89, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-content .read-more {
  display: block;
  color: #89949f;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 1.5rem;
  line-height: 2rem;
}

/* line 97, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-content .read-more:hover {
  color: #3f474e;
}

/* line 100, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-content .read-more:hover i {
  margin-left: 0;
  opacity: 1;
}

/* line 106, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-content .read-more i {
  margin-left: -10px;
  transform: rotate(-90deg);
  opacity: 0;
  color: #0f46a0;
  transition: all 300ms ease;
  font-size: 1.8rem;
}

/* line 117, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-plus {
  position: absolute;
  right: -150px;
  bottom: -150px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 150px;
  height: 150px;
  transform: rotate(45deg);
  background: #eb0a0f;
  transition: all 300ms ease;
  align-item: center;
}

/* line 131, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-plus:before, .c-latest__item-plus:after {
  content: "";
  position: absolute;
  top: 0;
  right: 80px;
  bottom: 0;
  left: 0;
  display: block;
  width: 18px;
  height: 2px;
  margin: auto;
  background: white;
}

/* line 146, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-plus:before {
  transform: rotate(45deg);
}

/* line 150, public/wp-content/themes/performair/assets/src/scss/component/_c-latest.scss */
.c-latest__item-plus:after {
  transform: rotate(135deg);
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-partners.scss */
.c-partners {
  border-top: 1px solid #e0e0e0;
  padding: 80px 0px;
  background: white;
  box-shadow: 0px 0px 50px rgba(33, 33, 33, 0.1);
}

/* line 7, public/wp-content/themes/performair/assets/src/scss/component/_c-partners.scss */
.c-partners .slick-track {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

/* line 14, public/wp-content/themes/performair/assets/src/scss/component/_c-partners.scss */
.c-partners__slide {
  margin: 0 20px;
  outline: 0;
}

/* line 18, public/wp-content/themes/performair/assets/src/scss/component/_c-partners.scss */
.c-partners__slide img {
  max-width: 150px;
  margin: 0 auto;
  backface-visibility: hidden;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
body.menu-is-open {
  overflow: hidden !important;
}

/* line 4, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
body.menu-is-open .c-massive {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  animation: slideIn 500ms forwards alternate;
}

@media (max-width: 768px) {
  /* line 4, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  body.menu-is-open .c-massive {
    display: flex;
  }
}

/* line 16, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
body.menu-is-open .c-massive__links {
  padding: 180px 80px;
}

@media (max-width: 768px) {
  /* line 20, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  body.menu-is-open .c-massive__contact {
    display: none;
  }
}

/* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
body.menu-is-open .c-header__burger {
  position: fixed;
  top: 30px !important;
  left: 0;
  background: transparent;
  z-index: 999999;
}

/* line 37, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
body.menu-is-close .c-massive {
  animation: slideOut 500ms forwards alternate;
}

/* line 42, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
body.menu-is-close .icon .bar {
  background: white;
}

@keyframes slideIn {
  0% {
  }
  5% {
    transform: translateY(-100%);
    box-shadow: 0px 0px 30px rgba(33, 33, 33, 0.3);
    z-index: 0;
  }
  100% {
    transform: translateY(0%);
    box-shadow: 0px 0px 30px rgba(33, 33, 33, 0.2);
    z-index: 99999;
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0%);
    box-shadow: 0px 0px 30px rgba(33, 33, 33, 0.2);
    z-index: 99999;
  }
  95% {
    transform: translateY(-100%);
    box-shadow: 0px 0px 30px rgba(33, 33, 33, 0.3);
    z-index: -1;
  }
  100% {
    left: 0px;
    transform: translateY(0%);
    box-shadow: none;
    z-index: -1;
  }
}

/* line 85, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

@media (max-width: 768px) {
  /* line 85, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive {
    flex-direction: column;
  }
}

/* line 94, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive .section-name {
  margin-bottom: 12px;
  opacity: 0.5;
  color: #fff;
  list-style-type: none;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 32px;
}

/* line 103, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__links {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 40px;
  width: 50%;
  padding: 96px 80px;
  background: #0f46a0;
}

@media (max-width: 768px) {
  /* line 103, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__links {
    width: 100%;
    padding: 40px;
  }
}

@media (max-width: 768px) {
  /* line 117, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__links .row {
    gap: 40px;
  }
}

/* line 123, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__links ul {
  margin: 0;
  list-style-type: none;
}

/* line 127, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__links ul li {
  margin-bottom: 8px;
}

/* line 130, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__links ul li a {
  color: #fff;
  font-size: 18px;
}

/* line 134, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__links ul li a:hover {
  opacity: 0.5;
}

/* line 142, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact {
  position: relative;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 96px 80px;
  border-top: 1px solid #d0dce8;
  background: #fff;
  overflow: hidden;
}

@media (max-width: 768px) {
  /* line 142, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__contact {
    width: 100%;
    padding: 40px;
  }
}

/* line 158, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact::after {
  content: "";
  position: absolute;
  right: -185px;
  bottom: -150px;
  width: 340px;
  height: 400px;
  transform: rotate(38deg);
  background-color: #eb0a0f;
}

@media (max-width: 767px) {
  /* line 158, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__contact::after {
    width: 200px;
  }
}

/* line 173, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .section-name {
  margin-bottom: 40px;
  color: #89949f;
  font-size: 32px;
}

/* line 179, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact h4 {
  margin-bottom: 24px;
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 24px;
  font-weight: 400;
}

/* line 187, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-address {
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 18px;
}

/* line 193, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-numbers {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
  margin-bottom: 40px;
  font-size: 18px;
}

/* line 201, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-numbers a {
  width: fit-content;
  color: #3f474e;
  transition: color 150ms ease-in-out;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 18px;
}

/* line 208, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-numbers a:hover {
  color: #eb0a0f;
}

/* line 213, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-numbers strong {
  color: #eb0a0f;
}

/* line 218, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-emergency {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 24px;
}

/* line 226, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-emergency a {
  width: fit-content;
  color: #3f474e;
  transition: color 150ms ease-in-out;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 18px;
}

/* line 233, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-emergency a:hover {
  color: #eb0a0f;
}

/* line 238, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-emergency strong {
  color: #eb0a0f;
}

/* line 243, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-socials {
  display: flex;
  gap: 16px;
}

/* line 248, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-social {
  width: fit-content;
  margin-top: 40px;
  padding: 32px;
  border: 1px solid #d0dce8;
  border-radius: 8px;
  transition: all 150ms ease-in-out;
}

/* line 256, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-social:hover {
  border: 1px solid #212121;
  background-color: #212121;
}

/* line 260, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-social:hover::after {
  filter: grayscale(1) invert(1);
}

/* line 265, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-social::after {
  content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3602_635)'%3E%3Cpath d='M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z' fill='%23000000'/%3E%3Cpath d='M16.6711 15.4688L17.2031 12H13.875V9.75C13.875 8.80102 14.34 7.875 15.8306 7.875H17.3438V4.92188C17.3438 4.92188 15.9705 4.6875 14.6576 4.6875C11.9166 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4688H10.125V23.8542C11.3674 24.0486 12.6326 24.0486 13.875 23.8542V15.4688H16.6711Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3602_635'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E ");
}

/* line 270, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__contact .c-massive__contact-social.-instagram::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' class='bi bi-instagram' viewBox='0 0 16 16'%3E%3Cpath d='M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z'%3E%3C/path%3E%3C/svg%3E%0A");
}

/* line 277, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive .col_1x2 {
  display: flex;
  flex-flow: column nowrap;
}

@media (max-width: 768px) {
  /* line 277, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive .col_1x2 {
    width: 100% !important;
  }
}

/* line 286, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive .button-wrapper {
  display: flex;
  align-items: flex-start;
  flex-flow: column wrap;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  /* line 286, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive .button-wrapper {
    align-items: center;
  }
}

/* line 296, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive .button-wrapper a {
  flex-shrink: 0;
}

/* line 301, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__nav {
  margin: 0;
  margin-bottom: 30px;
  list-style-type: none;
}

/* line 306, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__nav li,
.c-massive__nav li a {
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 312, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__nav.standard li {
  margin-bottom: 8px;
}

/* line 315, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__nav.standard li a {
  display: inline-flex;
  align-items: center;
  flex-flow: row wrap;
  color: #89949f;
  font-size: 1.9rem;
  line-height: 1.3rem;
}

@media screen and (max-width: 1200px) {
  /* line 315, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__nav.standard li a {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
}

/* line 328, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__nav.standard li a:after {
  content: "\f0dd";
  display: block;
  width: 0;
  height: 0;
  margin-bottom: -12px;
  transform: rotate(-90deg);
  opacity: 0;
  transition: all 300ms ease;
  font-family: fontAwesome;
}

/* line 340, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__nav.standard li a:hover {
  color: #3f474e;
}

/* line 343, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__nav.standard li a:hover:after {
  margin-left: 8px;
  opacity: 1;
}

/* line 352, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__nav.large {
  padding-top: 20px;
}

@media (max-width: 768px) {
  /* line 352, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__nav.large {
    display: flex;
    flex-flow: row wrap;
    text-align: center;
  }
}

/* line 361, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__nav.large li {
  width: 100%;
  margin-bottom: 25px;
}

@media (max-width: 992px) {
  /* line 361, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__nav.large li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  /* line 361, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__nav.large li {
    width: 100%;
    margin-bottom: 15px;
  }
}

/* line 374, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__nav.large li:not(.always-visible) {
  display: none;
}

@media (max-width: 768px) {
  /* line 374, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__nav.large li:not(.always-visible) {
    display: block;
  }
}

/* line 382, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__nav.large li a {
  display: inline-flex;
  align-items: center;
  flex-flow: row wrap;
  color: #89949f;
  font-size: 2.5rem;
  line-height: 1.2rem;
}

@media (max-width: 768px) {
  /* line 382, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__nav.large li a {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
}

/* line 395, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__nav.large li a:hover {
  color: #3f474e;
}

/* line 404, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__copyright {
  display: flex;
  align-items: center;
  padding: 40px 80px;
}

@media (max-width: 768px) {
  /* line 404, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__copyright {
    flex-direction: column;
    gap: 40px;
    padding: 20px 40px;
  }
}

/* line 415, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__copyright .c-massive_left,
.c-massive__copyright .c-massive_right {
  width: 50%;
}

@media (max-width: 768px) {
  /* line 415, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__copyright .c-massive_left,
  .c-massive__copyright .c-massive_right {
    width: 100%;
  }
}

/* line 424, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__copyright .c-massive_left {
  display: flex;
  border-right: 1px solid #d0dce8;
}

/* line 428, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__copyright .c-massive_left p {
  gap: 40px;
  width: 50%;
  margin: 0;
  font-size: 14px;
}

/* line 435, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__copyright .c-massive_left a {
  color: #89949f;
}

/* line 438, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__copyright .c-massive_left a:hover {
  color: #3f474e;
}

/* line 444, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__copyright .c-massive_right {
  display: flex;
  padding-left: 80px;
}

@media (max-width: 768px) {
  /* line 444, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
  .c-massive__copyright .c-massive_right {
    padding-left: 0px;
  }
}

/* line 451, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__copyright .c-massive_right a {
  gap: 40px;
  width: 50%;
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 14px;
}

/* line 458, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__copyright .c-massive_right a:hover {
  color: #eb0a0f;
}

/* line 463, public/wp-content/themes/performair/assets/src/scss/component/_c-massive.scss */
.c-massive__copyright .c-massive_right .backToTop {
  cursor: pointer;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer {
  display: none;
  height: 100%;
  pointer-events: none;
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
  .c-footer {
    pointer-events: initial;
    height: auto;
    display: block;
  }
}

/* line 12, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile {
  display: none;
  flex-flow: column nowrap;
  align-items: center;
  padding: 60px 40px;
  text-align: center;
  background: #fbfbfb;
  position: relative;
}

@media (max-width: 768px) {
  /* line 12, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
  .c-footer__mobile {
    display: flex;
  }
}

/* line 25, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile .mobile-footer-corner {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  backface-visibility: hidden;
}

/* line 34, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile .mobile-logo {
  margin-bottom: 40px;
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile h3 {
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 3.5rem;
  line-height: 4rem;
  margin-bottom: 5px;
}

/* line 45, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile h4 {
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 2rem;
  line-height: 2.5rem;
}

/* line 51, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile p {
  font-size: 2rem;
  line-height: 2.5rem;
}

/* line 56, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile a:not(.c-button) {
  font-size: 2rem;
  line-height: 2.5rem;
  color: #89949f;
  margin-bottom: 5px;
}

/* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile .conditions {
  font-size: 16px !important;
}

/* line 66, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile .facebook-link {
  margin: 10px 0px;
}

/* line 69, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile .facebook-link i {
  font-size: 2.5rem;
}

/* line 74, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile .urgence {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* line 79, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile .urgence span {
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #3f474e;
}

/* line 86, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile .urgence > * {
  display: block;
}

/* line 90, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile .urgence .c-button {
  margin-top: 30px;
}

/* line 95, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile-copyright {
  margin-top: 80px;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #89949f;
}

/* line 102, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile-copyright > div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

/* line 110, public/wp-content/themes/performair/assets/src/scss/component/_c-footer.scss */
.c-footer__mobile-copyright img {
  width: 22px;
  margin-left: 10px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb {
  background: #eef4ff;
  padding: 20px 40px;
}

@media (max-width: 479px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-breadcrumb.scss */
  .c-breadcrumb {
    display: none;
  }
}

/* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
  margin: 0;
}

/* line 17, public/wp-content/themes/performair/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb ul li {
  position: relative;
}

/* line 21, public/wp-content/themes/performair/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb ul li a:after {
  content: "\f0dd";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  transform: rotate(-90deg);
  margin: 0px 15px 0px 10px;
  color: #0f46a0;
}

/* line 33, public/wp-content/themes/performair/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb ul li,
.c-breadcrumb ul li h1,
.c-breadcrumb ul a {
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.6rem;
  line-height: 2rem;
  color: #3f474e;
}

/* line 41, public/wp-content/themes/performair/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb ul li h1 {
  margin-bottom: 0;
  text-transform: inherit;
  font-weight: lighter;
}

/* line 48, public/wp-content/themes/performair/assets/src/scss/component/_c-breadcrumb.scss */
.c-breadcrumb ul a:hover {
  color: #212121;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split {
  background: white;
  position: relative;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split .c-split__badge {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  z-index: 10;
  background: white;
  border-radius: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

@media (max-width: 992px) {
  /* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
  .c-split .c-split__badge {
    top: 350px;
    bottom: initial;
  }
}

@media (max-width: 768px) {
  /* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
  .c-split .c-split__badge {
    top: 250px;
  }
}

@media (max-width: 479px) {
  /* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
  .c-split .c-split__badge {
    display: none;
  }
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split .c-split__badge.service:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 65px;
  height: 65px;
  border-radius: 100%;
  border: 3px solid #89949f;
}

/* line 52, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split .c-split__badge.service img {
  width: 35px;
}

/* line 58, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split .col_1x2 {
  padding: 40px;
  min-height: 700px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 992px) {
  /* line 58, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
  .c-split .col_1x2 {
    width: 100%;
    min-height: 400px;
  }
}

@media (max-width: 768px) {
  /* line 58, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
  .c-split .col_1x2 {
    min-height: 300px;
  }
}

@media (max-width: 479px) {
  /* line 58, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
  .c-split .col_1x2 {
    min-height: 250px;
  }
}

/* line 78, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split .col_1x2:first-child {
  max-width: 600px;
  margin: 0 auto;
}

/* line 83, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split .col_1x2:last-child {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 992px) {
  /* line 83, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
  .c-split .col_1x2:last-child {
    order: -1;
  }
}

/* line 93, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split .col_1x2.no-padding {
  padding: 0px;
}

/* line 97, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split .col_1x2 p {
  max-width: 475px;
}

@media (max-width: 992px) {
  /* line 97, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
  .c-split .col_1x2 p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    max-width: none;
  }
}

/* line 108, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split hr {
  border-color: #fbfbfb;
  width: 100%;
  margin-bottom: 20px;
}

/* line 113, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split hr + p {
  color: #3f474e;
  margin-bottom: 10px;
}

/* line 119, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split .phone {
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 2.4rem;
  line-height: 3rem;
  color: #89949f;
}

/* line 125, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split .phone:after {
  content: "\f0dd";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 24px;
  text-rendering: auto;
  transform: rotate(-90deg);
  margin: 0 15px 0 5px;
  color: #eb0a0f;
  transition: all 300ms ease;
}

/* line 139, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split .phone:hover:after {
  margin: 0 15px 0 10px;
}

/* line 145, public/wp-content/themes/performair/assets/src/scss/component/_c-split.scss */
.c-split #map {
  width: 100%;
  height: 100%;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-anchor-scroll.scss */
.c-anchor-scroll {
  position: absolute;
  bottom: 100px;
  left: 226px;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  width: 350px;
  margin: 0 auto;
  color: #89949f;
  font-size: 1.6rem;
  z-index: 10;
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-anchor-scroll.scss */
  .c-anchor-scroll {
    display: none;
  }
}

/* line 18, public/wp-content/themes/performair/assets/src/scss/component/_c-anchor-scroll.scss */
.c-anchor-scroll::before {
  content: url("../images/svg/continueScroll.svg");
  margin-top: 5px;
  margin-right: 12px;
}

/* line 25, public/wp-content/themes/performair/assets/src/scss/component/_c-anchor-scroll.scss */
.c-anchor-scroll:hover i {
  margin-left: 20px;
}

/* line 30, public/wp-content/themes/performair/assets/src/scss/component/_c-anchor-scroll.scss */
.c-anchor-scroll i {
  margin-bottom: -2px;
  margin-left: 10px;
  color: #eb0a0f;
  transition: all 300ms ease;
  font-size: 2.5rem;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
.c-content {
  padding: 100px 0px;
}

/* line 3, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
.c-content.no-pb {
  padding-bottom: 0px;
}

@media (max-width: 992px) {
  /* line 7, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
  .c-content.service-expertise {
    padding-bottom: 40px;
  }
}

@media (max-width: 992px) {
  /* line 13, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
  .c-content.service-expertise .row {
    margin-bottom: 40px;
  }
}

@media (max-width: 992px) {
  /* line 19, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
  .c-content.service-expertise .col_1x2 {
    width: 100%;
  }
}

@media (max-width: 992px) {
  /* line 25, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
  .c-content.service-expertise .col_1x2:last-of-type {
    display: none;
  }
}

/* line 35, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
.c-content.grey {
  background-color: #f4f5f5;
}

/* line 39, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
.c-content img {
  width: 100%;
}

/* line 45, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
.c-content .no-gutter .col_1x2 {
  width: 45%;
}

/* line 50, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
.c-content__mw {
  max-width: 950px;
  margin: 0 auto 100px;
}

@media (max-width: 768px) {
  /* line 50, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
  .c-content__mw {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  /* line 59, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
  .c-content .row {
    flex-flow: column nowrap;
  }
}

@media (max-width: 768px) {
  /* line 64, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
  .c-content .row .col_1x2 {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 479px) {
  /* line 72, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
  .c-content .row.half-mb {
    margin-bottom: 30px;
  }
}

/* line 79, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
.c-content-page {
  background-color: white;
}

/* line 81, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
.c-content-page .c-produits__categories-banner {
  margin-bottom: 40px;
}

/* line 84, public/wp-content/themes/performair/assets/src/scss/component/_c-content.scss */
.c-content-page .c-content--info {
  margin-bottom: 60px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-parallax.scss */
.c-parallax {
  height: 700px;
}

@media screen and (max-width: 1300px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-parallax.scss */
  .c-parallax {
    height: 600px;
  }
}

@media screen and (max-width: 1150px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-parallax.scss */
  .c-parallax {
    height: 500px;
  }
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-parallax.scss */
  .c-parallax {
    height: 400px;
  }
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-parallax.scss */
  .c-parallax {
    height: 350px;
  }
}

@media (max-width: 479px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-parallax.scss */
  .c-parallax {
    height: 300px;
  }
}

/* line 3, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
.c-tabs .tab {
  display: none;
}

/* line 6, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
.c-tabs .tab.active {
  display: block;
}

/* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
.c-tabs__links {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
  list-style-type: none;
  margin-left: 0;
}

@media (max-width: 992px) {
  /* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
  .c-tabs__links {
    justify-content: center;
  }
}

@media (max-width: 479px) {
  /* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
  .c-tabs__links {
    flex-flow: column nowrap;
  }
}

/* line 30, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
.c-tabs__links li {
  position: relative;
  margin-right: 40px;
}

@media (max-width: 992px) {
  /* line 30, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
  .c-tabs__links li {
    margin: 0px 20px 10px;
  }
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
.c-tabs__links li:after {
  content: "\f0dd";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  transform: rotate(-90deg);
  color: #0f46a0;
  opacity: 0;
  transition: all 300ms ease;
}

/* line 52, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
.c-tabs__links li:hover:after, .c-tabs__links li.active:after {
  opacity: 1;
  transform: translateX(10px) rotate(-90deg);
}

/* line 58, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
.c-tabs__links li a {
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.8rem;
  line-height: 2rem;
  color: #3f474e;
}

@media (max-width: 768px) {
  /* line 58, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
  .c-tabs__links li a {
    font-family: "Helvetica-neue-condensed-bold";
  }
}

/* line 68, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
.c-tabs__links li a:hover {
  color: #212121;
}

@media (max-width: 479px) {
  /* line 76, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
  .c-tabs__content {
    text-align: center;
  }
}

/* line 82, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
.c-tabs__content h3 {
  margin-bottom: 30px;
}

/* line 86, public/wp-content/themes/performair/assets/src/scss/component/_c-tabs.scss */
.c-tabs__content a {
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.8rem;
  line-height: 2rem;
  color: #0f46a0;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
.c-avantages {
  background-color: #f0f0f0;
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  position: relative;
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
  .c-avantages {
    background-image: none !important;
  }
}

/* line 12, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
.c-avantages:before {
  content: '';
  background: linear-gradient(to right, #f0f0f0 0%, rgba(240, 240, 240, 0) 100%);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
  .c-avantages {
    padding-top: 60px;
    background-size: cover;
  }
}

@media (max-width: 479px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
  .c-avantages {
    background-position: right center;
  }
}

/* line 31, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
.c-avantages + .gap-filler {
  height: 200px;
  background: #fbfbfb;
}

/* line 36, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
.c-avantages .container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  min-height: 600px;
  position: relative;
}

/* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
.c-avantages .container .row {
  width: 100%;
}

@media screen and (max-width: 1100px) {
  /* line 48, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
  .c-avantages .col_2x5 {
    width: 100%;
    max-width: 500px;
  }
}

/* line 56, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
.c-avantages__boxes {
  position: absolute;
  right: 40px;
  bottom: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 60%;
  transform: translateY(50%);
}

@media (max-width: 992px) {
  /* line 56, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
  .c-avantages__boxes {
    position: initial;
    right: initial;
    left: initial;
    bottom: initial;
    width: 100%;
    transform: translateY(30%);
  }
}

@media (max-width: 479px) {
  /* line 56, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
  .c-avantages__boxes {
    transform: translateY(20%);
  }
}

/* line 80, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
.c-avantages__boxes .c-red-box {
  width: 48%;
}

@media (max-width: 479px) {
  /* line 80, public/wp-content/themes/performair/assets/src/scss/component/_c-avantages.scss */
  .c-avantages__boxes .c-red-box {
    width: 100%;
    margin-bottom: 10px;
    padding: 40px;
  }
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-red-box.scss */
.c-red-box {
  background-color: #eb0a0f;
  padding: 80px 40px;
  position: relative;
  overflow: hidden;
}

/* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-red-box.scss */
.c-red-box:hover .c-red-box__plus {
  right: -90px;
  bottom: -90px;
}

/* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-red-box.scss */
.c-red-box .pre-title:before {
  border-left: 12px solid white;
}

/* line 19, public/wp-content/themes/performair/assets/src/scss/component/_c-red-box.scss */
.c-red-box__plus {
  position: absolute;
  bottom: -150px;
  right: -150px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-item: center;
  width: 150px;
  height: 150px;
  background: white;
  transform: rotate(45deg);
  transition: all 300ms ease;
}

/* line 34, public/wp-content/themes/performair/assets/src/scss/component/_c-red-box.scss */
.c-red-box__plus:before, .c-red-box__plus:after {
  content: '';
  position: absolute;
  top: 0;
  right: 105px;
  bottom: 0;
  left: 0;
  display: block;
  width: 14px;
  height: 2px;
  background: #eb0a0f;
  margin: auto;
}

/* line 49, public/wp-content/themes/performair/assets/src/scss/component/_c-red-box.scss */
.c-red-box__plus:before {
  transform: rotate(45deg);
}

/* line 53, public/wp-content/themes/performair/assets/src/scss/component/_c-red-box.scss */
.c-red-box__plus:after {
  transform: rotate(135deg);
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.c-blog-listing {
  background-color: #fbfbfb;
  padding: 100px 0px 0px;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.c-blog-listing + .c-latest {
  padding: 100px 0px;
}

/* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.c-blog-listing__last-post {
  height: 600px;
  padding: 60px 100px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  /* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
  .c-blog-listing__last-post {
    padding: 60px 40px;
    height: 500px;
  }
}

@media (max-width: 479px) {
  /* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
  .c-blog-listing__last-post {
    padding: 20px 20px;
    height: 400px;
  }
}

/* line 31, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.c-blog-listing__last-post:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background: linear-gradient(45deg, #3f474e 25%, transparent 100%);
}

/* line 42, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.c-blog-listing__last-post > * {
  position: relative;
  z-index: 10;
}

/* line 47, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.c-blog-listing__last-post .date {
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 1.5rem;
  line-height: 2rem;
  color: white;
  text-transform: uppercase;
}

/* line 55, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.c-blog-listing__last-post h3 {
  max-width: 500px;
  margin: 10px 0px;
}

@media (max-width: 700px) {
  /* line 55, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
  .c-blog-listing__last-post h3 {
    font-size: 2rem;
  }
}

/* line 66, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.c-latest__item-content h4 {
  min-height: 9rem;
}

/* line 71, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.js-blog-slider .slick-list {
  box-sizing: initial !important;
}

/* line 75, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.js-blog-slider__arrows-container {
  position: relative;
}

/* line 79, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.js-blog-slider-prev, .js-blog-slider-next {
  position: absolute;
  cursor: pointer;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: white;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  top: 10px;
}

/* line 92, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.js-blog-slider-prev i, .js-blog-slider-next i {
  color: #0f46a0;
  font-size: 2.5rem;
  margin-left: 10px;
}

/* line 99, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.js-blog-slider-prev {
  left: 0;
}

/* line 102, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.js-blog-slider-next {
  right: 0;
}

/* line 107, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.slider-container-large {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

/* line 113, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-listing.scss */
.blog-item__container {
  margin-bottom: 40px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
.c-blog-single {
  padding: 100px 0px 0px;
  background: #fbfbfb;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
.c-blog-single h1 {
  max-width: 100%;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  /* line 10, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
  .c-blog-single p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

/* line 17, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
.c-blog-single .date {
  display: block;
  margin-bottom: 10px;
  color: #89949f;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 1.5rem;
  line-height: 2rem;
}

/* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
.c-blog-single__main-img {
  width: 100%;
  height: 700px;
  margin-bottom: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 992px) {
  /* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
  .c-blog-single__main-img {
    height: 500px;
  }
}

@media (max-width: 768px) {
  /* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
  .c-blog-single__main-img {
    height: 400px;
  }
}

@media (max-width: 479px) {
  /* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
  .c-blog-single__main-img {
    height: 300px;
  }
}

/* line 48, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
.c-blog-single .col_1x1 {
  padding: 0px 50px;
}

@media (max-width: 768px) {
  /* line 48, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
  .c-blog-single .col_1x1 {
    padding: 0px;
  }
}

/* line 56, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
.c-blog-single h3 {
  margin-bottom: 20px;
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-weight: 300;
  line-height: 3rem;
  fnt-size: 2.2rem;
}

/* line 64, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
.c-blog-single h3:not(:first-of-type) {
  margin-top: 40px;
}

/* line 69, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
.c-blog-single__nav {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 60px 0px;
  padding: 40px 0px !important;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

@media (max-width: 479px) {
  /* line 69, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
  .c-blog-single__nav {
    align-items: center;
    flex-flow: column nowrap;
    justify-content: center;
  }
}

/* line 84, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
.c-blog-single__nav a {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  color: #3f474e;
}

/* line 90, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
.c-blog-single__nav a:hover {
  color: #212121;
}

/* line 94, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
.c-blog-single__nav a i {
  color: #eb0a0f;
  font-size: 1.6rem;
}

/* line 100, public/wp-content/themes/performair/assets/src/scss/component/_c-blog-single.scss */
.c-blog-single__nav span {
  display: inline-block;
  margin: 0px 10px;
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.4rem;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-career.scss */
.c-career {
  background: #fbfbfb;
  padding: 100px 0px 50px;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-career.scss */
.c-career .row {
  margin-bottom: 0;
}

/* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-career.scss */
.c-career__links {
  padding-right: 80px;
}

/* line 14, public/wp-content/themes/performair/assets/src/scss/component/_c-career.scss */
.c-career__links li a {
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 2.2rem;
  line-height: 3rem;
  color: #89949f;
  padding: 15px 0px;
  display: block;
  border-bottom: 1px solid #89949f;
}

/* line 24, public/wp-content/themes/performair/assets/src/scss/component/_c-career.scss */
.c-career__links li a:before {
  content: "\f0dd";
  display: inline-block;
  font: normal normal normal 24px/1 FontAwesome;
  text-rendering: auto;
  transform: rotate(-90deg);
  color: #0f46a0;
  opacity: 0;
  transition: all 300ms ease;
  margin-left: -14px;
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-career.scss */
.c-career__links li a:hover:before {
  opacity: 1;
  transform: translateX(10px) rotate(-90deg);
  margin-right: 30px;
  margin-left: -14px;
}

/* line 49, public/wp-content/themes/performair/assets/src/scss/component/_c-career.scss */
.c-career__links li.active a {
  color: #3f474e;
}

/* line 52, public/wp-content/themes/performair/assets/src/scss/component/_c-career.scss */
.c-career__links li.active a:before {
  opacity: 1;
  transform: translateX(10px) rotate(-90deg);
  margin-right: 30px;
  margin-left: -14px;
}

/* line 63, public/wp-content/themes/performair/assets/src/scss/component/_c-career.scss */
.c-career__links li:last-child a {
  border-bottom: 0px;
}

/* line 72, public/wp-content/themes/performair/assets/src/scss/component/_c-career.scss */
.c-career__content .tab {
  display: none;
}

/* line 75, public/wp-content/themes/performair/assets/src/scss/component/_c-career.scss */
.c-career__content .tab.active {
  display: block;
}

@keyframes response-pop-up {
  0% {
    transform: translate3d(-50%, 24px, 0);
    opacity: 0;
  }
  5% {
    transform: translate3d(-50%, 0, 0);
    opacity: 1;
  }
  95% {
    transform: translate3d(-50%, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(-50%, 24px, 0);
    opacity: 0;
  }
}

@keyframes response-pop-up-loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* line 33, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.wpcf7-response-output {
  position: fixed;
  bottom: 50%;
  left: 50%;
  width: 30rem;
  padding: 2rem 2rem 2.5rem 2rem !important;
  transform: translate3d(-50%, -50%, 0);
  border: 2px solid #3c5058 !important;
  background-color: #ffffff;
  color: #3f474e;
  animation: response-pop-up 5s ease forwards;
  font-family: "Helvetica-neue-condensed-regular";
  font-weight: 700;
  line-height: 1.2;
  z-index: 40;
}

/* line 50, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.wpcf7-response-output::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 6px;
  transform-origin: left;
  background-color: #ef4035;
  animation: response-pop-up-loading 4.5s 0.25s linear forwards;
}

/* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form {
  padding: 100px 0px;
  background-color: #fbfbfb;
}

/* line 66, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form h2 {
  margin-bottom: 40px;
}

/* line 71, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form form .col_1x2 {
  width: 48% !important;
}

@media (max-width: 479px) {
  /* line 71, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
  .c-form form .col_1x2 {
    width: 100% !important;
  }
}

@media (max-width: 479px) {
  /* line 78, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
  .c-form form .col_1x2 .wpcf7-form-control-wrap {
    width: 100% !important;
  }
}

/* line 86, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form input[type="text"],
.c-form input[type="email"],
.c-form input[type="tel"] {
  width: 100%;
  margin-bottom: 40px;
  padding: 15px 30px;
  outline: 0;
  border: 0px;
  border-radius: 50px;
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.6rem;
  line-height: 2.4rem;
  border: 1px solid #d0dce8;
  border-radius: 8px;
}

/* line 104, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form input[type="text"]::placeholder,
.c-form input[type="email"]::placeholder,
.c-form input[type="tel"]::placeholder {
  color: #89949f;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 18px;
  font-weight: 400;
}

/* line 112, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form select {
  width: 100%;
  margin-bottom: 40px;
  padding: 15px 30px;
  outline: 0;
  border: 0px;
  border-radius: 50px;
  background: #ebecec;
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.6rem;
  line-height: 2.4rem;
  appearance: none;
}

/* line 127, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form input[type="submit"] {
  margin-top: 0px;
  outline: 0;
  border: 0px;
  cursor: pointer;
  transition: all 300ms ease;
}

/* line 135, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .posteConvoite + .fa {
  position: relative;
  margin-left: -35px;
  color: #3f474e;
  font-size: 1.6rem;
  pointer-events: none;
  z-index: 999;
}

/* line 144, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .wpcf7-form-control-wrap {
  position: relative;
  display: inline-block;
}

/* line 148, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .wpcf7-form-control-wrap.message {
  width: 100%;
}

/* line 152, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .wpcf7-list-item {
  display: block;
  line-height: 25px;
}

/* line 156, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .wpcf7-list-item-label {
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.6rem;
  line-height: 2rem;
}

/* line 164, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form span[role="alert"] {
  position: absolute;
  top: 60px;
  left: 0px;
  display: block;
  width: 100%;
  color: #eb0a0f;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 1.4rem;
  line-height: 2rem;
}

/* line 176, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form textarea {
  width: 100%;
  margin-bottom: 40px;
  padding: 15px 30px;
  outline: 0;
  border: 0px;
  border-radius: 8px;
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.6rem;
  line-height: 2.4rem;
  border: 1px solid #d0dce8;
  border-radius: 8px;
}

/* line 192, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form textarea::placeholder {
  color: #89949f;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 18px;
  font-weight: 400;
}

/* line 200, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form input[type="file"] {
  display: none;
  appearance: none;
}

/* line 205, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .col_1x3 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* line 211, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .ajax-loader {
  display: none !important;
}

/* line 215, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form #c-form__label {
  width: 100%;
  margin-bottom: 40px;
  padding: 15px 30px;
  outline: 0;
  border: 0px;
  border-radius: 50px;
  background: #3f474e;
  color: white;
  cursor: pointer;
  transition: all 300ms ease;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.6rem;
  line-height: 2.4rem;
}

/* line 230, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form #c-form__label:hover {
  background-color: #212121;
}

/* line 235, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .filename {
  position: absolute;
  bottom: -40px;
  left: 0;
  display: block;
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.4rem;
  line-height: 2rem;
}

@media (max-width: 479px) {
  /* line 235, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
  .c-form .filename {
    right: 0;
  }
}

/* line 250, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form div.wpcf7-response-output {
  margin: 40px 0px 0px;
  padding: 0px;
  border: 0px;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 1.4rem;
  line-height: 2rem;
}

/* line 258, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form div.wpcf7-response-output.wpcf7-validation-errors, .c-form div.wpcf7-response-output.wpcf7-mail-sent-ng {
  color: #eb0a0f;
}

/* line 263, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form div.wpcf7-response-output.wpcf7-mail-sent-ok {
  color: #6bb54e;
}

/* line 268, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .wpcf7-form-control-wrap.type {
  display: block;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

/* line 274, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .wpcf7-form-control-wrap.type span.first {
  margin-left: 0px;
}

/* line 279, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .wpcf7-radio {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: flex-start;
  height: 50px;
}

@media (max-width: 479px) {
  /* line 279, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
  .c-form .wpcf7-radio {
    align-items: flex-start;
    flex-flow: column nowrap;
    height: auto;
    margin-bottom: 20px;
  }
}

/* line 293, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .wpcf7-radio label {
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.6rem;
  line-height: 2.4rem;
}

@media (max-width: 479px) {
  /* line 301, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
  .c-form .wpcf7-list-item {
    margin-left: 0;
  }
}

@media (max-width: 479px) {
  /* line 307, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
  .c-form .career-buttons {
    flex-flow: column nowrap;
  }
}

@media (max-width: 479px) {
  /* line 312, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
  .c-form .career-buttons .col_1x2 {
    text-align: center !important;
  }
}

/* line 317, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .career-buttons .col_1x2:first-of-type {
  margin-bottom: 60px;
}

/* line 323, public/wp-content/themes/performair/assets/src/scss/component/_c-form.scss */
.c-form .wpcf7-list-item {
  margin-right: 24px !important;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-subvention.scss */
.c-subvention {
  background-color: #fbfbfb;
}

/* line 4, public/wp-content/themes/performair/assets/src/scss/component/_c-subvention.scss */
.c-subvention .row {
  padding: 100px 0px;
}

@media (max-width: 992px) {
  /* line 4, public/wp-content/themes/performair/assets/src/scss/component/_c-subvention.scss */
  .c-subvention .row {
    padding: 60px 0px;
  }
}

/* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-subvention.scss */
.c-subvention .row:not(:last-child) {
  border-bottom: 2px solid #e0e0e0;
}

@media (max-width: 992px) {
  /* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-subvention.scss */
  .c-subvention .row .col_1x2 {
    width: 100%;
    padding: 20px 40px;
  }
}

@media (max-width: 992px) {
  /* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-subvention.scss */
  .c-subvention .row .col_1x2 {
    padding: 20px;
  }
}

@media (max-width: 992px) {
  /* line 26, public/wp-content/themes/performair/assets/src/scss/component/_c-subvention.scss */
  .c-subvention .row .col_1x2:first-of-type {
    text-align: center;
  }
}

@media (max-width: 992px) {
  /* line 33, public/wp-content/themes/performair/assets/src/scss/component/_c-subvention.scss */
  .c-subvention .row .col_1x2 img {
    margin-bottom: 30px;
    width: 100%;
    max-width: 250px;
  }
}

/* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-subvention.scss */
.c-subvention h2 {
  font-size: 4rem;
  line-height: 4.5rem;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  /* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-subvention.scss */
  .c-subvention h2 {
    font-size: 3rem;
    line-height: 4rem;
  }
}

@media (max-width: 768px) {
  /* line 55, public/wp-content/themes/performair/assets/src/scss/component/_c-subvention.scss */
  .c-subvention p, .c-subvention li {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

/* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-subvention.scss */
.c-subvention img {
  max-width: 400px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash {
    height: auto;
  }
}

/* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__email {
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 2rem;
  line-height: 2.6rem;
  color: white;
}

/* line 17, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__email i {
  margin-right: 5px;
}

/* line 22, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

@media (max-width: 768px) {
  /* line 22, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__header {
    flex-flow: column wrap;
  }
}

/* line 31, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__header .logo {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__header .logo img {
  width: 235px;
}

/* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__header .cta {
  display: flex;
}

@media (max-width: 768px) {
  /* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__header .cta {
    width: 100%;
  }
}

@media (max-width: 479px) {
  /* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__header .cta {
    width: 100%;
    flex-flow: column wrap;
  }
}

/* line 55, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__header .cta a {
  margin-left: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  /* line 55, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__header .cta a {
    width: 50%;
    margin: 0px;
  }
}

@media (max-width: 479px) {
  /* line 55, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__header .cta a {
    width: 100%;
  }
}

/* line 72, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__header .cta a.find-us {
  color: #89949f;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.6rem;
}

@media (max-width: 768px) {
  /* line 72, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__header .cta a.find-us {
    padding: 20px 20px;
  }
}

/* line 81, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__header .cta a.find-us:hover {
  color: #3f474e;
}

/* line 85, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__header .cta a.find-us i {
  margin-right: 10px;
  color: #eb0a0f;
}

/* line 91, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__header .cta a.emergency {
  background: #eb0a0f;
  padding: 20px 40px;
  color: white;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 98, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__header .cta a.emergency:hover {
  background: #f62328;
}

/* line 106, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch {
  flex-grow: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  background: white;
}

@media (max-width: 992px) {
  /* line 106, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__dispatch {
    flex-flow: column wrap;
  }
}

/* line 117, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch .dispatch-col {
  width: 49.5%;
  display: flex;
  flex-flow: column nowrap;
}

@media (max-width: 992px) {
  /* line 117, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__dispatch .dispatch-col {
    width: 100%;
    height: 600px;
  }
}

@media (max-width: 768px) {
  /* line 117, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__dispatch .dispatch-col {
    width: 100%;
    height: 500px;
  }
}

@media (max-width: 479px) {
  /* line 117, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__dispatch .dispatch-col {
    width: 100%;
    height: 400px;
  }
}

/* line 138, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch .secteur-info {
  background: #eb0a0f;
  padding: 20px 30px;
  text-align: center;
}

@media (max-width: 550px) {
  /* line 138, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__dispatch .secteur-info {
    display: flex;
    flex-flow: column nowrap;
  }
}

/* line 148, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch .secteur-info a {
  color: white;
  font-size: 1.8rem;
  margin: 0px 20px;
}

@media (max-width: 550px) {
  /* line 148, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__dispatch .secteur-info a {
    margin: 0 0 10px;
  }
}

/* line 157, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch .secteur-info a i {
  margin-right: 5px;
}

/* line 163, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch-item {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

/* line 180, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch-item:hover:before {
  opacity: 0.75;
}

/* line 184, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch-item:hover .plus-icon {
  bottom: -100px;
  right: -100px;
}

/* line 190, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch-item:before {
  content: '';
  background: #3f474e;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  transition: all 300ms ease;
}

/* line 203, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch-item > * {
  position: relative;
  z-index: 10;
}

/* line 208, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch-item h2 {
  color: white;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-black";
  font-size: 8rem;
  line-height: 8rem;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  /* line 208, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__dispatch-item h2 {
    font-size: 6rem;
    line-height: 6rem;
  }
}

@media (max-width: 479px) {
  /* line 208, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__dispatch-item h2 {
    font-size: 3rem;
    line-height: 3rem;
  }
}

/* line 226, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch-item h2 span {
  display: block;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 4.5rem;
  line-height: 4.5rem;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  /* line 226, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__dispatch-item h2 span {
    font-size: 3rem;
    line-height: 3rem;
  }
}

@media (max-width: 479px) {
  /* line 226, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
  .c-splash__dispatch-item h2 span {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

/* line 246, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch-item .plus-icon {
  position: absolute;
  bottom: -200px;
  right: -200px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-item: center;
  width: 200px;
  height: 200px;
  background: #eb0a0f;
  transform: rotate(45deg);
  transition: all 300ms ease;
}

/* line 261, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch-item .plus-icon:before, .c-splash__dispatch-item .plus-icon:after {
  content: '';
  position: absolute;
  top: 0;
  right: 110px;
  bottom: 0;
  left: 0;
  display: block;
  width: 18px;
  height: 2px;
  background: white;
  margin: auto;
}

/* line 276, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch-item .plus-icon:before {
  transform: rotate(45deg);
}

/* line 280, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__dispatch-item .plus-icon:after {
  transform: rotate(135deg);
}

/* line 287, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__footer {
  padding: 40px 0px;
}

/* line 290, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__footer-links {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin-bottom: 0;
}

/* line 298, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__footer-links li {
  margin: 10px 20px;
}

/* line 302, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__footer-links li a {
  font-size: 1.6rem;
  color: #3f474e;
  text-transform: uppercase;
}

/* line 307, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__footer-links li a:hover {
  color: #0f46a0;
}

/* line 313, public/wp-content/themes/performair/assets/src/scss/component/_c-splash.scss */
.c-splash__footer-links li:last-child a {
  margin: 0px 8px;
}

/* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__banner {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  justify-content: center;
  height: calc(100% - 75px);
  min-height: 700px;
  background-size: cover;
  overflow: hidden;
}

@media (max-width: 768px) {
  /* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__banner {
    min-height: 500px;
  }
}

/* line 17, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__banner-corner {
  position: absolute;
  right: 0px;
  bottom: 0;
  width: 250px;
  height: 307px;
}

@media (max-width: 768px) {
  /* line 17, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__banner-corner {
    width: 150px;
    height: auto;
  }
}

/* line 29, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__banner-corner a {
  position: absolute;
  right: 30px;
  bottom: 20px;
  width: 12px;
  margin: 0 auto;
  color: white;
  text-align: center;
  font-size: 2.5rem;
  z-index: 9999;
}

/* line 42, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__banner-subtitle {
  color: white;
  font-size: 3rem;
  line-height: 3.5rem;
  font-family: "Helvetica-neue-condensed-bold";
}

@media (max-width: 768px) {
  /* line 42, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__banner-subtitle {
    font-size: 2.2rem;
    line-height: 2.7rem;
  }
}

/* line 54, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__banner h1 {
  max-width: 800px;
  margin: 20px auto 60px;
  color: white;
  text-transform: initial;
  font-size: 6rem;
  font-weight: 300;
  line-height: 1;
  font-family: "Helvetica-neue-condensed-regular";
}

@media (max-width: 768px) {
  /* line 54, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__banner h1 {
    font-size: 4rem;
  }
}

/* line 70, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners {
  padding: 100px 0px;
}

/* line 73, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners h2 {
  margin-bottom: 100px;
}

/* line 77, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners ul {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0;
  list-style-type: none;
}

/* line 85, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners ul li {
  width: 20%;
  margin-bottom: 70px;
  padding: 0px 20px;
}

@media (max-width: 768px) {
  /* line 85, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__partners ul li {
    width: 33.33%;
  }
}

@media (max-width: 479px) {
  /* line 85, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__partners ul li {
    width: 50%;
  }
}

/* line 99, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners ul li:nth-child(1) img {
  width: 120px;
}

/* line 105, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners ul li:nth-child(2) img {
  width: 110px;
}

/* line 111, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners ul li:nth-child(3) img {
  width: 90px;
}

/* line 117, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners ul li:nth-child(4) img {
  width: 150px;
}

/* line 123, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners ul li:nth-child(5) img {
  width: 100px;
}

/* line 129, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners ul li:nth-child(6) img {
  width: 120px;
}

/* line 135, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners ul li:nth-child(7) img {
  width: 110px;
}

/* line 141, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners ul li:nth-child(8) img {
  width: 90px;
}

/* line 147, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners ul li:nth-child(9) img {
  width: 120px;
}

/* line 152, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__partners ul li img {
  backface-visibility: hidden;
}

/* line 159, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__secteurs {
  padding: 120px 0px;
}

@media (max-width: 992px) {
  /* line 159, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__secteurs {
    padding-bottom: 60px;
  }
}

/* line 166, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__secteurs .col_1x2 {
  width: 45%;
}

@media (max-width: 768px) {
  /* line 166, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__secteurs .col_1x2 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  /* line 173, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__secteurs .col_1x2:first-of-type {
    margin-bottom: 40px;
  }
}

/* line 180, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__secteurs .col_1x2 p a {
  color: #0f46a0;
}

/* line 187, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe {
  position: relative;
  box-shadow: 0px 0px 50px rgba(63, 71, 78, 0.1);
}

/* line 191, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-image {
  height: 100%;
  min-height: 650px;
}

/* line 195, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-image .slick-list,
.c-commercial__equipe-image .slick-track {
  height: 100%;
}

/* line 200, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-image-slide {
  outline: 0;
  background-repeat: no-repeat;
  background-position: centr;
  background-size: cover;
}

/* line 208, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-content {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  padding: 40px 100px;
  overflow: hidden;
}

/* line 217, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-content:before {
  content: "";
  position: absolute;
  right: -285px;
  bottom: -285px;
  width: 500px;
  height: 500px;
  transform: rotate(45deg);
  background: #eb0a0f;
  transition: all 300ms ease;
}

/* line 229, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-content .pre-title {
  margin-bottom: 40px;
}

/* line 233, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-content-slide {
  outline: 0;
}

/* line 236, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-content-slide h3 {
  margin-bottom: 5px;
  color: #3f474e;
  font-size: 3.5rem;
  line-height: 4rem;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 244, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-content-slide h4 {
  color: #3f474e;
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 3.2rem;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 252, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-content-slide p {
  max-width: 600px;
  font-weight: 300;
  line-height: 3.5rem;
}

/* line 258, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-content-slide a {
  color: #3f474e;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2.4rem;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 265, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-content-slide a:hover {
  color: #eb0a0f;
}

/* line 269, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-content-slide a i {
  margin-right: 10px;
}

/* line 276, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-next {
  position: absolute;
  right: 30px;
  bottom: 30px;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  color: white;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 289, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-next i {
  margin-left: 10px;
  color: #0f46a0;
  font-size: 2.5rem;
}

/* line 296, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-dots {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  justify-content: center;
  width: 50px;
  height: 200px;
  margin: auto;
  border-radius: 50px;
  background: white;
  z-index: 9999;
}

/* line 316, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-dots ul li.slick-active button {
  background-color: #3f474e;
}

/* line 321, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__equipe-dots ul li button {
  width: 15px;
  height: 15px;
  margin: 5px 0px;
  outline: 0;
  border: 0px;
  border-radius: 100%;
  background-color: #e0e0e0;
  cursor: pointer;
  transition: all 300ms ease;
  font-size: 0px;
}

/* line 338, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets {
  padding: 50px 0px;
}

/* line 341, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets h2 {
  margin-bottom: 50px;
}

/* line 345, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-wrapper {
  position: relative;
}

/* line 349, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-prev, .c-commercial__projets-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
}

@media (max-width: 768px) {
  /* line 349, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__projets-prev, .c-commercial__projets-next {
    display: none !important;
  }
}

/* line 364, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-prev i, .c-commercial__projets-next i {
  color: #eb0a0f;
  font-size: 3rem;
}

/* line 370, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-next {
  right: 0px;
}

/* line 374, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-prev {
  left: 0px;
}

/* line 379, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-dots ul {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 50px;
  margin-left: 0;
  list-style-type: none;
}

/* line 390, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-dots ul li.slick-active button {
  background-color: #3f474e;
}

/* line 395, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-dots ul li button {
  width: 15px;
  height: 15px;
  margin: 0px 5px;
  outline: 0;
  border: 0px;
  border-radius: 100%;
  background-color: #89949f;
  cursor: pointer;
  transition: all 300ms ease;
  font-size: 0px;
}

/* line 411, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-slider {
  padding: 0px 60px;
}

@media (max-width: 768px) {
  /* line 411, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__projets-slider {
    padding: 0;
  }
}

/* line 419, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets .row {
  margin-right: 0;
}

/* line 423, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-slide {
  position: relative;
  display: flex !important;
  flex-flow: column wrap;
  justify-content: flex-end;
  min-height: 600px;
  padding: 40px;
  outline: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: left;
}

@media (max-width: 768px) {
  /* line 423, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__projets-slide {
    min-height: 350px;
  }
}

/* line 440, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-slide:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(45deg, #3f474e 0%, rgba(63, 71, 78, 0) 100%);
  pointer-events: none;
  z-index: -1;
}

/* line 452, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-slide > * {
  position: relative;
  z-index: 9999;
}

/* line 457, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-slide .pre-title:before {
  border-left: 12px solid white;
}

/* line 461, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__projets-slide h3 {
  color: white;
  text-transform: uppercase;
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 471, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer {
  position: relative;
}

/* line 474, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer h2 {
  max-width: 300px;
  font-size: 3.4rem;
  line-height: 4.4rem;
}

/* line 480, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer .corner {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
  margin-bottom: 0px;
  backface-visibility: hidden;
}

@media (max-width: 992px) {
  /* line 480, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__footer .corner {
    display: none;
  }
}

/* line 493, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer img {
  width: 100px;
  margin-bottom: 30px;
}

/* line 499, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer .row > * {
  display: flex;
  align-items: flex-start;
  flex-flow: column wrap;
  justify-content: center;
  padding: 120px 40px 60px;
}

@media (max-width: 992px) {
  /* line 507, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__footer .row .col_2x5,
  .c-commercial__footer .row .col_3x5 {
    width: 100%;
  }
}

/* line 514, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer .row .col_2x5 {
  padding-left: 60px;
  background: #f4f5f5;
  text-align: left;
}

@media (max-width: 992px) {
  /* line 514, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__footer .row .col_2x5 {
    align-items: center;
    padding-left: 0;
    text-align: center;
  }
}

/* line 525, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer .row .col_2x5 .copyright {
  margin-top: 60px;
  color: #89949f;
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 534, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer .row .col_3x5 {
  background: #edf0f0;
}

/* line 540, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1250px) {
  /* line 540, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__footer-right-top {
    margin-bottom: 30px;
  }
}

/* line 550, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top > * {
  margin-right: 100px;
}

@media (max-width: 992px) {
  /* line 550, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__footer-right-top > * {
    margin: 0 auto;
  }
}

/* line 558, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top h3 {
  margin-bottom: 10px;
  font-size: 3rem;
  line-height: 3.6rem;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 565, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top h4 {
  margin-bottom: 10px;
  font-size: 2.2rem;
  line-height: 3rem;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 572, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top p {
  font-size: 1.8rem;
  line-height: 2.6rem;
}

@media screen and (max-width: 600px) {
  /* line 577, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__footer-right-top .coordonnees {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
}

/* line 584, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top .coordonnees a {
  display: block;
  margin-bottom: 3px;
  color: #89949f;
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-family: "Helvetica-neue-condensed-regular";
}

@media screen and (max-width: 1150px) {
  /* line 584, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__footer-right-top .coordonnees a {
    margin-bottom: 20px;
  }
}

/* line 596, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top .coordonnees a:hover {
  color: #0f46a0;
}

@media screen and (max-width: 600px) {
  /* line 602, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__footer-right-top .links {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
}

/* line 609, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top .links h3 {
  margin-bottom: 20px;
}

/* line 613, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top .links a {
  display: block;
  margin-bottom: 10px;
  color: #3f474e;
  font-size: 2rem;
  line-height: 3rem;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 621, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top .links a:hover {
  color: #0f46a0;
}

/* line 627, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top .social {
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  margin-right: 0px;
}

@media screen and (max-width: 1250px) {
  /* line 627, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__footer-right-top .social {
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;
    margin-top: 30px;
  }
}

@media (max-width: 992px) {
  /* line 627, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__footer-right-top .social {
    align-items: center;
    justify-content: center;
    margin: 20pxpx 10px;
    text-align: center;
  }
}

@media screen and (max-width: 1250px) {
  /* line 647, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
  .c-commercial__footer-right-top .social a {
    margin-right: 15px;
  }
}

/* line 653, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top .social a:hover i {
  color: #0f46a0;
}

/* line 659, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-top .social i {
  margin-bottom: 30px;
  color: #89949f;
  transition: all 300ms ease;
  font-size: 2.2rem;
}

/* line 669, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-bottom h2 {
  font-size: 4.4rem;
}

/* line 673, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-bottom h2:hover a {
  color: #212121;
}

/* line 677, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-bottom h2:hover i {
  margin-left: 10px;
}

/* line 682, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-bottom h2 a {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  color: #3f474e;
}

/* line 689, public/wp-content/themes/performair/assets/src/scss/component/_c-commercial.scss */
.c-commercial__footer-right-bottom h2 i {
  transform: rotate(-90deg);
  color: #0f46a0;
  transition: all 300ms ease;
  font-size: 3.5rem;
}

/* line 3, public/wp-content/themes/performair/assets/src/scss/component/_c-contact.scss */
.c-contact .row {
  margin-right: 0;
}

/* line 7, public/wp-content/themes/performair/assets/src/scss/component/_c-contact.scss */
.c-contact strong {
  color: #eb0a0f;
}

@media screen and (max-width: 1050px) {
  /* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-contact.scss */
  .c-contact .col_2x3,
  .c-contact .col_1x3 {
    width: 100%;
  }
}

@media screen and (max-width: 1150px) {
  /* line 19, public/wp-content/themes/performair/assets/src/scss/component/_c-contact.scss */
  .c-contact .col_1x3 {
    display: none;
  }
}

@media screen and (max-width: 1050px) {
  /* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-contact.scss */
  .c-contact .col_1x1.right {
    text-align: center;
  }
}

/* line 34, public/wp-content/themes/performair/assets/src/scss/component/_c-contact.scss */
.c-contact a {
  display: block;
  margin-bottom: 6px;
  font-family: "Helvetica-neue-condensed-regular";
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #89949f;
}

/* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-contact.scss */
.c-contact button[type='submit'] {
  cursor: pointer;
  border-color: transparent;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-financement.scss */
.c-financement {
  padding: 100px 0px;
  background: #fbfbfb;
}

@media (max-width: 768px) {
  /* line 6, public/wp-content/themes/performair/assets/src/scss/component/_c-financement.scss */
  .c-financement ul li {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

/* line 14, public/wp-content/themes/performair/assets/src/scss/component/_c-financement.scss */
.c-financement .c-financement_btn {
  margin-top: 50px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique {
  position: relative;
  padding: 100px 0;
  background: white;
}

/* line 6, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique h2 {
  margin-bottom: 40px;
}

/* line 10, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__listing {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0;
  margin-right: -2.33%;
  padding: 0;
  list-style-type: none;
}

@media (max-width: 992px) {
  /* line 10, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique__listing {
    margin: 0 -2% 0 0;
  }
}

@media (max-width: 768px) {
  /* line 10, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique__listing {
    margin: 0;
  }
}

/* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__listing li {
  width: 30%;
  margin-right: 3.33%;
  margin-bottom: 50px;
  box-shadow: 0px 0px 50px rgba(33, 33, 33, 0.2);
}

@media (max-width: 992px) {
  /* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique__listing li {
    width: 48%;
    margin-right: 2%;
  }
}

@media (max-width: 768px) {
  /* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique__listing li {
    width: 100%;
    margin-right: 0;
  }
}

/* line 45, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__listing li:hover .category-image:before {
  opacity: 1;
}

@media (max-width: 479px) {
  /* line 51, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique__listing li .c-button.small {
    padding: 10px 25px;
  }
}

/* line 57, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__listing li .category-image {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
  justify-content: flex-end;
  height: 250px;
  padding: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 479px) {
  /* line 57, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique__listing li .category-image {
    height: 200px;
  }
}

/* line 73, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__listing li .category-image:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  background: linear-gradient(45deg, #3f474e 25%, transparent 100%);
  transition: all 300ms ease;
}

/* line 85, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__listing li .category-image .pre-title {
  margin-bottom: 0px;
}

/* line 88, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__listing li .category-image .pre-title:before {
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #0f46a0;
}

/* line 96, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__listing li .category-image h3 {
  position: relative;
  color: white;
  z-index: 2;
}

/* line 103, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__listing li .content {
  padding: 40px;
  background: white;
}

@media (max-width: 479px) {
  /* line 103, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique__listing li .content {
    padding: 30px 20px;
  }
}

/* line 111, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__listing li .content p {
  font-size: 1.8rem;
  line-height: 2.5rem;
}

@media (max-width: 479px) {
  /* line 111, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique__listing li .content p {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}

/* line 124, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__produits {
  position: relative;
  padding: 150px 0px;
  background: white;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto;
}

/* line 132, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__produits:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #f5f6f6, rgba(245, 246, 246, 0));
  z-index: 0;
}

/* line 143, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__produits .container {
  position: relative;
  z-index: 1;
}

/* line 147, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__produits .container h3 {
  margin-bottom: 20px;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 3.5rem;
  line-height: 4rem;
}

/* line 154, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__produits .container ul {
  margin: 20px 0px;
  padding: 0;
  list-style-type: none;
}

/* line 159, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__produits .container ul li {
  margin: 10px 0px;
}

/* line 162, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__produits .container ul li:before {
  content: "\f0dd";
  display: inline-block;
  margin-right: 10px;
  transform: rotate(-90deg);
  color: #0f46a0;
  text-rendering: auto;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
}

/* line 173, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__produits .container ul li a {
  color: #89949f;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 181, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique__produits .orange-triangle {
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: block;
  transform: rotate(135deg);
  border-top: 75px solid transparent;
  border-right: 75px solid transparent;
  border-bottom: 75px solid rgba(235, 10, 15, 0.8);
  border-left: 75px solid transparent;
}

/* line 194, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing {
  padding: 100px 0px;
  background: white;
}

/* line 198, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing .container:nth-of-type(2) {
  display: flex;
  flex-direction: row;
}

/* line 203, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing .left {
  flex-grow: 1;
}

/* line 207, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing__filters {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 20px;
}

/* line 213, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing__filters span {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 1.4rem;
}

/* line 223, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing__filters button,
.c-boutique-listing__filters a {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 30px;
  border: 1px solid white;
  border-radius: 50px;
  background-color: #0f46a0;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.4rem;
}

/* line 237, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing__filters button:not(:last-of-type),
.c-boutique-listing__filters a:not(:last-of-type) {
  margin-right: 10px;
}

/* line 241, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing__filters button.active,
.c-boutique-listing__filters a.active {
  border-color: #eb0a0f;
  background-color: #eb0a0f;
  color: white;
}

/* line 248, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing__filters button:focus,
.c-boutique-listing__filters a:focus {
  outline: none;
}

/* line 254, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing .c-boutique-sidebar {
  min-width: 275px;
}

/* line 257, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing .c-boutique-sidebar h3 {
  margin-bottom: 20px;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 2.6rem;
  line-height: 3rem;
}

/* line 264, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing .c-boutique-sidebar .boxes-wrapper {
  display: flex;
  flex-direction: column;
}

/* line 268, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing .c-boutique-sidebar .boxes-wrapper a {
  margin-bottom: 30px;
  padding: 60px 40px;
}

/* line 272, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing .c-boutique-sidebar .boxes-wrapper a h3 {
  font-size: 2.2rem;
  line-height: 2.8rem;
}

/* line 279, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing .c-boutique-sidebar ul {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* line 284, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing .c-boutique-sidebar ul li {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: 5px 0px;
  padding: 10px 0px;
  border-bottom: 1px solid #89949f;
  box-shadow: none;
}

/* line 294, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing .c-boutique-sidebar ul li:first-child {
  border-top: 1px solid #89949f;
}

/* line 298, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing .c-boutique-sidebar ul li:before {
  content: "\f0dd";
  display: inline-block;
  margin-right: 10px;
  transform: rotate(-90deg);
  color: #0f46a0;
  text-rendering: auto;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
}

/* line 309, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing .c-boutique-sidebar ul li a {
  color: #89949f;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 317, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing h1 {
  max-width: 100%;
  margin-bottom: 30px;
  text-transform: initial;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 4rem;
  line-height: 4.5rem;
}

/* line 326, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0 -3.33% 0 0;
  padding: 0;
  list-style-type: none;
}

@media (max-width: 992px) {
  /* line 326, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-listing ul {
    margin: 0 -2% 0 0;
  }
}

@media (max-width: 768px) {
  /* line 326, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-listing ul {
    margin: 0;
  }
}

/* line 342, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul li {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 30%;
  margin-right: 3.33%;
  margin-bottom: 40px;
  box-shadow: 0px 0px 50px rgba(33, 33, 33, 0.2);
}

@media (max-width: 992px) {
  /* line 342, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-listing ul li {
    width: 48%;
    margin-right: 2%;
  }
}

@media (max-width: 768px) {
  /* line 342, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-listing ul li {
    width: 100%;
    margin-right: 0;
  }
}

/* line 361, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul li .image-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-flow: column wrap;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  min-height: 225px;
  padding: 50px 30px 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 479px) {
  /* line 361, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-listing ul li .image-wrapper {
    padding: 40px 20px 20px;
  }
}

/* line 379, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul li .image-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(45deg, #3f474e 25%, transparent 100%);
}

/* line 389, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul li .image-wrapper > * {
  position: relative;
  z-index: 10;
}

/* line 395, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul li .pre-title {
  margin-bottom: 0px;
  color: white;
  font-size: 1.4rem;
  line-height: 2rem;
}

/* line 401, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul li .pre-title:before {
  display: block;
  margin-bottom: -5px;
  margin-left: 4px;
  border-width: 10px !important;
}

/* line 408, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul li .pre-title a {
  display: inline;
  color: white;
}

/* line 414, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul li a {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 479px) {
  /* line 421, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-listing ul li a:not(.c-button) {
    align-items: flex-start;
    flex-flow: column wrap;
  }
}

/* line 429, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul li h2 {
  margin-bottom: 0px;
  color: white;
  font-size: 2.3rem;
  line-height: 2.8rem;
}

/* line 436, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul li .price {
  color: white;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 442, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul .content {
  padding: 20px 30px;
}

@media (max-width: 479px) {
  /* line 442, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-listing ul .content {
    padding: 20px;
  }
}

/* line 449, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-listing ul .content a {
  margin-top: 0;
  padding: 10px 30px;
}

/* line 457, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single {
  padding: 150px 0px;
  background: white;
}

@media (max-width: 992px) {
  /* line 457, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-single {
    padding: 100px 0;
  }
}

@media (max-width: 768px) {
  /* line 457, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-single {
    padding: 50px 0;
  }
}

/* line 469, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .product_meta {
  display: none;
}

/* line 473, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
}

@media (max-width: 992px) {
  /* line 473, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-single .single-top-row {
    flex-flow: row wrap;
  }
}

/* line 484, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row > div {
  width: 48%;
}

@media (max-width: 992px) {
  /* line 484, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-single .single-top-row > div {
    width: 100%;
    margin-bottom: 40px;
  }
}

/* line 494, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row .js-single-slider .slick-slide {
  height: 475px;
  outline: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 650px) {
  /* line 494, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-single .single-top-row .js-single-slider .slick-slide {
    height: 400px;
  }
}

@media (max-width: 479px) {
  /* line 494, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-single .single-top-row .js-single-slider .slick-slide {
    height: 300px;
  }
}

@media (max-width: 375px) {
  /* line 494, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-single .single-top-row .js-single-slider .slick-slide {
    height: 250px;
  }
}

/* line 515, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row form.cart {
  display: flex;
  flex-flow: row nowrap;
}

/* line 519, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row form.cart .qty {
  width: 50px;
  height: 40px;
  margin-right: 15px;
  padding-left: 5px;
  outline: 0;
  border: 2px solid #0f46a0;
  border-radius: 0px 0px 0px 10px;
  color: #3f474e;
  text-align: center;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 1.8rem;
  line-height: 1.8rem;
}

/* line 534, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row form.cart button {
  display: inline-flex;
  flex-flow: row wrap;
  margin-top: 20px;
  margin-top: 0px;
  padding: 13px 30px;
  outline: 0px;
  border: 0px;
  border-radius: 0px 50px 50px 50px;
  box-shadow: 0px 0px 30px rgba(33, 33, 33, 0.4);
  background: #0f46a0;
  color: white;
  cursor: pointer;
  transition: all 300ms ease;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 1.4rem;
  letter-spacing: 1px;
  align-iems: center;
}

/* line 554, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row form.cart button:hover {
  background: #135acf;
}

/* line 558, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row form.cart button:after {
  content: "\f0dd";
  display: inline-block;
  transform: rotate(-90deg);
  color: white;
  text-rendering: auto;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
}

/* line 570, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row .woocommerce-product-gallery__wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

/* line 575, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row .woocommerce-product-gallery__wrapper > div {
  margin-right: 15px;
  margin-bottom: 15px;
}

/* line 581, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row figure {
  margin-top: 0px;
  margin-left: 0px;
}

/* line 587, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row .pre-title a {
  color: #89949f;
}

/* line 592, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row h1 {
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid #89949f;
  text-transform: initial;
  font-size: 5rem;
  line-height: 5.5rem;
}

@media (max-width: 768px) {
  /* line 592, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-single .single-top-row h1 {
    font-size: 4rem;
    line-height: 4.5rem;
  }
}

@media (max-width: 479px) {
  /* line 592, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .c-boutique-single .single-top-row h1 {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

/* line 611, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row .price {
  margin-bottom: 30px;
  color: #0f46a0;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 4.5rem;
}

/* line 617, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row .price .amount {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

/* line 623, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row .price .woocommerce-Price-currencySymbol {
  margin-top: -5px;
  font-size: 3rem;
  line-height: 3rem;
}

/* line 630, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .single-top-row h3 {
  margin-bottom: 10px;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 2.4rem;
  line-height: 2.8rem;
}

/* line 638, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .woocommerce-product-details__short-description {
  margin-bottom: 30px;
}

/* line 643, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .related ul {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -3.33% 0 0;
  padding: 0;
  list-style-type: none;
}

/* line 650, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .related ul li {
  width: 30%;
  margin-right: 3.33%;
}

/* line 654, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.c-boutique-single .related ul li .image-wrapper {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* line 665, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products {
  margin-top: 100px;
  box-shadow: none;
}

/* line 669, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products h2 {
  margin-bottom: 40px;
  text-align: left;
  font-size: 4rem;
  line-height: 4.5rem;
}

/* line 676, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0 -3.33% 0 0;
  padding: 0;
  list-style-type: none;
}

@media (max-width: 992px) {
  /* line 676, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .related.products ul {
    margin: 0 -2% 0 0;
  }
}

@media (max-width: 768px) {
  /* line 676, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .related.products ul {
    margin: 0;
  }
}

/* line 692, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul li {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 30%;
  min-height: 350px;
  margin-right: 3.33%;
  margin-bottom: 40px;
  box-shadow: 0px 0px 50px rgba(33, 33, 33, 0.2);
}

@media (max-width: 992px) {
  /* line 692, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .related.products ul li {
    width: 48%;
    margin-right: 2%;
  }
}

@media (max-width: 768px) {
  /* line 692, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .related.products ul li {
    width: 100%;
    margin-right: 0;
  }
}

/* line 712, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul li .image-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-flow: column wrap;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  min-height: 225px;
  padding: 50px 30px 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 479px) {
  /* line 712, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .related.products ul li .image-wrapper {
    padding: 40px 20px 20px;
  }
}

/* line 730, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul li .image-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(45deg, #3f474e 25%, transparent 100%);
}

/* line 740, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul li .image-wrapper > * {
  position: relative;
  z-index: 10;
}

/* line 746, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul li .pre-title {
  margin-bottom: 0px;
  color: white;
  font-size: 1.4rem;
  line-height: 2rem;
}

/* line 752, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul li .pre-title:before {
  display: block;
  margin-bottom: -5px;
  margin-left: 4px;
  border-width: 10px !important;
}

/* line 759, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul li .pre-title a {
  color: white;
}

/* line 764, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul li a {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 479px) {
  /* line 771, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .related.products ul li a:not(.c-button) {
    align-items: flex-start;
    flex-flow: column wrap;
  }
}

/* line 779, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul li h2 {
  margin-bottom: 0px;
  color: white;
  font-size: 3rem;
  line-height: 3.5rem;
}

@media (max-width: 479px) {
  /* line 779, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .related.products ul li h2 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

/* line 791, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul li .price {
  color: white;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 797, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul .content {
  padding: 20px 30px;
}

@media (max-width: 479px) {
  /* line 797, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
  .related.products ul .content {
    padding: 20px;
  }
}

/* line 804, public/wp-content/themes/performair/assets/src/scss/component/_c-boutique.scss */
.related.products ul .content a {
  margin-top: 0;
  padding: 10px 30px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion {
  padding: 100px 0px;
  background: white;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion--career {
  padding: 0;
  background: #fbfbfb;
}

/* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion--career .c-accordion-item {
  padding: 40px 50px 40px 40px;
}

@media (max-width: 768px) {
  /* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion--career .c-accordion-item {
    padding: 30px 40px 30px 30px;
  }
}

@media (max-width: 768px) {
  /* line 16, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion--career .c-accordion-item > h3 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}

/* line 25, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion-item {
  position: relative;
  margin-bottom: 30px;
  padding: 40px 30% 40px 40px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 300ms ease;
  border-radius: 8px;
}

/* line 34, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion-item:last-of-type {
  margin-bottom: 0;
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion-item:hover {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
}

/* line 42, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion-item .toggle-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50px;
  height: 100%;
}

@media (max-width: 768px) {
  /* line 42, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion-item .toggle-icon {
    top: 20px;
    right: -20px;
  }
}

/* line 57, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion-item .toggle-icon .plus {
  position: absolute;
  right: 28px;
  bottom: 40px;
  z-index: 999;
}

/* line 63, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion-item .toggle-icon .plus:before, .c-accordion-item .toggle-icon .plus:after {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: white;
}

/* line 72, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion-item .toggle-icon .plus:after {
  transform: rotate(90deg) translateX(-2px);
}

/* line 77, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion-item .toggle-icon .triangle {
  position: absolute;
  margin-top: 10px;
  transform: rotate(45deg);
  border-top: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #eb0a0f;
}

/* line 89, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content {
  display: none;
  margin-top: 40px;
}

/* line 93, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content span.accordion-sub {
  display: block;
  margin-bottom: 20px;
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 2.2rem;
  line-height: 3rem;
}

/* line 102, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content h1,
.c-accordion .accordion-content h2,
.c-accordion .accordion-content h3,
.c-accordion .accordion-content h4,
.c-accordion .accordion-content h5,
.c-accordion .accordion-content h6 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #3f474e;
  text-transform: initial;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 115, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content h1 {
  font-size: 3rem;
  line-height: 3.5rem;
}

@media (max-width: 768px) {
  /* line 115, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .accordion-content h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

/* line 125, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content h2 {
  font-size: 2.8rem;
  line-height: 3.3rem;
}

@media (max-width: 768px) {
  /* line 125, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .accordion-content h2 {
    font-size: 1.8rem;
    line-height: 2.3rem;
  }
}

/* line 135, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content h3 {
  font-size: 2.4rem;
  line-height: 2.9rem;
}

@media (max-width: 768px) {
  /* line 135, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .accordion-content h3 {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }
}

/* line 145, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content h4 {
  font-size: 2.2rem;
  line-height: 2.7rem;
}

@media (max-width: 768px) {
  /* line 145, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .accordion-content h4 {
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
}

/* line 155, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content h5 {
  font-size: 2rem;
  line-height: 2.5rem;
}

@media (max-width: 768px) {
  /* line 155, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .accordion-content h5 {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
}

/* line 165, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content h6 {
  font-size: 1.8rem;
  line-height: 2.3rem;
}

@media (max-width: 768px) {
  /* line 165, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .accordion-content h6 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

/* line 175, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content p {
  font-size: 1.6rem;
  line-height: 2.2rem;
}

@media (max-width: 768px) {
  /* line 175, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .accordion-content p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

/* line 185, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content p:last-of-type {
  margin-bottom: 50px;
}

/* line 189, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content strong {
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 193, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content ul {
  margin: 20px 0px 30px 20px;
}

/* line 196, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content ul li {
  font-size: 1.6rem;
  line-height: 2.2rem;
}

/* line 202, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .accordion-content ul:last-of-type {
  margin-bottom: 50px;
}

/* line 207, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion.-lightblue {
  background-color: #eef4ff;
}

/* line 211, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_header {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  text-align: center;
}

/* line 218, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* line 223, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_single {
  padding: 24px 40px;
  border-radius: 8px;
  background-color: #fff;
}

@media (max-width: 768px) {
  /* line 223, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .c-accordion_single {
    padding: 20px 20px;
  }
}

/* line 233, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_single.-job .c-accordion_body {
  border: none;
}

/* line 238, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_head {
  position: relative;
  cursor: pointer;
}

/* line 242, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_head h5 {
  margin: 0;
  color: #212121;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 32px;
  font-weight: 400;
}

@media (max-width: 768px) {
  /* line 242, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .c-accordion_head h5 {
    font-size: 28px;
  }
}

/* line 255, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_head-infos {
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
  padding-top: 20px;
  border-top: 1px solid #d0dce8;
}

@media (max-width: 500px) {
  /* line 255, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .c-accordion_head-infos {
    flex-direction: column;
  }
}

/* line 266, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_head-infos p {
  width: 33.333%;
}

@media (max-width: 500px) {
  /* line 266, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .c-accordion_head-infos p {
    width: 100%;
  }
}

/* line 275, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_toggle {
  position: absolute;
  top: -6px;
  right: 0;
  cursor: pointer;
}

@media (max-width: 768px) {
  /* line 275, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .c-accordion_toggle {
    top: -30px;
    right: -40px;
  }
}

/* line 286, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_toggle.-center {
  top: 36px;
  right: 40px;
}

/* line 291, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_toggle.-center.is-open:after {
  content: "-";
  background-color: #212121;
}

/* line 298, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_toggle::after {
  content: "+";
  display: inline-flex;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: #eb0a0f;
  color: #fff;
  transition: all ease-in 150ms;
  font-size: 36px;
}

@media (max-width: 768px) {
  /* line 298, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
  .c-accordion .c-accordion_toggle::after {
    width: 36px;
    height: 36px;
    font-size: 28px;
  }
}

/* line 318, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_body {
  display: none;
  margin-top: 32px;
  border-top: 1px solid #d0dce8;
}

/* line 323, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_body h3 {
  margin-bottom: 12px;
  color: #0f46a0;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 24px;
  font-weight: 400;
}

/* line 331, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_body ul {
  margin-bottom: 40px;
}

/* line 336, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_footer {
  padding: 40px 0;
  border-top: 1px solid #d0dce8;
}

/* line 341, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_action {
  margin-top: 40px;
}

/* line 348, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_single.is-open .c-accordion_toggle::after {
  content: "-";
  background-color: #212121;
}

/* line 354, public/wp-content/themes/performair/assets/src/scss/component/_c-accordion.scss */
.c-accordion .c-accordion_single.is-open .c-accordion_body {
  display: block;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-results.scss */
.c-results {
  background: #fbfbfb;
  padding: 100px 0px 50px;
  border-bottom: 1px solid #e0e0e0;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
.c-eval-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(63, 71, 78, 0.9);
  z-index: 999999;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all 200ms ease;
  padding: 40px 20px 20px;
  overflow: auto;
}

/* line 19, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
.c-eval-form.active {
  visibility: visible;
  opacity: 1;
}

/* line 24, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
.c-eval-form .wrapper {
  max-width: 800px;
  width: 100%;
  margin: auto;
  background: #fbfbfb;
  padding: 50px;
  border-radius: 10px;
  position: relative;
}

@media (max-width: 600px) {
  /* line 24, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
  .c-eval-form .wrapper {
    padding: 50px 40px;
  }
}

@media (max-width: 479px) {
  /* line 24, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
  .c-eval-form .wrapper {
    padding: 50px 30px 30px;
  }
}

/* line 41, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
.c-eval-form .wrapper .close-eval-form {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #0f46a0;
  color: white;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

@media (max-width: 479px) {
  /* line 41, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
  .c-eval-form .wrapper .close-eval-form {
    top: -20px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}

/* line 64, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
.c-eval-form .wrapper .close-eval-form i {
  font-size: 18px;
}

/* line 70, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
.c-eval-form h2 {
  margin-bottom: 10px;
}

@media (max-width: 479px) {
  /* line 70, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
  .c-eval-form h2 {
    font-size: 2.2rem;
    line-height: 2.6rem;
    margin-bottom: 20px;
  }
}

/* line 80, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
.c-eval-form p {
  margin-bottom: 40px;
}

@media (max-width: 479px) {
  /* line 80, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
  .c-eval-form p {
    display: none;
  }
}

/* line 89, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
.c-eval-form.c-form input[type="text"],
.c-eval-form.c-form input[type="tel"],
.c-eval-form.c-form input[type="email"] {
  margin-bottom: 30px;
}

@media (max-width: 600px) {
  /* line 89, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
  .c-eval-form.c-form input[type="text"],
  .c-eval-form.c-form input[type="tel"],
  .c-eval-form.c-form input[type="email"] {
    padding: 10px 20px;
    border-radius: 5px;
  }
}

@media (max-width: 479px) {
  /* line 89, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
  .c-eval-form.c-form input[type="text"],
  .c-eval-form.c-form input[type="tel"],
  .c-eval-form.c-form input[type="email"] {
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  /* line 104, public/wp-content/themes/performair/assets/src/scss/component/_c-eval-form.scss */
  .c-eval-form.c-form textarea {
    padding: 10px 20px;
    border-radius: 5px;
    height: 140px;
  }
}

/* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__block-text-image {
  padding: 150px 0 50px;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__block-text-image .c-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, 50%);
  display: inline-table;
}

/* line 14, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__block-text-image .row.img-right {
  flex-direction: row-reverse;
}

@media screen and (max-width: 767px) {
  /* line 13, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
  .c-ferroviaire__block-text-image .row {
    display: block;
  }
  /* line 19, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
  .c-ferroviaire__block-text-image .row .col_1x2 {
    width: 100%;
  }
}

/* line 25, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__cta {
  background: #0f46a0;
  padding: 100px 0 1px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  /* line 29, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
  .c-ferroviaire__cta .row {
    display: block;
  }
  /* line 32, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
  .c-ferroviaire__cta .row .col_1x2 {
    width: 100%;
  }
}

/* line 37, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__cta h2 {
  color: #fff;
}

/* line 41, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__cta-img img {
  height: 100%;
  object-fit: cover;
}

/* line 46, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__cta-col {
  padding-left: 60px;
}

@media screen and (max-width: 767px) {
  /* line 46, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
  .c-ferroviaire__cta-col {
    padding-left: 0px;
    margin-top: 40px;
  }
}

/* line 52, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__cta-col .surtitle {
  font-size: 2rem;
  margin-bottom: 1rem;
  display: inline-block;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 59, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__cta-content {
  margin-top: 2rem;
}

/* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__cta-content .content p {
  color: #fff;
  font-size: 1.5rem;
}

/* line 68, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__cta-or {
  position: relative;
  text-align: center;
  display: block;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 1.5rem;
}

/* line 75, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__cta-or i {
  display: inline-block;
  background: #0f46a0;
  padding: 0 20px;
  font-style: inherit;
  font-size: 2.2rem;
  position: relative;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 84, public/wp-content/themes/performair/assets/src/scss/component/_c-ferroviaire.scss */
.c-ferroviaire__cta-or:before {
  width: 100%;
  background: #fff;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 1px;
  position: absolute;
  content: '';
  left: 0;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-cart.scss */
.c-cart {
  background: white;
  padding: 100px 0px;
  min-height: 100%;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce {
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 16px;
  color: #89949f;
}

/* line 6, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce a,
.woocommerce button {
  color: #0f46a0;
}

/* line 10, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce a.button.alt,
.woocommerce button.button.alt {
  background: #0f46a0;
  color: white;
  font-family: "Helvetica-neue-condensed-regular";
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-weight: 300;
}

/* line 20, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover {
  background-color: #0f46a0;
  color: white;
}

/* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce h3 {
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 30, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce h3#order_review_heading {
  margin-top: 30px;
  margin-bottom: 10px;
}

/* line 39, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-MyAccount-navigation ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
}

/* line 46, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-MyAccount-navigation ul li {
  margin-right: 15px;
  margin-bottom: 10px;
}

/* line 59, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-billing-fields h3,
.woocommerce-account-fields h3,
.woocommerce-additional-fields h3,
.woocommerce-shipping-fields h3,
.woocommerce-EditAccountForm h3 {
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 63, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-billing-fields p.form-row,
.woocommerce-account-fields p.form-row,
.woocommerce-additional-fields p.form-row,
.woocommerce-shipping-fields p.form-row,
.woocommerce-EditAccountForm p.form-row {
  width: 100%;
}

/* line 66, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-billing-fields label,
.woocommerce-account-fields label,
.woocommerce-additional-fields label,
.woocommerce-shipping-fields label,
.woocommerce-EditAccountForm label {
  display: block;
  margin-bottom: 0px;
}

/* line 70, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-billing-fields .woocommerce-input-wrapper,
.woocommerce-account-fields .woocommerce-input-wrapper,
.woocommerce-additional-fields .woocommerce-input-wrapper,
.woocommerce-shipping-fields .woocommerce-input-wrapper,
.woocommerce-EditAccountForm .woocommerce-input-wrapper {
  width: 100%;
}

/* line 74, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-billing-fields input,
.woocommerce-account-fields input,
.woocommerce-additional-fields input,
.woocommerce-shipping-fields input,
.woocommerce-EditAccountForm input {
  height: 40px;
  border: 1px solid #89949f;
  padding: 10px;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 82, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-billing-fields .form-row textarea.input-text,
.woocommerce-account-fields .form-row textarea.input-text,
.woocommerce-additional-fields .form-row textarea.input-text,
.woocommerce-shipping-fields .form-row textarea.input-text,
.woocommerce-EditAccountForm .form-row textarea.input-text {
  border: 1px solid #89949f;
  padding: 10px;
  height: 150px;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 90, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-billing-fields .woocommerce-Button.button,
.woocommerce-account-fields .woocommerce-Button.button,
.woocommerce-additional-fields .woocommerce-Button.button,
.woocommerce-shipping-fields .woocommerce-Button.button,
.woocommerce-EditAccountForm .woocommerce-Button.button {
  background: #0f46a0;
  color: white;
  font-family: "Helvetica-neue-condensed-regular";
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-weight: 300;
}

/* line 100, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-billing-fields .woocommerce-Button.button:hover,
.woocommerce-account-fields .woocommerce-Button.button:hover,
.woocommerce-additional-fields .woocommerce-Button.button:hover,
.woocommerce-shipping-fields .woocommerce-Button.button:hover,
.woocommerce-EditAccountForm .woocommerce-Button.button:hover {
  background-color: #0f46a0;
  color: white;
}

/* line 107, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-message {
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 16px;
  color: #89949f;
  border-top-color: #0f46a0;
}

/* line 113, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-message:before {
  color: #0f46a0;
}

/* line 117, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-message a {
  color: #0f46a0;
}

/* line 122, public/wp-content/themes/performair/assets/src/scss/component/_c-woocommerce.scss */
.woocommerce-shipping-calculator {
  display: none;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
.covid {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 99999;
}

/* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
.covid__container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  max-width: 900px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  background: white;
}

@media (max-width: 992px) {
  /* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
  .covid__container {
    flex-flow: column nowrap;
  }
}

/* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
.covid__image {
  width: 100%;
  max-width: 420px;
  object-fit: contain;
}

@media (max-width: 992px) {
  /* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
  .covid__image {
    display: none;
  }
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
.covid__text {
  position: relative;
  flex-grow: 1;
  padding: 40px 30px 30px;
}

/* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
.covid__text h3 {
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  /* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
  .covid__text h3 {
    font-size: 2.5rem;
  }
}

/* line 51, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
.covid__text p {
  font-size: 17px;
  line-height: 24px;
}

/* line 55, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
.covid__text p a {
  color: #0f46a0;
}

/* line 61, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
.covid__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 20px;
  color: #eb0a0f;
  cursor: pointer;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 16px;
}

/* line 71, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
.covid__close:before, .covid__close:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 14px;
  height: 0px;
  margin: auto 0;
  transform-origin: center;
  border-bottom: 2px solid #eb0a0f;
}

/* line 85, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
.covid__close:before {
  transform: rotate(45deg);
}

/* line 89, public/wp-content/themes/performair/assets/src/scss/component/_c-covid.scss */
.covid__close:after {
  transform: rotate(-45deg);
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-search.scss */
.c-search {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

/* line 6, public/wp-content/themes/performair/assets/src/scss/component/_c-search.scss */
.c-search__form {
  width: 100%;
}

/* line 10, public/wp-content/themes/performair/assets/src/scss/component/_c-search.scss */
.c-search__text {
  border-radius: 50px;
  width: calc(100% - 140px);
  padding: 6px 30px 8px;
  border: 0;
  background: #ebecec;
  font-family: Helvetica-neue-condensed-regular;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #3f474e;
  margin-top: 5px;
  margin-bottom: 40px;
  outline: 0;
}

@media (min-width: 768px) {
  /* line 10, public/wp-content/themes/performair/assets/src/scss/component/_c-search.scss */
  .c-search__text {
    width: 33%;
  }
}

/* line 29, public/wp-content/themes/performair/assets/src/scss/component/_c-search.scss */
.c-search__submit {
  background-color: #0054a4;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 10px 30px;
  font-size: 1.4rem;
  font-family: Helvetica-neue-condensed-regular;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #fff;
  float: right;
}

@media (min-width: 768px) {
  /* line 29, public/wp-content/themes/performair/assets/src/scss/component/_c-search.scss */
  .c-search__submit {
    float: none;
  }
}

/* line 49, public/wp-content/themes/performair/assets/src/scss/component/_c-search.scss */
.c-search .c-form {
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero {
  position: relative;
  height: 86vh;
}

@media (max-width: 820px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
  .c-home-hero {
    height: initial;
  }
}

/* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-home-hero_slider {
  height: 100%;
}

/* line 13, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .slick-list,
.c-home-hero .slick-track {
  height: 100%;
}

/* line 18, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-home-hero_slide {
  display: flex;
  align-items: center;
  height: 100%;
}

@media (max-width: 820px) {
  /* line 18, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
  .c-home-hero .c-home-hero_slide {
    flex-direction: column-reverse;
  }
}

/* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-home-hero_wrapper {
  width: 45%;
  height: 100%;
}

@media (max-width: 820px) {
  /* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
  .c-home-hero .c-home-hero_wrapper {
    width: 100%;
  }
}

/* line 37, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-home-hero_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0 80px;
  padding: 130px 0 0;
}

@media (max-width: 820px) {
  /* line 37, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
  .c-home-hero .c-home-hero_content {
    padding: 70px 0 0;
  }
}

@media (max-width: 600px) {
  /* line 37, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
  .c-home-hero .c-home-hero_content {
    margin: 0 40px;
  }
}

/* line 53, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-home-hero_content h1 {
  color: #3f474e;
  font-size: 56px;
}

@media (max-width: 820px) {
  /* line 53, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
  .c-home-hero .c-home-hero_content h1 {
    font-size: 35px;
  }
}

/* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-home-hero_content h5 {
  margin: 10px 0;
  color: #eb0a0f;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 32px;
}

/* line 70, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-home-hero_content .c-home-hero_subtitle {
  margin-bottom: 40px;
  color: #3f474e;
}

/* line 76, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-slider--scroll {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 40px;
  border-top: 1px solid #d0dce8;
  color: #89949f;
  font-size: 18px;
}

@media (max-width: 820px) {
  /* line 76, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
  .c-home-hero .c-slider--scroll {
    margin-top: 80px;
  }
}

/* line 89, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-slider--scroll::before {
  content: url("../images/svg/continueScroll.svg");
  margin-top: 5px;
}

/* line 95, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-home-hero_img {
  width: 55%;
  height: 100%;
}

@media (max-width: 820px) {
  /* line 95, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
  .c-home-hero .c-home-hero_img {
    width: 100%;
    height: 275px;
    max-height: 275px;
  }
}

/* line 105, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-home-hero_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* line 112, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-tags {
  display: flex;
  gap: 10px;
}

/* line 116, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .c-tags .c-tag {
  padding: 6px 10px;
  border-radius: 4px;
  background-color: #eef4ff;
  color: #0f46a0;
  font-size: 14px;
}

/* line 125, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .slick-arrow {
  position: absolute;
  top: 47%;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  background-color: #fff;
  cursor: pointer;
  z-index: 5;
}

@media (max-width: 820px) {
  /* line 125, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
  .c-home-hero .slick-arrow {
    top: 39%;
  }
}

/* line 142, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .slick-arrow::after {
  content: url("../images/svg/slider-arrow.svg");
}

/* line 147, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .prevArrow {
  right: unset;
  left: 47%;
}

@media (max-width: 820px) {
  /* line 147, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
  .c-home-hero .prevArrow {
    left: 40px;
  }
}

/* line 156, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .nextArrow {
  transform: scaleX(-1);
}

/* line 160, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .slick-dots {
  position: absolute;
  top: 80px;
  left: 80px;
  display: flex;
  gap: 8px;
  margin: 0;
}

@media (max-width: 820px) {
  /* line 160, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
  .c-home-hero .slick-dots {
    top: 40.3%;
    left: 40%;
  }
}

@media (max-width: 500px) {
  /* line 160, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
  .c-home-hero .slick-dots {
    left: 33%;
  }
}

/* line 177, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .slick-dots li {
  list-style-type: none;
}

/* line 180, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .slick-dots li .slider-dot-wrapper {
  padding: 6px;
}

/* line 184, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .slick-dots li .slider-dot {
  width: 12px;
  height: 12px;
  border: none;
  border-radius: 50px;
  background-color: #d0dce8;
  list-style-type: none;
}

/* line 195, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .slick-dots .slick-active .slider-dot-wrapper {
  border: 1px solid #d0dce8;
  border-radius: 50px;
}

/* line 199, public/wp-content/themes/performair/assets/src/scss/component/_c-home-hero.scss */
.c-home-hero .slick-dots .slick-active .slider-dot {
  background-color: #0f46a0;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
.c-page-header {
  background-color: #fff;
}

/* line 4, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
.c-page-header .c-page-header_wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  /* line 4, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
  .c-page-header .c-page-header_wrapper {
    flex-direction: column;
  }
}

/* line 13, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
.c-page-header .c-page-header_content {
  width: 50%;
  padding: 0 80px;
}

@media (max-width: 768px) {
  /* line 13, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
  .c-page-header .c-page-header_content {
    width: 100%;
    padding: 40px;
  }
}

/* line 22, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
.c-page-header .c-page-header_content h1 {
  margin-bottom: 40px;
  color: #0f46a0;
}

/* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
.c-page-header .c-page-header_content p {
  color: #3f474e;
  font-size: 18px;
}

/* line 32, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
.c-page-header .c-page-header_content li {
  font-size: 18px;
}

/* line 37, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
.c-page-header .c-page-header_img {
  position: relative;
  width: 50%;
  height: 600px;
  overflow: hidden;
}

@media (max-width: 768px) {
  /* line 37, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
  .c-page-header .c-page-header_img {
    width: 100%;
    height: 400px;
  }
}

/* line 48, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
.c-page-header .c-page-header_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* line 54, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
.c-page-header .c-page-header_img::after {
  content: "";
  position: absolute;
  right: -130px;
  bottom: -110px;
  width: 250px;
  height: 320px;
  transform: rotate(217deg);
  background-color: #eb0a0f;
}

@media (max-width: 768px) {
  /* line 54, public/wp-content/themes/performair/assets/src/scss/component/_c-page_header.scss */
  .c-page-header .c-page-header_img::after {
    display: none;
  }
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
.c-marque-listing {
  padding: 0 80px;
  background: #eef4ff;
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
  .c-marque-listing {
    padding: 0 40px;
  }
}

/* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
.c-marque-listing .c-marque-listing_single {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-top: 120px;
  padding-bottom: 120px;
}

@media (max-width: 768px) {
  /* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
  .c-marque-listing .c-marque-listing_single {
    flex-direction: column-reverse;
    gap: 48px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

/* line 23, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
.c-marque-listing .c-marque-listing_single:not(:last-child) {
  border-bottom: 1px solid #d0dce8;
}

/* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
.c-marque-listing .c-marque-single_content {
  width: 50%;
}

@media (max-width: 768px) {
  /* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
  .c-marque-listing .c-marque-single_content {
    width: 100%;
  }
}

/* line 35, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
.c-marque-listing .c-marque-single_content h2 {
  margin-bottom: 20px;
  color: #0f46a0;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-black";
  font-size: 64px;
  line-height: 5.5rem;
}

/* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
.c-marque-listing .c-marque-single_content h4 {
  margin-bottom: 24px;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 24px;
}

/* line 50, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
.c-marque-listing .c-marque-single_content p {
  margin-bottom: 48px;
  color: #3f474e;
  font-size: 18px;
}

/* line 57, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
.c-marque-listing .c-marque-single_media {
  display: flex;
  flex-direction: column;
  width: 50%;
  border-radius: 8px;
  background-color: #fff;
}

@media (max-width: 768px) {
  /* line 57, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
  .c-marque-listing .c-marque-single_media {
    width: 100%;
  }
}

/* line 69, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
.c-marque-listing .c-marque-single_media .c-marque-single_img img {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
}

/* line 77, public/wp-content/themes/performair/assets/src/scss/component/_c-marque-listing.scss */
.c-marque-listing .c-marque-single_media .c-marque-single_logo {
  display: flex;
  justify-content: center;
  padding: 20px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
.c-cta {
  padding: 120px 80px;
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
  .c-cta {
    padding: 60px 40px;
  }
}

/* line 8, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
.c-cta .c-cta_wrapper {
  display: flex;
  gap: 24px;
}

@media (max-width: 768px) {
  /* line 8, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
  .c-cta .c-cta_wrapper {
    flex-direction: column;
  }
}

/* line 17, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
.c-cta .c-cta_single {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background-color: #eef4ff;
}

/* line 25, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
.c-cta .c-cta_single.-center .c-cta_content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* line 33, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
.c-cta .c-cta_media {
  position: relative;
  flex: 1;
  min-height: 300px;
}

@media (max-width: 768px) {
  /* line 33, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
  .c-cta .c-cta_media {
    min-height: 200px;
  }
}

/* line 42, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
.c-cta .c-cta_media img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px 16px 0 0;
  object-fit: cover;
}

/* line 52, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
.c-cta .c-cta_content {
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
}

@media (max-width: 768px) {
  /* line 52, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
  .c-cta .c-cta_content {
    padding: 20px;
  }
}

/* line 63, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
.c-cta .c-cta_content h5 {
  margin: 0;
  font-size: 32px;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 69, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
.c-cta .c-cta_content p {
  color: #3f474e;
  font-size: 24px;
}

/* line 74, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
.c-cta .c-cta_content .c-cta_action {
  width: fit-content;
  margin: 16px 0 20px;
}

@media (max-width: 768px) {
  /* line 74, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
  .c-cta .c-cta_content .c-cta_action {
    margin: 20px 0;
  }
  /* line 81, public/wp-content/themes/performair/assets/src/scss/component/_c-cta.scss */
  .c-cta .c-cta_content .c-cta_action a {
    font-size: 12px;
  }
}

/* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_banner {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

/* line 8, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper {
  padding: 80px 80px 120px;
}

@media (max-width: 768px) {
  /* line 8, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
  .c-single-marque .c-single-marque_wrapper {
    padding: 40px 40px 60px;
  }
}

/* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper .c-single-marque_heading {
  margin-bottom: 80px;
}

/* line 19, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper .c-single-marque_content {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  /* line 19, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
  .c-single-marque .c-single-marque_wrapper .c-single-marque_content {
    flex-direction: column;
  }
}

/* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper .c-single-marque_content .c-single-marque_title {
  width: 50%;
}

@media (max-width: 768px) {
  /* line 27, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
  .c-single-marque .c-single-marque_wrapper .c-single-marque_content .c-single-marque_title {
    width: 100%;
  }
}

/* line 34, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper .c-single-marque_content .c-single-marque_title h1 {
  color: #0f46a0;
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper .c-single-marque_content .c-single-marque_title .subtitle {
  color: #3f474e;
  font-size: 24px;
}

/* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper .c-single-marque_content .c-single-marque_logo {
  display: flex;
  justify-content: center;
  width: 40%;
  margin-bottom: 80px;
  padding: 48px 0;
  border: 1px solid #d0dce8;
  border-radius: 8px;
}

@media (max-width: 768px) {
  /* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
  .c-single-marque .c-single-marque_wrapper .c-single-marque_content .c-single-marque_logo {
    width: 100%;
  }
}

/* line 59, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper .c-single-marque_media {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  /* line 59, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
  .c-single-marque .c-single-marque_wrapper .c-single-marque_media {
    flex-direction: column;
  }
}

/* line 67, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper .c-single-marque_media .c-single-marque_description {
  width: 50%;
}

@media (max-width: 768px) {
  /* line 67, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
  .c-single-marque .c-single-marque_wrapper .c-single-marque_media .c-single-marque_description {
    width: 100%;
  }
}

/* line 75, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper .c-single-marque_media .c-single-marque_avantages {
  width: 40%;
}

@media (max-width: 768px) {
  /* line 75, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
  .c-single-marque .c-single-marque_wrapper .c-single-marque_media .c-single-marque_avantages {
    width: 100%;
  }
}

/* line 83, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper .c-single-marque_media h3 {
  margin-bottom: 24px;
  color: #0f46a0;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 32px;
}

/* line 91, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper .c-single-marque_media p {
  color: #3f474e;
  font-size: 18px;
}

/* line 96, public/wp-content/themes/performair/assets/src/scss/component/_c-single-marque.scss */
.c-single-marque .c-single-marque_wrapper .c-single-marque_media li {
  color: #3f474e;
  font-size: 18px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-filters.scss */
.c-filters {
  display: flex;
  gap: 40px;
  margin-bottom: 48px;
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-filters.scss */
  .c-filters {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 24px;
  }
}

/* line 12, public/wp-content/themes/performair/assets/src/scss/component/_c-filters.scss */
.c-filters.-job {
  justify-content: space-between;
}

/* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-filters.scss */
.c-filters.-job .c-filter_wrapper {
  display: flex;
  justify-content: space-between;
  width: 33%;
}

@media (max-width: 768px) {
  /* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-filters.scss */
  .c-filters.-job .c-filter_wrapper {
    flex-direction: column;
    width: 100%;
  }
}

/* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-filters.scss */
.c-filters_link {
  color: #89949f;
  cursor: pointer;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 24px;
}

@media (max-width: 768px) {
  /* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-filters.scss */
  .c-filters_link {
    width: fit-content;
  }
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-filters.scss */
.c-filters_link.is-active {
  border-bottom: 1px solid #0f46a0;
  color: #0f46a0;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-products {
  padding: 120px 80px 0;
  background-color: #eef4ff;
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
  .c-products {
    padding: 60px 40px 0;
  }
}

/* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-products .c-products_header {
  max-width: 1200px;
  margin: auto;
}

/* line 13, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-products .c-products_header h2 {
  margin-bottom: 80px;
  color: #0f46a0;
  text-transform: uppercase;
  font-size: 7em;
  font-family: "Helvetica-neue-condensed-black";
}

@media (max-width: 768px) {
  /* line 13, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
  .c-products .c-products_header h2 {
    margin-bottom: 40px;
    font-size: 4em;
  }
}

/* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-products-listing {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  max-width: 1200px;
  margin: auto;
  padding-bottom: 120px;
  border-bottom: 1px solid #d0dce8;
}

@media (max-width: 768px) {
  /* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
  .c-products-listing {
    padding-bottom: 60px;
  }
}

/* line 42, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-products-listing_single {
  display: flex;
  flex-direction: column;
  width: 30%;
}

@media (max-width: 1000px) {
  /* line 42, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
  .c-products-listing_single {
    width: 48%;
  }
}

@media (max-width: 768px) {
  /* line 42, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
  .c-products-listing_single {
    width: 100%;
  }
}

/* line 56, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-products-listing_single:hover .c-products_footer {
  background-color: #135acf;
}

/* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-product-listings_carousel .slick-track {
  display: flex !important;
}

/* line 66, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-product-listings_carousel .slick-slide {
  height: inherit !important;
}

/* line 70, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-product-listings_carousel .slick-list {
  overflow: inherit;
}

/* line 74, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-product-listings_carousel img {
  opacity: 1 !important;
}

/* line 79, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-products-listing_arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: #fff;
  color: #212121;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

@media (max-width: 700px) {
  /* line 79, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
  .c-products-listing_arrow {
    width: 60px;
    height: 60px;
  }
}

/* line 95, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-products-listing_arrow:hover {
  background-color: #135acf;
}

/* line 99, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-products-listing_arrow.-prev {
  border-radius: 8px 0 0 8px;
}

/* line 103, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-products-listing_arrow.-next {
  border-radius: 0 8px 8px 0;
}

/* line 108, public/wp-content/themes/performair/assets/src/scss/component/_c-products-listing.scss */
.c-product-listings_slide {
  margin-right: 24px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-products.scss */
.c-products_body {
  flex: 1;
  padding: 40px;
  border-radius: 8px 8px 0 0;
  background-color: #fff;
}

/* line 10, public/wp-content/themes/performair/assets/src/scss/component/_c-products.scss */
.c-products_footer {
  padding: 20px;
  border-radius: 0 0 8px 8px;
  background-color: #0f46a0;
  transition: all 150ms ease-in-out;
  text-align: center;
}

/* line 17, public/wp-content/themes/performair/assets/src/scss/component/_c-products.scss */
.c-products_footer p {
  margin-bottom: 0;
  color: #fff;
}

/* line 23, public/wp-content/themes/performair/assets/src/scss/component/_c-products.scss */
.c-products_img {
  height: 300px;
}

/* line 26, public/wp-content/themes/performair/assets/src/scss/component/_c-products.scss */
.c-products_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* line 33, public/wp-content/themes/performair/assets/src/scss/component/_c-products.scss */
.c-products_content {
  text-align: center;
}

/* line 36, public/wp-content/themes/performair/assets/src/scss/component/_c-products.scss */
.c-products_content h5 {
  margin: 20px 0;
  color: #212121;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 32px;
}

/* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-products.scss */
.c-products_marque {
  text-align: center;
}

/* line 48, public/wp-content/themes/performair/assets/src/scss/component/_c-products.scss */
.c-products_tag {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

/* line 54, public/wp-content/themes/performair/assets/src/scss/component/_c-products.scss */
.c-products_tag p {
  padding: 6px 10px;
  border-radius: 4px;
  background-color: #eef4ff;
  color: #0f46a0;
}

/* line 61, public/wp-content/themes/performair/assets/src/scss/component/_c-products.scss */
.c-products_tag .c-tag_promo {
  background-color: #eb0a0f;
  color: #fff;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing {
  position: relative;
  padding: 120px 80px;
  overflow: hidden;
  background-color: #212121;
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
  .c-promo-listing {
    padding: 60px 40px;
  }
}

/* line 12, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_header {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 80px;
}

@media (max-width: 500px) {
  /* line 12, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
  .c-promo-listing_header {
    margin-bottom: 160px;
  }
}

/* line 21, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_header h2 {
  max-width: 100%;
  color: #fff;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-black";
  font-size: 7em;
}

@media (max-width: 768px) {
  /* line 21, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
  .c-promo-listing_header h2 {
    font-size: 4em;
  }
}

/* line 33, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_header p {
  width: 50%;
  font-size: 24px;
}

@media (max-width: 500px) {
  /* line 33, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
  .c-promo-listing_header p {
    width: 100%;
  }
}

/* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_slider {
  width: 1px;
  width: 100%;
  min-width: 100%;
}

/* line 50, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_slider .slick-track {
  display: flex !important;
}

/* line 54, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_slider .slick-slide {
  height: inherit !important;
}

/* line 58, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_slider .slick-list {
  overflow: inherit;
}

/* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_slider img {
  opacity: 1 !important;
}

/* line 66, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_slider .slick-arrow {
  position: absolute;
  top: -195px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: #fff;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

@media (max-width: 768px) {
  /* line 66, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
  .c-promo-listing_slider .slick-arrow {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 500px) {
  /* line 66, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
  .c-promo-listing_slider .slick-arrow {
    top: -125px;
    border-radius: 8px !important;
  }
}

/* line 89, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_slider .slick-arrow:hover {
  background-color: #eb0a0f;
}

/* line 93, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_slider .slick-arrow::after {
  content: url("../images/svg/arrow_forward_black.svg");
}

/* line 98, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_slider .prevArrow {
  right: 90px;
  border-right: 1px solid #d0dce8;
  border-radius: 8px 0 0 8px;
}

@media (max-width: 500px) {
  /* line 98, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
  .c-promo-listing_slider .prevArrow {
    right: unset;
    left: 10px;
    border: none;
  }
}

/* line 110, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_slider .nextArrow {
  border-radius: 0 8px 8px 0;
}

/* line 113, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_slider .nextArrow::after {
  margin-bottom: 4px;
  transform: scale(-1);
}

/* line 120, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_slide {
  margin-right: 24px;
}

/* line 124, public/wp-content/themes/performair/assets/src/scss/component/_c-promo-listing.scss */
.c-promo-listing_single {
  display: flex !important;
  flex-direction: column;
  height: 700px;
  margin-right: 12px;
  margin-left: 12px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
.c-distinctions {
  padding: 120px 80px;
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
  .c-distinctions {
    padding: 60px 40px;
  }
}

/* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
.c-distinctions_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  /* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
  .c-distinctions_header {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
  }
}

/* line 20, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
.c-distinctions_header h1 {
  color: #0f46a0;
}

/* line 24, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
.c-distinctions_header p {
  width: 40%;
}

@media (max-width: 768px) {
  /* line 24, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
  .c-distinctions_header p {
    width: 100%;
  }
}

/* line 33, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
.c-distinctions_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

/* line 39, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
.c-distinctions_single {
  flex-grow: 1;
  width: 23%;
}

@media (max-width: 768px) {
  /* line 39, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
  .c-distinctions_single {
    width: 100%;
  }
}

/* line 48, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
.c-distinctions_media {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  padding: 40px;
  border: 1px solid #d0dce8;
  border-radius: 8px;
}

@media (max-width: 768px) {
  /* line 48, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
  .c-distinctions_media {
    margin-bottom: 20px;
  }
}

/* line 63, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
.c-distinctions_content h6 {
  margin: 0 0 12px;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 24px;
  font-weight: 400;
}

/* line 70, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
.c-distinctions_content p {
  color: #3f474e;
}

/* line 74, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
.c-distinctions_content a {
  display: flex;
  align-items: center;
  width: fit-content;
  color: #3f474e;
  transition: all 150ms ease-in-out;
  text-transform: uppercase;
  font-size: 14px;
}

/* line 83, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
.c-distinctions_content a::before {
  content: url("../images/svg/arrow_forward_orange.svg");
  margin-right: 10px;
}

/* line 88, public/wp-content/themes/performair/assets/src/scss/component/_c-distinction.scss */
.c-distinctions_content a:hover {
  transform: translateX(10px);
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
.c-caroussel {
  background-color: #eef4ff;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
.c-caroussel_header {
  position: relative;
  margin-bottom: 92px;
}

/* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
.c-caroussel_slider .slick-arrow {
  position: absolute;
  top: -165px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: #fff;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

@media (max-width: 768px) {
  /* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
  .c-caroussel_slider .slick-arrow {
    top: -145px;
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 360px) {
  /* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
  .c-caroussel_slider .slick-arrow {
    top: -80px;
    border-radius: 8px !important;
  }
}

/* line 35, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
.c-caroussel_slider .slick-arrow:hover {
  background-color: #0f46a0;
}

/* line 39, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
.c-caroussel_slider .slick-arrow::after {
  content: url("../images/svg/arrow_forward_black.svg");
}

/* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
.c-caroussel_slider .prevArrow {
  right: 90px;
  border-right: 1px solid #d0dce8;
  border-radius: 8px 0 0 8px;
}

@media (max-width: 768px) {
  /* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
  .c-caroussel_slider .prevArrow {
    right: 70px;
  }
}

@media (max-width: 360px) {
  /* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
  .c-caroussel_slider .prevArrow {
    right: 240px;
    border-right: none;
  }
}

/* line 59, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
.c-caroussel_slider .nextArrow {
  border-radius: 0 8px 8px 0;
}

/* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
.c-caroussel_slider .nextArrow::after {
  margin-bottom: 4px;
  transform: scale(-1);
}

/* line 69, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
.c-caroussel_card {
  margin-right: 24px;
  border-radius: 8px;
  background-color: #fff;
}

/* line 74, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
.c-caroussel_card img {
  width: 100%;
  max-height: 400px;
  border-radius: 8px;
  object-fit: cover;
}

/* line 82, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
.c-caroussel_content {
  padding: 40px;
}

/* line 85, public/wp-content/themes/performair/assets/src/scss/component/_c-caroussel.scss */
.c-caroussel_content h5 {
  margin: 0;
  margin-bottom: 20px;
  color: #212121;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 32px;
  font-weight: 400;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
.c-banner {
  position: relative;
  background-color: #0f46a0;
  overflow: hidden;
}

/* line 6, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
.c-banner .c-banner_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

@media (max-width: 768px) {
  /* line 6, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
  .c-banner .c-banner_content {
    padding: 0 40px;
  }
}

/* line 17, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
.c-banner .c-banner_avatars {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* line 25, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
.c-banner .c-banner_avatar {
  position: absolute;
}

/* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
.c-banner .c-banner_avatar img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  object-fit: cover;
}

/* line 35, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
.c-banner .c-banner_avatar:nth-child(1) {
  top: 80px;
  left: 280px;
  width: 80px;
  height: 80px;
}

@media (max-width: 1000px) {
  /* line 35, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
  .c-banner .c-banner_avatar:nth-child(1) {
    top: 40px;
    left: 160px;
  }
}

@media (max-width: 768px) {
  /* line 35, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
  .c-banner .c-banner_avatar:nth-child(1) {
    display: none;
  }
}

/* line 51, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
.c-banner .c-banner_avatar:nth-child(2) {
  top: 225px;
  left: -20px;
  width: 120px;
  height: 120px;
}

@media (max-width: 500px) {
  /* line 51, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
  .c-banner .c-banner_avatar:nth-child(2) {
    display: none;
  }
}

/* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
.c-banner .c-banner_avatar:nth-child(3) {
  bottom: 80px;
  left: 120px;
  width: 160px;
  height: 160px;
}

@media (max-width: 1000px) {
  /* line 62, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
  .c-banner .c-banner_avatar:nth-child(3) {
    display: none;
  }
}

/* line 73, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
.c-banner .c-banner_avatar:nth-child(4) {
  top: 80px;
  right: 120px;
  width: 160px;
  height: 160px;
}

@media (max-width: 1000px) {
  /* line 73, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
  .c-banner .c-banner_avatar:nth-child(4) {
    display: none;
  }
}

/* line 84, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
.c-banner .c-banner_avatar:nth-child(5) {
  right: -30px;
  bottom: 240px;
  width: 120px;
  height: 120px;
}

@media (max-width: 500px) {
  /* line 84, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
  .c-banner .c-banner_avatar:nth-child(5) {
    display: none;
  }
}

/* line 95, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
.c-banner .c-banner_avatar:nth-child(6) {
  right: 275px;
  bottom: 80px;
  width: 80px;
  height: 80px;
}

@media (max-width: 1000px) {
  /* line 95, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
  .c-banner .c-banner_avatar:nth-child(6) {
    right: 160px;
    bottom: 40px;
  }
}

@media (max-width: 768px) {
  /* line 95, public/wp-content/themes/performair/assets/src/scss/component/_c-banner.scss */
  .c-banner .c-banner_avatar:nth-child(6) {
    display: none;
  }
}

/* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_events {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

@media (max-width: 768px) {
  /* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_events {
    gap: 40px;
  }
}

/* line 12, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translate(40px);
  opacity: 0;
  transition: all 1s ease-in;
}

@media (max-width: 768px) {
  /* line 12, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_event {
    flex-direction: column;
    gap: 20px;
  }
}

/* line 26, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event h3 {
  width: 30%;
  text-align: center;
  font-size: 7em;
}

@media (max-width: 768px) {
  /* line 26, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_event h3 {
    width: 100%;
    margin-bottom: 0;
    font-size: 3rem;
    text-align: start;
  }
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event h3::before {
  content: "";
  position: absolute;
  top: 35px;
  right: 0;
  left: 0;
  width: 95px;
  height: 1px;
  margin: auto;
  transform: rotateZ(270deg);
  border-top: 1px dashed #d0dce8;
}

@media (max-width: 768px) {
  /* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_event h3::before {
    display: none;
  }
}

/* line 56, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event .c-timeline_dot-border {
  position: absolute;
  top: 74px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 32px;
  transition: all 350ms ease-in-out;
}

@media (max-width: 768px) {
  /* line 56, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_event .c-timeline_dot-border {
    display: none;
  }
}

/* line 72, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event .c-timeline_dot {
  display: flex;
  border: none;
  transition: all 350ms ease-in-out;
}

/* line 77, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event .c-timeline_dot::after {
  content: "";
  width: 10px;
  height: 10px;
  transform-origin: center;
  border-radius: 32px;
  background-color: #eb0a0f;
  transition: all 350ms ease-in-out;
}

/* line 88, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event .c-timeline_img {
  width: 30%;
}

@media (max-width: 768px) {
  /* line 88, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_event .c-timeline_img {
    width: 100%;
  }
}

/* line 95, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event .c-timeline_img img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

/* line 102, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event .c-timeline_desc {
  width: 30%;
}

@media (max-width: 768px) {
  /* line 102, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_event .c-timeline_desc {
    width: 100%;
  }
}

/* line 109, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event .c-timeline_desc h6 {
  margin: 0;
  margin-bottom: 12px;
  color: #212121;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 24px;
  font-weight: 400;
}

/* line 119, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event:nth-child(even) {
  flex-direction: row-reverse;
}

@media (max-width: 768px) {
  /* line 119, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_event:nth-child(even) {
    flex-direction: column;
  }
}

/* line 128, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event.is-active .c-timeline_dot-border {
  top: 70px;
}

@media (max-width: 768px) {
  /* line 128, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_event.is-active .c-timeline_dot-border {
    display: none;
  }
}

/* line 136, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event.is-active .c-timeline_dot {
  border: 1px solid #89949f;
  border-radius: 100%;
}

/* line 140, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event.is-active .c-timeline_dot::after {
  content: "";
  width: 20px;
  height: 20px;
  margin: 10px;
  background-color: #eb0a0f;
}

/* line 150, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_event.is-inview {
  transform: translateY(0);
  opacity: 1;
}

/* line 156, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_today {
  padding-top: 120px;
}

@media (max-width: 768px) {
  /* line 156, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_today {
    padding-top: 60px;
  }
}

/* line 163, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_today h3 {
  margin: 0 auto 100px;
  color: #d0dce8;
  text-align: center;
  text-transform: uppercase;
  font-size: 7em;
}

@media (max-width: 768px) {
  /* line 163, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_today h3 {
    font-size: 4em;
  }
}

/* line 176, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_today-wrapper {
  display: flex;
  justify-content: center;
  gap: 24px;
}

@media (max-width: 1000px) {
  /* line 176, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_today-wrapper {
    flex-wrap: wrap;
    gap: 80px 24px;
  }
}

/* line 186, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_today-wrapper .c-timeline_today-card {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 33.333%;
  padding: 40px;
  padding-top: 80px;
  border: 1px solid #d0dce8;
}

@media (max-width: 1000px) {
  /* line 186, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_today-wrapper .c-timeline_today-card {
    width: 48%;
  }
}

@media (max-width: 768px) {
  /* line 186, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
  .c-timeline .c-timeline_today-wrapper .c-timeline_today-card {
    width: 100%;
    padding: 20px;
    padding-top: 80px;
  }
}

/* line 206, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_today-wrapper .c-timeline_today-card img {
  position: absolute;
  top: -50px;
  right: 0;
  left: 0;
  margin: auto;
}

/* line 214, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_today-wrapper .c-timeline_today-card h6 {
  margin: 0 0 40px;
  color: #212121;
  text-align: center;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 24px;
  font-weight: 400;
}

/* line 223, public/wp-content/themes/performair/assets/src/scss/component/_c-timeline.scss */
.c-timeline .c-timeline_today-wrapper .c-timeline_today-card p {
  color: #3f474e;
  text-align: center;
}

/* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_content {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  /* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
  .c-media-text .c-media-text_content {
    flex-direction: column;
    gap: 20px;
  }
}

/* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_content.-center {
  align-items: center;
}

/* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_content.-left {
  flex-direction: row-reverse;
}

@media (max-width: 768px) {
  /* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
  .c-media-text .c-media-text_content.-left {
    flex-direction: column;
  }
}

/* line 23, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_content.-gallery {
  gap: 132px;
}

@media (max-width: 768px) {
  /* line 23, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
  .c-media-text .c-media-text_content.-gallery {
    gap: 48px;
  }
}

/* line 32, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_desc {
  width: 40%;
}

@media (max-width: 768px) {
  /* line 32, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
  .c-media-text .c-media-text_desc {
    width: 100%;
  }
}

/* line 39, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_desc.-compact {
  width: 30%;
}

@media (max-width: 768px) {
  /* line 39, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
  .c-media-text .c-media-text_desc.-compact {
    width: 100%;
  }
}

/* line 47, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_desc h4 {
  color: #0f46a0;
  text-transform: uppercase;
}

/* line 53, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_img {
  width: 40%;
}

@media (max-width: 768px) {
  /* line 53, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
  .c-media-text .c-media-text_img {
    width: 100%;
  }
}

/* line 60, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_img img {
  width: 100%;
  border-radius: 8px;
}

/* line 65, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_img.-big {
  width: 40%;
  max-height: 600px;
}

@media (max-width: 768px) {
  /* line 65, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
  .c-media-text .c-media-text_img.-big {
    display: none;
  }
}

/* line 73, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_img.-big img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* line 81, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_second-img {
  width: 30%;
}

@media (max-width: 768px) {
  /* line 81, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
  .c-media-text .c-media-text_second-img {
    width: 100%;
  }
}

/* line 88, public/wp-content/themes/performair/assets/src/scss/component/_c-media-text.scss */
.c-media-text .c-media-text_second-img img {
  width: 100%;
  height: 70%;
  border-radius: 8px;
  object-fit: cover;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members {
  background-color: #eef4ff;
}

/* line 4, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  text-align: center;
}

/* line 12, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_header img {
  width: 96px;
  height: 96px;
  margin-bottom: 80px;
}

/* line 19, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 72px 24px;
  padding-bottom: 120px;
  border-bottom: 2px dashed #d0dce8;
}

/* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_single {
  width: 30%;
  border-radius: 8px;
  background-color: #fff;
}

@media (max-width: 1000px) {
  /* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
  .c-team-members .c-team-members_single {
    width: 48%;
  }
}

@media (max-width: 768px) {
  /* line 28, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
  .c-team-members .c-team-members_single {
    width: 100%;
  }
}

/* line 41, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_single img {
  width: 100%;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
}

/* line 48, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_body {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}

/* line 55, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_body h4 {
  text-transform: uppercase;
}

/* line 59, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_body h6 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 18px;
  font-weight: 400;
}

/* line 68, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_body a {
  margin-bottom: 20px;
  color: #212121;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 18px;
  font-weight: 400;
}

/* line 75, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_body a:hover {
  color: #bababa;
}

/* line 81, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_seemore {
  position: absolute;
  bottom: -35px;
  cursor: pointer;
}

/* line 86, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_seemore::after {
  content: url("../images/svg/viewmore.svg");
}

/* line 91, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_single-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  padding: 150px;
  background-color: #2121213e;
  z-index: 9999;
}

@media (max-width: 768px) {
  /* line 91, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
  .c-team-members .c-team-members_single-modal {
    padding: 85px 20px;
  }
}

/* line 108, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper {
  height: auto;
  padding: 40px;
  border-radius: 8px;
  background-color: #fff;
  overflow: auto;
}

@media (max-width: 768px) {
  /* line 108, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
  .c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper {
    padding: 20px;
  }
}

/* line 119, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper .c-team-members_body {
  position: relative;
  flex-direction: row;
  gap: 40px;
  padding: 0;
  padding-bottom: 40px;
}

@media (max-width: 768px) {
  /* line 119, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
  .c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper .c-team-members_body {
    flex-direction: column;
    padding-bottom: 20px;
  }
}

/* line 131, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper .c-team-members_body img {
  width: 200px;
  height: 170px;
  border-radius: 8px;
  object-fit: cover;
}

@media (max-width: 768px) {
  /* line 131, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
  .c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper .c-team-members_body img {
    width: unset;
    height: unset;
  }
}

/* line 144, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper .c-team-members_desc {
  padding-top: 40px;
  border-top: 1px solid #d0dce8;
}

@media (max-width: 768px) {
  /* line 144, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
  .c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper .c-team-members_desc {
    padding-top: 20px;
  }
}

/* line 153, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper .c-team-members_content {
  width: 70%;
}

@media (max-width: 768px) {
  /* line 153, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
  .c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper .c-team-members_content {
    width: 100%;
  }
}

/* line 161, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper .c-team-members_close {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 100%;
  cursor: pointer;
}

/* line 168, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper .c-team-members_close::after {
  content: url("../images/svg/close.svg");
}

/* line 173, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_single-modal .c-team-members_modal-wrapper .c-tag {
  margin-top: 20px;
}

/* line 178, public/wp-content/themes/performair/assets/src/scss/component/_c-team-members.scss */
.c-team-members .c-team-members_single-modal.is-active {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_slider {
  position: relative;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_slider .slick-arrow {
  position: absolute;
  right: 40px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #d0dce8;
  border-radius: 50px;
  background-color: #fff;
  cursor: pointer;
  z-index: 5;
}

@media (max-width: 768px) {
  /* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
  .c-testimonies .c-testimonies_slider .slick-arrow {
    right: 0;
    bottom: 165px;
  }
}

@media (max-width: 660px) {
  /* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
  .c-testimonies .c-testimonies_slider .slick-arrow {
    bottom: 190px;
  }
}

@media (max-width: 470px) {
  /* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
  .c-testimonies .c-testimonies_slider .slick-arrow {
    bottom: 210px;
  }
}

@media (max-width: 360px) {
  /* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
  .c-testimonies .c-testimonies_slider .slick-arrow {
    bottom: 235px;
  }
}

/* line 36, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_slider .slick-arrow::after {
  content: url("../images/svg/slider-arrow.svg");
}

/* line 41, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_slider .prevArrow {
  right: 110px;
}

@media (max-width: 768px) {
  /* line 41, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
  .c-testimonies .c-testimonies_slider .prevArrow {
    display: none !important;
  }
}

/* line 49, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_slider .nextArrow {
  transform: scaleX(-1);
}

@media (max-width: 768px) {
  /* line 49, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
  .c-testimonies .c-testimonies_slider .nextArrow {
    display: none !important;
  }
}

/* line 57, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_slider .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0px;
  left: 0;
  display: flex !important;
  justify-content: center;
  gap: 8px;
  margin: auto;
  padding-left: 110px;
  cursor: pointer;
}

@media (max-width: 768px) {
  /* line 57, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
  .c-testimonies .c-testimonies_slider .slick-dots {
    position: static;
    margin: 0;
    padding: 0;
    margin-top: 16px;
  }
}

/* line 76, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_slider .slick-dots li {
  list-style-type: none;
}

/* line 79, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_slider .slick-dots li .slider-dot-wrapper {
  padding: 6px;
}

/* line 83, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_slider .slick-dots li .slider-dot {
  width: 12px;
  height: 12px;
  border: none;
  border-radius: 50px;
  background-color: #d0dce8;
  list-style-type: none;
}

/* line 94, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_slider .slick-dots .slick-active .slider-dot-wrapper {
  border: 1px solid #d0dce8;
  border-radius: 50px;
}

/* line 98, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_slider .slick-dots .slick-active .slider-dot {
  background-color: #eb0a0f;
}

@media (max-width: 768px) {
  /* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
  .c-testimonies .c-testimonies_slider {
    display: flex !important;
    flex-direction: column;
  }
}

/* line 110, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_single {
  display: flex;
  gap: 64px;
}

@media (max-width: 991px) {
  /* line 110, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
  .c-testimonies .c-testimonies_single {
    flex-direction: column;
    gap: 32px;
  }
}

/* line 119, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_media {
  width: 50%;
}

@media (max-width: 991px) {
  /* line 119, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
  .c-testimonies .c-testimonies_media {
    width: 100%;
  }
}

/* line 126, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_media img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

/* line 133, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 50%;
}

@media (max-width: 768px) {
  /* line 133, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
  .c-testimonies .c-testimonies_content {
    gap: 24px;
    width: 100%;
  }
}

/* line 145, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_content h3 {
  text-transform: uppercase;
}

/* line 149, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_content p {
  margin-bottom: 0;
}

/* line 152, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_content p span {
  color: #eb0a0f;
}

/* line 158, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_footer {
  padding-bottom: 48px;
  border-bottom: 1px solid #d0dce8;
}

@media (max-width: 768px) {
  /* line 158, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
  .c-testimonies .c-testimonies_footer {
    padding-bottom: 24px;
  }
}

/* line 166, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_footer p {
  margin-bottom: 0;
}

/* line 171, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_name {
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
}

/* line 179, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies .c-testimonies_poste {
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}

/* line 188, public/wp-content/themes/performair/assets/src/scss/component/_c-testimonies.scss */
.c-testimonies.no-dots .slick-dots {
  display: none;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-marquee.scss */
.c-marquee {
  overflow: hidden;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-marquee.scss */
.c-marquee_wrapper {
  display: inline-flex;
  will-change: transform;
}

/* line 10, public/wp-content/themes/performair/assets/src/scss/component/_c-marquee.scss */
.c-marquee_content {
  display: flex;
  align-items: center;
  gap: 40px;
}

/* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-marquee.scss */
.c-marquee_content h1 {
  display: flex;
  align-items: center;
}

/* line 19, public/wp-content/themes/performair/assets/src/scss/component/_c-marquee.scss */
.c-marquee_content h1::after {
  content: url("../images/svg/dot.svg");
  margin-left: 40px;
}

/* line 24, public/wp-content/themes/performair/assets/src/scss/component/_c-marquee.scss */
.c-marquee_content h1:last-child {
  margin-right: 40px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-text-video.scss */
.c-text-video {
  background-color: #eef4ff;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-text-video.scss */
.c-text-video_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

/* line 11, public/wp-content/themes/performair/assets/src/scss/component/_c-text-video.scss */
.c-text-video_content h2 {
  text-transform: uppercase;
}

/* line 16, public/wp-content/themes/performair/assets/src/scss/component/_c-text-video.scss */
.c-text-video_desc {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* line 21, public/wp-content/themes/performair/assets/src/scss/component/_c-text-video.scss */
.c-text-video_desc h6 {
  margin: 0;
  color: #3f474e;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
}

/* line 32, public/wp-content/themes/performair/assets/src/scss/component/_c-text-video.scss */
.c-text-video_media {
  position: relative;
}

/* line 36, public/wp-content/themes/performair/assets/src/scss/component/_c-text-video.scss */
.c-text-video_video {
  display: none;
}

/* line 40, public/wp-content/themes/performair/assets/src/scss/component/_c-text-video.scss */
.c-text-video_play {
  position: absolute;
  top: 46%;
  right: 0;
  left: 0;
  margin: auto;
  cursor: pointer;
}

/* line 48, public/wp-content/themes/performair/assets/src/scss/component/_c-text-video.scss */
.c-text-video_play::after {
  content: url("../images/svg/play.svg");
}

/* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form-section .c-form-section_header {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 80px;
  margin-bottom: 80px;
}

/* line 9, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form-section .c-form-section_header .c-form-section_desc {
  text-align: center;
}

/* line 14, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form-section .c-form_input {
  margin-right: 0;
  margin-bottom: 40px;
}

@media (max-width: 700px) {
  /* line 14, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
  .c-form-section .c-form_input {
    width: 100%;
  }
}

/* line 24, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form-section .c-form_label p {
  margin: 0;
  color: #212121;
  font-size: 18px;
  font-weight: 400;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 31, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form-section .c-form_label p span {
  color: #eb0a0f;
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form-section .c-form_text p {
  margin-bottom: 0;
}

/* line 42, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form-section .c-form_text input,
.c-form-section .c-form_text textarea {
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  border: 1px solid #d0dce8;
  border-radius: 8px;
}

/* line 50, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form-section .c-form_text input::placeholder,
.c-form-section .c-form_text textarea::placeholder {
  color: #89949f;
  font-size: 18px;
  font-weight: 400;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 59, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form-section .c-form_footer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* line 65, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form-section #c-form__label {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-right: 0;
  padding: 20px;
  border: 1px dashed #d0dce8;
  border-radius: 8px;
}

/* line 76, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form-section_icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: #24388a;
}

/* line 86, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form-section_icon img {
  position: absolute;
  max-width: 48px;
  object-fit: contain;
}

/* line 94, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form_checkbox .wpcf7-list-item {
  margin: 0;
}

/* line 100, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form_checkbox-list .wpcf7-checkbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* line 106, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
.c-form_checkbox-list .wpcf7-list-item {
  width: 50%;
  margin: 0;
}

@media (max-width: 500px) {
  /* line 106, public/wp-content/themes/performair/assets/src/scss/component/_c-form-section.scss */
  .c-form_checkbox-list .wpcf7-list-item {
    width: 100%;
  }
}

/* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-simple-header.scss */
.c-simple-header .c-simple-header_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header {
  background-color: #0f46a0;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header_content {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1000px) {
  /* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
  .c-complex-header_content {
    flex-direction: column;
    gap: 24px;
  }
}

/* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header_text {
  width: 40%;
}

@media (max-width: 1000px) {
  /* line 15, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
  .c-complex-header_text {
    width: 100%;
  }
}

/* line 22, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header_text.-center {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

/* line 30, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header_media {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 40%;
  transform: translateX(115px);
}

@media (max-width: 1000px) {
  /* line 30, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
  .c-complex-header_media {
    justify-content: center;
    width: 100%;
    transform: unset;
  }
}

/* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header_media img {
  width: 600px;
  height: 600px;
  border-radius: 100%;
}

@media (max-width: 1000px) {
  /* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
  .c-complex-header_media img {
    width: 400px;
    height: 400px;
  }
}

/* line 56, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header_play {
  position: absolute;
  top: 275px;
  right: 275px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  /* line 56, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
  .c-complex-header_play {
    top: 175px;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    margin: auto;
  }
}

/* line 71, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header_play::after {
  content: url("../images/svg/play.svg");
}

/* line 76, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header_cards {
  display: flex;
  gap: 24px;
  margin-top: 160px;
}

@media (max-width: 1000px) {
  /* line 76, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
  .c-complex-header_cards {
    flex-wrap: wrap;
    gap: 72px 24px;
    margin-top: 100px;
  }
}

/* line 88, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header_card {
  position: relative;
  width: 33.333%;
  padding: 80px 40px 40px;
  border-radius: 8px;
  background-color: #fff;
}

@media (max-width: 1250px) {
  /* line 88, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
  .c-complex-header_card {
    padding: 80px 20px 40px;
  }
}

@media (max-width: 1000px) {
  /* line 88, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
  .c-complex-header_card {
    width: 48%;
  }
}

@media (max-width: 768px) {
  /* line 88, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
  .c-complex-header_card {
    width: 100%;
  }
}

/* line 107, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header_card h3 {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 900;
}

@media (max-width: 1250px) {
  /* line 107, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
  .c-complex-header_card h3 {
    font-size: 38px;
  }
}

/* line 119, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header_count {
  position: absolute;
  top: -48px;
  left: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  border-radius: 48px;
  background-color: #eb0a0f;
}

@media (max-width: 1250px) {
  /* line 119, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
  .c-complex-header_count {
    left: 20px;
  }
}

/* line 135, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.c-complex-header_count h4 {
  color: #fff;
}

/* line 140, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.subtitle {
  margin-bottom: 20px;
  color: #0f46a0;
  font-size: 18px;
  font-weight: 400;
}

/* line 147, public/wp-content/themes/performair/assets/src/scss/component/_c-complex-header.scss */
.desc {
  color: #3f474e;
  font-size: 18px;
  font-weight: 400;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header {
  position: relative;
  background-color: #0f46a0;
}

/* line 6, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  text-align: center;
}

/* line 13, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header_content h4 {
  color: #d0dce8;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 32px;
  font-weight: 700;
}

/* line 21, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header_content p {
  width: 60%;
  color: #d0dce8;
}

@media (max-width: 768px) {
  /* line 21, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
  .c-bubbles-header_content p {
    width: 100%;
  }
}

/* line 31, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header_avatars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* line 40, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header_avatar {
  position: absolute;
}

/* line 43, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header_avatar img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  object-fit: cover;
}

/* line 50, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header_avatar:nth-child(1) {
  top: 80px;
  left: 280px;
  width: 80px;
  height: 80px;
}

@media (max-width: 1000px) {
  /* line 50, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
  .c-bubbles-header_avatar:nth-child(1) {
    top: 40px;
    left: 160px;
  }
}

@media (max-width: 768px) {
  /* line 50, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
  .c-bubbles-header_avatar:nth-child(1) {
    display: none;
  }
}

/* line 66, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header_avatar:nth-child(2) {
  top: 225px;
  left: -20px;
  width: 120px;
  height: 120px;
}

@media (max-width: 500px) {
  /* line 66, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
  .c-bubbles-header_avatar:nth-child(2) {
    display: none;
  }
}

/* line 77, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header_avatar:nth-child(3) {
  bottom: 80px;
  left: 120px;
  width: 160px;
  height: 160px;
}

@media (max-width: 1000px) {
  /* line 77, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
  .c-bubbles-header_avatar:nth-child(3) {
    display: none;
  }
}

/* line 88, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header_avatar:nth-child(4) {
  top: 80px;
  right: 120px;
  width: 160px;
  height: 160px;
}

@media (max-width: 1000px) {
  /* line 88, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
  .c-bubbles-header_avatar:nth-child(4) {
    display: none;
  }
}

/* line 99, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header_avatar:nth-child(5) {
  right: -30px;
  bottom: 240px;
  width: 120px;
  height: 120px;
}

@media (max-width: 500px) {
  /* line 99, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
  .c-bubbles-header_avatar:nth-child(5) {
    display: none;
  }
}

/* line 110, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
.c-bubbles-header_avatar:nth-child(6) {
  right: 275px;
  bottom: 80px;
  width: 80px;
  height: 80px;
}

@media (max-width: 1000px) {
  /* line 110, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
  .c-bubbles-header_avatar:nth-child(6) {
    right: 160px;
    bottom: 40px;
  }
}

@media (max-width: 768px) {
  /* line 110, public/wp-content/themes/performair/assets/src/scss/component/_c-bubbles-header.scss */
  .c-bubbles-header_avatar:nth-child(6) {
    display: none;
  }
}

/* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-logo-header.scss */
.c-logo-header .c-logo-header_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

/* line 8, public/wp-content/themes/performair/assets/src/scss/component/_c-logo-header.scss */
.c-logo-header .c-logo-header_content img {
  width: 130px;
  height: 65px;
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  /* line 8, public/wp-content/themes/performair/assets/src/scss/component/_c-logo-header.scss */
  .c-logo-header .c-logo-header_content img {
    margin-bottom: 40px;
  }
}

/* line 18, public/wp-content/themes/performair/assets/src/scss/component/_c-logo-header.scss */
.c-logo-header .c-logo-header_content h4 {
  text-transform: uppercase;
}

/* line 22, public/wp-content/themes/performair/assets/src/scss/component/_c-logo-header.scss */
.c-logo-header .c-logo-header_content p {
  margin-top: 80px;
}

@media (max-width: 768px) {
  /* line 22, public/wp-content/themes/performair/assets/src/scss/component/_c-logo-header.scss */
  .c-logo-header .c-logo-header_content p {
    margin-top: 40px;
  }
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-tag.scss */
.c-tag {
  width: fit-content;
  padding: 6px;
  border-radius: 8px;
  background-color: #eef4ff;
  color: #0f46a0;
  font-size: 14px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_lightbox.scss */
.c-lightbox {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  overflow: auto;
  pointer-events: none;
  z-index: 50;
}

/* line 16, public/wp-content/themes/performair/assets/src/scss/component/_lightbox.scss */
.c-lightbox.is-active {
  width: 100%;
  height: 100%;
  opacity: 1;
  pointer-events: all;
}

/* line 24, public/wp-content/themes/performair/assets/src/scss/component/_lightbox.scss */
.c-lightbox_clear {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 16px;
  cursor: pointer;
}

/* line 31, public/wp-content/themes/performair/assets/src/scss/component/_lightbox.scss */
.c-lightbox_clear::after {
  content: url("../images/svg/close.svg");
}

/* line 35, public/wp-content/themes/performair/assets/src/scss/component/_lightbox.scss */
.c-lightbox_clear:hover, .c-lightbox_clear:focus {
  transform: rotate(90deg);
}

/* line 41, public/wp-content/themes/performair/assets/src/scss/component/_lightbox.scss */
.c-lightbox_inner {
  display: flex;
  align-items: center;
  min-height: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
}

/* line 48, public/wp-content/themes/performair/assets/src/scss/component/_lightbox.scss */
.c-lightbox_inner.-stretch {
  align-items: stretch;
}

/* line 53, public/wp-content/themes/performair/assets/src/scss/component/_lightbox.scss */
.c-lightbox_video {
  width: 100%;
  height: auto;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon {
  display: inline-flex;
  align-items: center;
}

/* line 6, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-date::before {
  content: url("../images/svg/calendar.svg");
  width: 16px;
  margin-top: 5px;
  margin-right: 14px;
}

/* line 15, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-type::before {
  content: url("../images/svg/schedule.svg");
  width: 16px;
  margin-top: 5px;
  margin-right: 14px;
}

/* line 24, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-city::before {
  content: url("../images/svg/location.svg");
  width: 16px;
  margin-top: 5px;
  margin-right: 14px;
}

/* line 33, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-grey1:before {
  background-color: #89949f;
}

/* line 39, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-grey3:before {
  background-color: #212121;
}

/* line 45, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-download::before {
  content: "";
  width: 24px;
  height: 24px;
  mask-image: url("../images/svg/download.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
}

/* line 57, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-document::before {
  content: url("../images/svg/document.svg");
  width: 24px;
  height: 24px;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
}

/* line 68, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-money::before {
  content: url("../images/svg/money.svg");
  width: 24px;
  height: 24px;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
}

/* line 79, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-sell::before {
  content: url("../images/svg/sell.svg");
  width: 24px;
  height: 24px;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
}

/* line 89, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-arrow-left {
  width: 24px;
  height: 24px;
}

/* line 92, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-arrow-left::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  mask-image: url("../images/svg/arrow-left.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
}

/* line 104, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-arrow-right {
  width: 24px;
  height: 24px;
}

/* line 107, public/wp-content/themes/performair/assets/src/scss/component/_icon.scss */
.o-icon.-arrow-right::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  mask-image: url("../images/svg/arrow-right.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
}

/* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
.c-advantages h2 {
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 64px;
  font-weight: 900;
  line-height: 70.4px;
}

@media (max-width: 768px) {
  /* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
  .c-advantages h2 {
    font-size: 48px;
  }
}

@media (max-width: 500px) {
  /* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
  .c-advantages h2 {
    font-size: 2.8em;
    line-height: 34px;
  }
}

/* line 18, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
.c-advantages .c-advantages_content {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 0;
  margin-top: 80px;
}

@media (max-width: 768px) {
  /* line 18, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
  .c-advantages .c-advantages_content {
    margin-top: 40px;
  }
}

/* line 29, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
.c-advantages .c-advantages_content.-compact .c-advantages_single {
  width: 50%;
}

@media (max-width: 768px) {
  /* line 29, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
  .c-advantages .c-advantages_content.-compact .c-advantages_single {
    width: 100%;
    padding: 0 20px;
    border-right: 1px solid #d0dce8;
  }
}

/* line 41, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
.c-advantages .c-advantages_single {
  width: 33.333%;
  padding: 0 40px;
  border-left: 1px solid #d0dce8;
}

@media (max-width: 991px) {
  /* line 41, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
  .c-advantages .c-advantages_single {
    width: 50%;
  }
}

@media (max-width: 768px) {
  /* line 41, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
  .c-advantages .c-advantages_single {
    width: 100%;
    padding: 0 20px;
    border-right: 1px solid #d0dce8;
  }
}

/* line 56, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
.c-advantages .c-advantages_single h5 {
  margin: 0;
  margin-bottom: 12px;
  color: #212121;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 32px;
  font-weight: 400;
}

/* line 65, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
.c-advantages .c-advantages_single p {
  margin: 0;
}

/* line 69, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
.c-advantages .c-advantages_single:nth-child(3) {
  border-right: 1px solid #d0dce8;
}

@media (max-width: 991px) {
  /* line 69, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
  .c-advantages .c-advantages_single:nth-child(3) {
    border-right: initial;
  }
}

@media (max-width: 768px) {
  /* line 69, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
  .c-advantages .c-advantages_single:nth-child(3) {
    border-right: 1px solid #d0dce8;
  }
}

@media (max-width: 991px) {
  /* line 81, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
  .c-advantages .c-advantages_single:nth-child(even) {
    border-right: 1px solid #d0dce8;
  }
}

@media (max-width: 768px) {
  /* line 81, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
  .c-advantages .c-advantages_single:nth-child(even) {
    border-right: 1px solid #d0dce8;
  }
}

/* line 91, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
.c-advantages .c-advantages_single:last-child {
  border-right: 1px solid #d0dce8;
}

/* line 96, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
.c-advantages .c-advantages_count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  margin-bottom: 48px;
  border-radius: 48px;
  background-color: #eef4ff;
}

/* line 107, public/wp-content/themes/performair/assets/src/scss/component/_c-advantages.scss */
.c-advantages .c-advantages_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  margin-bottom: 48px;
  padding: 24px;
  border-radius: 48px;
  background-color: #eef4ff;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal {
  position: fixed;
  top: 0;
  right: -3000px;
  bottom: 0;
  width: 66%;
  padding: 40px;
  background-color: #fff;
  overflow: auto;
  transition: all ease-in-out 500ms;
  z-index: 999999;
}

@media (max-width: 767px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
  .c-modal {
    width: 100%;
  }
}

/* line 17, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal.is-active {
  right: 0;
}

/* line 22, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #d0dce8;
}

/* line 29, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_header h1 {
  max-width: 100%;
  margin: 0;
}

/* line 34, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_header img {
  cursor: pointer;
  transition: all ease-in-out 150ms;
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_header img:hover {
  opacity: 0.8;
}

/* line 44, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_form {
  padding: 0;
  background-color: #fff;
}

/* line 48, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_form label {
  color: #212121;
  font-size: 18px;
}

/* line 52, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_form label span {
  color: #eb0a0f;
}

/* line 57, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_form input[type="text"],
.c-modal_form input[type="tel"],
.c-modal_form input[type="email"] {
  margin-bottom: 20px;
}

/* line 63, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_form .wpcf7-form-control-wrap {
  width: 100%;
  margin-top: 10px;
}

/* line 68, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_form .c-button {
  border: none;
  cursor: pointer;
}

/* line 73, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_form .wpcf7-response-output {
  right: 10%;
  bottom: 10%;
  left: unset;
}

/* line 80, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_button {
  position: fixed;
  right: 32px;
  bottom: 0;
  display: flex;
  gap: 10px;
  padding: 16px 24px;
  background-color: #24388a;
  cursor: pointer;
  transition: all ease-in-out 150ms;
}

/* line 91, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_button:hover {
  background-color: #0054a4;
}

/* line 95, public/wp-content/themes/performair/assets/src/scss/component/_c-modal.scss */
.c-modal_button p {
  margin: 0;
  color: #fff;
}

/* line 2, public/wp-content/themes/performair/assets/src/scss/component/_c-checkbox.scss */
.c-checkbox .wpcf7-list-item {
  margin: 0;
}

/* line 5, public/wp-content/themes/performair/assets/src/scss/component/_c-checkbox.scss */
.c-checkbox .wpcf7-list-item label {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

/* line 13, public/wp-content/themes/performair/assets/src/scss/component/_c-checkbox.scss */
.c-checkbox input {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid #d0dce8;
  border-radius: 6px;
  background-color: #eef4ff;
}

/* line 23, public/wp-content/themes/performair/assets/src/scss/component/_c-checkbox.scss */
.c-checkbox input:checked {
  background-color: #eef4ff;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_filter.scss */
.c-filter_item {
  padding: 16px;
  border-radius: 8px;
  background-color: #eef4ff;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

/* line 9, public/wp-content/themes/performair/assets/src/scss/component/_filter.scss */
.c-filter_item.is-active {
  background-color: #eb0a0f;
}

/* line 13, public/wp-content/themes/performair/assets/src/scss/component/_filter.scss */
.c-filter_item:hover {
  background-color: #24388a;
}

/* line 17, public/wp-content/themes/performair/assets/src/scss/component/_filter.scss */
.c-filter_item input {
  position: absolute;
  left: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
}

/* line 31, public/wp-content/themes/performair/assets/src/scss/component/_filter.scss */
.c-filters_title {
  margin-top: 8px;
  margin-bottom: 0;
  color: #24388a;
  transition: color 0.3s ease;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

/* line 41, public/wp-content/themes/performair/assets/src/scss/component/_filter.scss */
.c-filter_item:hover .c-filters_title,
.c-filter_item.is-active .c-filters_title {
  color: white;
}

/* line 47, public/wp-content/themes/performair/assets/src/scss/component/_filter.scss */
.c-filters_media {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0 auto;
}

/* line 53, public/wp-content/themes/performair/assets/src/scss/component/_filter.scss */
.c-filters_media img {
  position: absolute;
  inset: 0;
  transition: filter 0.3s ease;
  object-fit: contain;
}

/* line 59, public/wp-content/themes/performair/assets/src/scss/component/_filter.scss */
.c-filter_item:hover .c-filters_media img,
.c-filter_item.is-active .c-filters_media img {
  filter: brightness(0) invert(1);
}

/* line 66, public/wp-content/themes/performair/assets/src/scss/component/_filter.scss */
[data-filter-item]:not(.is-match),
.data-filter-item[data-pagination-item="false"] {
  display: none !important;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  text-align: center;
}

/* line 11, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card_body {
  padding: 40px;
}

@media (max-width: 500px) {
  /* line 11, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
  .c-card_body {
    padding: 16px;
  }
}

/* line 19, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card_media {
  position: relative;
  margin-bottom: 20px;
  aspect-ratio: 1/0.8;
}

/* line 24, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card_media img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* line 33, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card_categories {
  margin-bottom: 12px;
  color: #212121;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}

/* line 41, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card_title {
  margin-bottom: 20px;
  color: #212121;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 1;
}

@media (max-width: 700px) {
  /* line 41, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
  .c-card_title {
    font-size: 24px;
  }
}

/* line 53, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card_footer {
  margin-top: auto;
  margin-bottom: 0;
  padding: 20px 40px;
  background-color: #24388a;
  color: #fff;
  overflow: hidden;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  font-size: 14px;
}

/* line 64, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card:hover .c-card_footer {
  background-color: #135acf;
}

/* line 69, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card_tags {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* line 75, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card_tag {
  padding: 6px 10px;
  border-radius: 4px;
  background-color: #eef4ff;
  color: #24388a;
  font-size: 14px;
  line-height: 20px;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 84, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card_tag.-red {
  background-color: #eb0a0f;
  color: #fff;
}

/* line 89, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card_tag.-black {
  background-color: #212121;
  color: #fff;
}

/* line 94, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card_tag.-green {
  background-color: #14ae5c;
  color: #fff;
}

/* line 100, public/wp-content/themes/performair/assets/src/scss/component/_card.scss */
.c-card_subtitle {
  color: #3f474e;
  font-size: 18px;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product {
  display: flex;
}

@media (max-width: 1000px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product {
    flex-direction: column;
  }
}

/* line 9, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_info {
  padding: 80px;
  background-color: #24388a;
  color: #fff;
}

@media (max-width: 1000px) {
  /* line 9, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product_info {
    padding: 40px;
  }
}

@media (max-width: 700px) {
  /* line 9, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product_info {
    padding: 40px 16px;
  }
}

/* line 23, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_heading {
  margin: 40px 0;
  padding: 40px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* line 30, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_back {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
}

/* line 39, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_back i {
  color: #eb0a0f;
}

/* line 44, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_categories {
  margin-bottom: 24px;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-bold";
  font-size: 24px;
}

/* line 51, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_title {
  margin-bottom: 24px;
  color: #fff;
  font-size: 64px;
  line-height: 1;
}

@media (max-width: 1000px) {
  /* line 51, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product_title {
    font-size: 48px;
  }
}

/* line 62, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_spec {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: #fff;
  font-size: 24px;
}

@media (max-width: 700px) {
  /* line 62, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product_spec {
    flex-direction: column;
  }
}

/* line 74, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_spec span:first-child {
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 80, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_spec.-small {
  font-size: 18px;
}

/* line 83, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_spec.-small span:first-child {
  text-transform: inherit;
  font-family: "Helvetica-neue-condensed-bold";
}

/* line 91, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_list {
  margin-bottom: 32px;
}

/* line 95, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_info,
.c-single-product_medias {
  flex: 1;
}

/* line 100, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_medias {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d0dce8;
  background-color: #fff;
}

@media (max-width: 1000px) {
  /* line 100, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product_medias {
    display: none;
    aspect-ratio: 1/0.6;
  }
}

/* line 111, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_medias.-mobile {
  display: none;
}

@media (max-width: 1000px) {
  /* line 111, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product_medias.-mobile {
    display: flex;
    margin-bottom: 40px;
    aspect-ratio: 1/0.6;
  }
}

@media (max-width: 768px) {
  /* line 111, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product_medias.-mobile {
    aspect-ratio: 1/1;
  }
}

/* line 126, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_media {
  position: relative;
  flex: 1;
}

/* line 130, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_media img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  object-fit: contain;
}

@media (max-width: 768px) {
  /* line 130, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product_media img {
    padding: 20px;
  }
}

/* line 144, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_brand {
  position: relative;
  border-top: 1px solid #d0dce8;
  text-align: center;
}

/* line 149, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_brand img {
  max-height: 100px;
  margin: 0 auto;
  padding: 20px;
}

/* line 156, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_tag-img {
  filter: brightness(0) invert(1);
}

/* line 160, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_file {
  padding: 24px;
  border: 1px solid #d0dce8;
  border-radius: 8px;
}

@media (max-width: 700px) {
  /* line 160, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product_file {
    padding: 16px;
  }
}

/* line 170, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_file-title {
  display: flex;
  align-items: center;
  gap: 24px;
}

/* line 176, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_content-title {
  margin-bottom: 24px;
  color: #24388a;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-regular";
  font-size: 48px;
  line-height: 1;
}

@media (max-width: 700px) {
  /* line 176, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product_content-title {
    font-size: 40px;
  }
}

/* line 189, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_content {
  max-width: 1062px;
  margin: 120px auto 0;
}

@media (max-width: 700px) {
  /* line 189, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product_content {
    margin: 40px auto 0;
  }
}

/* line 198, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_icon {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #24388a;
}

/* line 208, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.-red .c-single-product_icon {
  background-color: #c2271d;
}

/* line 212, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.-green .c-single-product_icon {
  background-color: #0f954d;
}

/* line 217, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-produt_download {
  display: flex;
  justify-content: end;
}

/* line 222, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_action {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 24px;
  padding: 40px;
  border-radius: 16px;
}

@media (max-width: 700px) {
  /* line 222, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
  .c-single-product_action {
    align-items: baseline;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }
}

/* line 237, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_action h4,
.c-single-product_action p {
  color: #fff;
}

/* line 242, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_action h4 {
  margin-bottom: 8px;
  text-transform: uppercase;
}

/* line 247, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_action.-red {
  background-color: #eb0a0f;
}

/* line 251, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_action.-green {
  background-color: #14ae5c;
}

/* line 256, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_details {
  margin-bottom: 40px;
}

/* line 260, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_desc {
  margin-bottom: 40px;
}

/* line 264, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_tags {
  display: flex;
  justify-content: space-between;
}

/* line 269, public/wp-content/themes/performair/assets/src/scss/component/_single-product.scss */
.c-single-product_cats {
  display: flex;
  gap: 8px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_feature-link.scss */
.c-feature-link {
  padding: 40px;
  border-radius: 16px;
  background-color: #eef4ff;
}

@media (max-width: 700px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_feature-link.scss */
  .c-feature-link {
    padding: 16px;
  }
}

/* line 11, public/wp-content/themes/performair/assets/src/scss/component/_feature-link.scss */
.c-feature-link_title {
  margin-bottom: 16px;
  color: #212121;
  text-transform: uppercase;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 18, public/wp-content/themes/performair/assets/src/scss/component/_feature-link.scss */
.c-feature-link_content {
  margin-bottom: 40px;
}

/* line 22, public/wp-content/themes/performair/assets/src/scss/component/_feature-link.scss */
.c-feature-link_btn {
  display: inline-block;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_gallery.scss */
.c-gallery,
.c-gallery_nav {
  max-width: 1062px;
  margin: 0 auto;
}

/* line 6, public/wp-content/themes/performair/assets/src/scss/component/_gallery.scss */
.c-gallery_media {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 16/9;
}

/* line 13, public/wp-content/themes/performair/assets/src/scss/component/_gallery.scss */
.c-gallery_media.-video:before {
  content: "";
  position: absolute;
  inset: 0;
  width: 24px;
  height: 24px;
  margin: auto;
  background-image: url("../images/svg/play.svg");
  background-size: contain;
  z-index: 1;
}

/* line 26, public/wp-content/themes/performair/assets/src/scss/component/_gallery.scss */
.c-gallery_media img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

/* line 34, public/wp-content/themes/performair/assets/src/scss/component/_gallery.scss */
.c-gallery_nav {
  margin-top: 24px;
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_gallery.scss */
.c-gallery_nav-item {
  margin-right: 12px;
  padding: 4px;
  border: 2px solid transparent;
  border-radius: 8px;
  cursor: pointer;
}

/* line 45, public/wp-content/themes/performair/assets/src/scss/component/_gallery.scss */
.c-gallery_nav-item.slick-current {
  border-color: #eb0a0f;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_video.scss */
.c-video {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 16/9;
}

/* line 8, public/wp-content/themes/performair/assets/src/scss/component/_video.scss */
.c-video_video {
  position: absolute;
  inset: 0;
  display: none;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* line 16, public/wp-content/themes/performair/assets/src/scss/component/_video.scss */
.is-init .c-video_video {
  display: block;
  pointer-events: all;
}

/* line 22, public/wp-content/themes/performair/assets/src/scss/component/_video.scss */
.c-video_placeholder,
.c-video_thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: opacity 0.2s ease;
  pointer-events: none;
  pointer-events: none;
}

/* line 32, public/wp-content/themes/performair/assets/src/scss/component/_video.scss */
.is-init .c-video_placeholder, .is-init
.c-video_thumbnail {
  opacity: 0;
}

/* line 37, public/wp-content/themes/performair/assets/src/scss/component/_video.scss */
.c-video_play {
  position: absolute;
  inset: 0;
  width: 48px;
  height: 48px;
  margin: auto;
  cursor: pointer;
}

/* line 45, public/wp-content/themes/performair/assets/src/scss/component/_video.scss */
.c-video_play:before {
  content: url("../images/svg/play.svg");
}

/* line 49, public/wp-content/themes/performair/assets/src/scss/component/_video.scss */
.c-video.is-init .c-video_play {
  transform: translate3d(-50%, -50%, 0) scale(0);
  opacity: 0;
  pointer-events: none;
}

/* line 55, public/wp-content/themes/performair/assets/src/scss/component/_video.scss */
.c-video.has-replay.is-ended .c-video_play {
  transform: translate3d(-50%, -50%, 0) scale(1);
  opacity: 1;
  pointer-events: all;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header {
  padding: 80px 0;
  background-color: #24388a;
  color: #fff;
}

@media (max-width: 991px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
  .c-service-header {
    padding: 0px 0 40px;
  }
}

/* line 11, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 90px;
}

@media (max-width: 991px) {
  /* line 11, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
  .c-service-header_inner {
    align-items: normal;
    flex-direction: column;
  }
}

/* line 23, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_heading {
  position: relative;
  max-width: 55%;
}

@media (max-width: 991px) {
  /* line 23, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
  .c-service-header_heading {
    max-width: inherit;
  }
}

/* line 32, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_title,
.c-service-header_overline,
.c-service-header_content {
  color: #fff;
}

/* line 38, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_overline {
  margin-bottom: 40px;
  font-size: 32px;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 44, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_title {
  margin-bottom: 40px;
  font-size: 96px;
}

@media (max-width: 991px) {
  /* line 44, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
  .c-service-header_title {
    font-size: 68px;
  }
}

@media (max-width: 767px) {
  /* line 44, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
  .c-service-header_title {
    font-size: 58px;
  }
}

@media (max-width: 500px) {
  /* line 44, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
  .c-service-header_title {
    font-size: 48px;
  }
}

/* line 61, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_content {
  max-width: 80%;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 991px) {
  /* line 61, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
  .c-service-header_content {
    max-width: inherit;
  }
}

/* line 71, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_medias {
  position: relative;
  flex: 1;
}

/* line 76, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_media {
  position: relative;
  margin-right: -120px;
  border-radius: 50%;
  overflow: hidden;
  aspect-ratio: 1/1;
}

@media (max-width: 991px) {
  /* line 76, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
  .c-service-header_media {
    margin-right: 0;
  }
}

/* line 88, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

/* line 95, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_icon {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 128px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;
  background-color: #eb0a0f;
}

@media (min-width: 992px) {
  /* line 95, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
  .c-service-header_icon {
    bottom: 0;
    left: -64px;
  }
}

@media (max-width: 991px) {
  /* line 95, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
  .c-service-header_icon {
    top: -64px;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
  }
}

/* line 121, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_icon img {
  max-width: 48px;
}

/* line 126, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_scroll {
  display: inline-flex;
  padding-top: 40px;
  color: #fff;
  font-size: 18px;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 133, public/wp-content/themes/performair/assets/src/scss/component/_service-header.scss */
.c-service-header_scroll::before {
  content: url("../images/svg/continueScroll.svg");
  margin-right: 12px;
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_text.scss */
.c-text_title {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1;
  font-family: "Helvetica-neue-condensed-regular";
}

@media (max-width: 700px) {
  /* line 1, public/wp-content/themes/performair/assets/src/scss/component/_text.scss */
  .c-text_title {
    font-size: 32px;
  }
}

/* line 12, public/wp-content/themes/performair/assets/src/scss/component/_text.scss */
.c-text_subtitle {
  margin-bottom: 24px;
  font-family: "Helvetica-neue-condensed-regular";
}

/* line 1, public/wp-content/themes/performair/assets/src/scss/component/_media.scss */
.c-media {
  max-width: 1062px;
  margin: 0 auto;
}

/* line 6, public/wp-content/themes/performair/assets/src/scss/component/_media.scss */
.c-media_media {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 16/9;
}

/* line 12, public/wp-content/themes/performair/assets/src/scss/component/_media.scss */
.c-media_media img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

/* line 2, public/wp-content/themes/performair/assets/src/scss/component/_select.scss */
.c-select select {
  width: 100%;
  padding: 24px 20px;
  border: 1px solid #d0dce8;
  border-radius: 8px;
  font-size: 18px;
}
