.c-boutique {
	position: relative;
	padding: 100px 0;
	background: white;

	h2 {
		margin-bottom: 40px;
	}

	&__listing {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		margin: 0;
		margin-right: -2.33%;
		padding: 0;
		list-style-type: none;

		@include mq(medium) {
			margin: 0 -2% 0 0;
		}

		@include mq(small) {
			margin: 0;
		}

		li {
			width: 30%;
			margin-right: 3.33%;
			margin-bottom: 50px;
			box-shadow: 0px 0px 50px rgba($grey-3, 0.2);

			@include mq(medium) {
				width: 48%;
				margin-right: 2%;
			}

			@include mq(small) {
				width: 100%;
				margin-right: 0;
			}

			&:hover {
				.category-image {
					&:before {
						opacity: 1;
					}
				}
			}

			.c-button.small {
				@include mq(xsmall) {
					padding: 10px 25px;
				}
			}

			.category-image {
				position: relative;
				display: flex;
				align-items: flex-start;
				flex-flow: column nowrap;
				justify-content: flex-end;
				height: 250px;
				padding: 30px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;

				@include mq(xsmall) {
					height: 200px;
				}

				&:before {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					opacity: 0.7;
					background: linear-gradient(45deg, rgba($grey-2, 1) 25%, rgba(0, 0, 0, 0) 100%);
					transition: all 300ms ease;
				}

				.pre-title {
					margin-bottom: 0px;

					&:before {
						border-top: 10px solid transparent;
						border-right: 10px solid transparent;
						border-bottom: 10px solid transparent;
						border-left: 10px solid $blue;
					}
				}

				h3 {
					position: relative;
					color: white;
					z-index: 2;
				}
			}

			.content {
				padding: 40px;
				background: white;

				@include mq(xsmall) {
					padding: 30px 20px;
				}

				p {
					font-size: 1.8rem;
					line-height: 2.5rem;

					@include mq(xsmall) {
						font-size: 1.5rem;
						line-height: 2.2rem;
					}
				}
			}
		}
	}

	&__produits {
		position: relative;
		padding: 150px 0px;
		background: white;
		background-repeat: no-repeat;
		background-position: right center;
		background-size: auto;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: linear-gradient(to right, rgba(#f5f6f6, 1), rgba(#f5f6f6, 0));
			z-index: 0;
		}

		.container {
			position: relative;
			z-index: 1;

			h3 {
				margin-bottom: 20px;
				font-family: $helv-bold;
				font-size: 3.5rem;
				line-height: 4rem;
			}

			ul {
				margin: 20px 0px;
				padding: 0;
				list-style-type: none;

				li {
					margin: 10px 0px;

					&:before {
						content: "\f0dd";
						display: inline-block;
						margin-right: 10px;
						transform: rotate(-90deg);
						color: $blue;
						text-rendering: auto;
						font: normal normal normal 14px/1 FontAwesome;
						font-size: inherit;
					}

					a {
						color: $grey-1;
						font-family: $helv-regular;
					}
				}
			}
		}

		.orange-triangle {
			position: absolute;
			right: 0px;
			bottom: 0px;
			display: block;
			transform: rotate(135deg);
			border-top: 75px solid transparent;
			border-right: 75px solid transparent;
			border-bottom: 75px solid rgba($red, 0.8);
			border-left: 75px solid transparent;
		}
	}

	&-listing {
		padding: 100px 0px;
		background: white;

		.container:nth-of-type(2) {
			display: flex;
			flex-direction: row;
		}

		.left {
			flex-grow: 1;
		}

		&__filters {
			display: flex;
			align-items: center;
			flex-flow: row wrap;
			justify-content: center;
			margin-top: 20px;
			span {
				display: inline-block;
				margin-top: 5px;
				margin-right: 10px;
				margin-bottom: 5px;
				text-transform: uppercase;
				font-family: $helv-bold;
				font-size: 1.4rem;
			}

			button,
			a {
				margin-top: 5px;
				margin-bottom: 5px;
				padding: 10px 30px;
				border: 1px solid white;
				border-radius: 50px;
				//background-color: transparent;
				background-color: $blue;
				color: white;
				cursor: pointer;
				text-transform: uppercase;
				font-family: $helv-regular;
				font-size: 1.4rem;
				&:not(:last-of-type) {
					margin-right: 10px;
				}

				&.active {
					//border-color: $blue;
					border-color: $red;
					//background-color: $blue;
					background-color: $red;
					color: white;
				}
				&:focus {
					outline: none;
				}
			}
		}

		.c-boutique-sidebar {
			min-width: 275px;

			h3 {
				margin-bottom: 20px;
				font-family: $helv-bold;
				font-size: 2.6rem;
				line-height: 3rem;
			}

			.boxes-wrapper {
				display: flex;
				flex-direction: column;

				a {
					margin-bottom: 30px;
					padding: 60px 40px;

					h3 {
						font-size: 2.2rem;
						line-height: 2.8rem;
					}
				}
			}

			ul {
				display: flex;
				flex-direction: column;
				width: 100%;

				li {
					display: flex;
					align-items: center;
					flex-direction: row;
					width: 100%;
					margin: 5px 0px;
					padding: 10px 0px;
					border-bottom: 1px solid $grey-1;
					box-shadow: none;

					&:first-child {
						border-top: 1px solid $grey-1;
					}

					&:before {
						content: "\f0dd";
						display: inline-block;
						margin-right: 10px;
						transform: rotate(-90deg);
						color: $blue;
						text-rendering: auto;
						font: normal normal normal 14px/1 FontAwesome;
						font-size: inherit;
					}

					a {
						color: $grey-1;
						font-family: $helv-regular;
					}
				}
			}
		}

		h1 {
			max-width: 100%;
			margin-bottom: 30px;
			text-transform: initial;
			font-family: $helv-bold;
			font-size: 4rem;
			line-height: 4.5rem;
		}

		ul {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			margin: 0 -3.33% 0 0;
			padding: 0;
			list-style-type: none;

			@include mq(medium) {
				margin: 0 -2% 0 0;
			}

			@include mq(small) {
				margin: 0;
			}

			li {
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				width: 30%;
				margin-right: 3.33%;
				margin-bottom: 40px;
				box-shadow: 0px 0px 50px rgba($grey-3, 0.2);

				@include mq(medium) {
					width: 48%;
					margin-right: 2%;
				}

				@include mq(small) {
					width: 100%;
					margin-right: 0;
				}

				.image-wrapper {
					position: relative;
					display: flex;
					align-items: flex-start;
					flex-flow: column wrap;
					justify-content: flex-end;
					width: 100%;
					height: 100%;
					min-height: 225px;
					padding: 50px 30px 20px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;

					@include mq(xsmall) {
						padding: 40px 20px 20px;
					}

					&:before {
						content: "";
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						background: linear-gradient(45deg, rgba($grey-2, 1) 25%, rgba(0, 0, 0, 0) 100%);
					}

					& > * {
						position: relative;
						z-index: 10;
					}
				}

				.pre-title {
					margin-bottom: 0px;
					color: white;
					font-size: 1.4rem;
					line-height: 2rem;

					&:before {
						display: block;
						margin-bottom: -5px;
						margin-left: 4px;
						border-width: 10px !important;
					}

					a {
						display: inline;
						color: white;
					}
				}

				a {
					display: flex;
					align-items: center;
					flex-flow: row wrap;
					justify-content: space-between;
					width: 100%;

					&:not(.c-button) {
						@include mq(xsmall) {
							align-items: flex-start;
							flex-flow: column wrap;
						}
					}
				}

				h2 {
					margin-bottom: 0px;
					color: white;
					font-size: 2.3rem;
					line-height: 2.8rem;
				}

				.price {
					color: white;
					font-family: $helv-regular;
				}
			}

			.content {
				padding: 20px 30px;

				@include mq(xsmall) {
					padding: 20px;
				}

				a {
					margin-top: 0;
					padding: 10px 30px;
				}
			}
		}
	}

	&-single {
		padding: 150px 0px;
		background: white;

		@include mq(medium) {
			padding: 100px 0;
		}

		@include mq(small) {
			padding: 50px 0;
		}

		.product_meta {
			display: none;
		}

		.single-top-row {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 50px;

			@include mq(medium) {
				flex-flow: row wrap;
			}

			& > div {
				width: 48%;

				@include mq(medium) {
					width: 100%;
					margin-bottom: 40px;
				}
			}

			.js-single-slider {
				.slick-slide {
					height: 475px;
					outline: 0;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;

					@media (max-width: 650px) {
						height: 400px;
					}

					@include mq(xsmall) {
						height: 300px;
					}

					@media (max-width: 375px) {
						height: 250px;
					}
				}
			}

			form.cart {
				display: flex;
				flex-flow: row nowrap;

				.qty {
					width: 50px;
					height: 40px;
					margin-right: 15px;
					padding-left: 5px;
					outline: 0;
					border: 2px solid $blue;
					border-radius: 0px 0px 0px 10px;
					color: $grey-2;
					text-align: center;
					font-family: $helv-bold;
					font-size: 1.8rem;
					line-height: 1.8rem;
				}

				button {
					display: inline-flex;
					flex-flow: row wrap;
					margin-top: 20px;
					margin-top: 0px;
					padding: 13px 30px;
					outline: 0px;
					border: 0px;
					border-radius: 0px 50px 50px 50px;
					box-shadow: 0px 0px 30px rgba($grey-3, 0.4);
					background: $blue;
					color: white;
					cursor: pointer;
					transition: all 300ms ease;
					text-transform: uppercase;
					font-family: $helv-regular;
					font-size: 1.4rem;
					letter-spacing: 1px;
					align-iems: center;

					&:hover {
						background: lighten($blue, 10%);
					}

					&:after {
						content: "\f0dd";
						display: inline-block;
						transform: rotate(-90deg);
						color: white;
						text-rendering: auto;
						font: normal normal normal 14px/1 FontAwesome;
						font-size: inherit;
					}
				}
			}

			.woocommerce-product-gallery__wrapper {
				display: flex;
				flex-flow: row wrap;
				justify-content: flex-start;

				& > div {
					margin-right: 15px;
					margin-bottom: 15px;
				}
			}

			figure {
				margin-top: 0px;
				margin-left: 0px;
			}

			.pre-title {
				a {
					color: $grey-1;
				}
			}

			h1 {
				width: 100%;
				padding-bottom: 15px;
				border-bottom: 1px solid $grey-1;
				text-transform: initial;
				font-size: 5rem;
				line-height: 5.5rem;

				@include mq(small) {
					font-size: 4rem;
					line-height: 4.5rem;
				}

				@include mq(xsmall) {
					font-size: 3rem;
					line-height: 3.5rem;
				}
			}

			.price {
				margin-bottom: 30px;
				color: $blue;
				font-family: $helv-bold;
				font-size: 4.5rem;

				.amount {
					display: flex;
					align-items: flex-start;
					flex-direction: row;
				}

				.woocommerce-Price-currencySymbol {
					margin-top: -5px;
					font-size: 3rem;
					line-height: 3rem;
				}
			}

			h3 {
				margin-bottom: 10px;
				font-family: $helv-regular;
				font-size: 2.4rem;
				line-height: 2.8rem;
			}
		}

		.woocommerce-product-details__short-description {
			margin-bottom: 30px;
		}

		.related {
			ul {
				display: flex;
				flex-flow: row wrap;
				margin: 0 -3.33% 0 0;
				padding: 0;
				list-style-type: none;

				li {
					width: 30%;
					margin-right: 3.33%;

					.image-wrapper {
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
					}
				}
			}
		}
	}
}

.related.products {
	margin-top: 100px;
	box-shadow: none;

	h2 {
		margin-bottom: 40px;
		text-align: left;
		font-size: 4rem;
		line-height: 4.5rem;
	}

	ul {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		margin: 0 -3.33% 0 0;
		padding: 0;
		list-style-type: none;

		@include mq(medium) {
			margin: 0 -2% 0 0;
		}

		@include mq(small) {
			margin: 0;
		}

		li {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			width: 30%;
			min-height: 350px;
			margin-right: 3.33%;
			margin-bottom: 40px;
			box-shadow: 0px 0px 50px rgba($grey-3, 0.2);

			@include mq(medium) {
				width: 48%;
				margin-right: 2%;
			}

			@include mq(small) {
				width: 100%;
				margin-right: 0;
			}

			.image-wrapper {
				position: relative;
				display: flex;
				align-items: flex-start;
				flex-flow: column wrap;
				justify-content: flex-end;
				width: 100%;
				height: 100%;
				min-height: 225px;
				padding: 50px 30px 20px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;

				@include mq(xsmall) {
					padding: 40px 20px 20px;
				}

				&:before {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background: linear-gradient(45deg, rgba($grey-2, 1) 25%, rgba(0, 0, 0, 0) 100%);
				}

				& > * {
					position: relative;
					z-index: 10;
				}
			}

			.pre-title {
				margin-bottom: 0px;
				color: white;
				font-size: 1.4rem;
				line-height: 2rem;

				&:before {
					display: block;
					margin-bottom: -5px;
					margin-left: 4px;
					border-width: 10px !important;
				}

				a {
					color: white;
				}
			}

			a {
				display: flex;
				align-items: center;
				flex-flow: row wrap;
				justify-content: space-between;
				width: 100%;

				&:not(.c-button) {
					@include mq(xsmall) {
						align-items: flex-start;
						flex-flow: column wrap;
					}
				}
			}

			h2 {
				margin-bottom: 0px;
				color: white;
				font-size: 3rem;
				line-height: 3.5rem;

				@include mq(xsmall) {
					font-size: 2.5rem;
					line-height: 3rem;
				}
			}

			.price {
				color: white;
				font-family: $helv-regular;
			}
		}

		.content {
			padding: 20px 30px;

			@include mq(xsmall) {
				padding: 20px;
			}

			a {
				margin-top: 0;
				padding: 10px 30px;
			}
		}
	}
}
