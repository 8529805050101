/* =======================================================================
Layout
========================================================================== */

.wrapper {
	position: relative;

	&.c-home__about {
		padding: 200px 0px 50px;

		@include mq(medium) {
			padding: 120px 0px 50px;
		}
	}
}

$section-colors: "white", "ligthblue";

.o-section {
	padding: 120px 0;
	opacity: 0;
	transition: all 1s ease-in-out;

	@media (max-width: 768px) {
		padding: 60px 0;
	}

	@each $section-color in $section-colors {
		&.-#{$section-color} {
			&.-collapse + .o-section.-#{$section-color}.-collapse {
				padding-top: 0;
			}
		}
	}

	&.is-inview,
	&.-not-anim {
		opacity: 1;
	}

	&.-compact {
		padding: 60px 0;

		@media (max-width: 768px) {
			padding: 30px 0;
		}
	}

	&.-no-spacing-top {
		padding: 0 0px 120px 0px;

		@media (max-width: 768px) {
			padding: 0 0 60px 0;
		}
	}

	&.-no-spacing-bottom {
		padding: 120px 0px 0px 0px;

		@media (max-width: 768px) {
			padding: 60px 0 0px 0;
		}
	}

	&.-lightblue {
		background-color: $lightblue;
	}

	&.-white {
		background-color: $white;
	}

	&.-hidden {
		overflow: hidden;
	}
}

.o-section_header {
	display: flex;
	gap: 25px;
	padding-bottom: 48px;

	@media (max-width: 1000px) {
		flex-direction: column;
	}

	&.-carousel {
		@media (min-width: 1001px) {
			align-items: center;
		}
	}
}

.o-section_overline {
	margin-bottom: 24px;
	color: $grey-3;
	font-family: $helv-regular;
	font-size: 32px;
}

.o-section_title {
	max-width: inherit;
	color: $darkblue;
	font-family: $helv-regular;
	font-size: 96px;
	line-height: 1;

	@media (max-width: 1000px) {
		font-size: 64px;
	}

	@media (max-width: 700px) {
		font-size: 56px;
	}

	&.-md {
		font-size: 64px;

		@media (max-width: 700px) {
			font-size: 48px;
		}
	}
}

.o-section_heading {
	@media (min-width: 1001px) {
		width: 66%;
	}
}

.o-section_description {
	flex: 1;

	&.-border {
		padding-top: 24px;
		border-top: 1px solid #d0dce8;
	}
}

.o-section_actions {
	display: flex;
	flex: 1;
	gap: 4px;

	@media (min-width: 1001px) {
		justify-content: end;
	}
}

.o-section_filters {
	padding-bottom: 32px;
}

.o-container {
	padding: 0 80px;

	@media (max-width: 768px) {
		padding: 0 30px;
	}

	&.-no-spacing {
		padding: 0;
	}

	&.-xs {
		padding: 0 296px;

		@media (max-width: 991px) {
			padding: 0 100px;
		}

		@media (max-width: 768px) {
			padding: 0 30px;
		}
	}
}

.container {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding-right: 40px;
	padding-left: 40px;

	@include mq(xsmall) {
		padding-right: 30px;
		padding-left: 30px;
	}
}

.row {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin-right: -2%;
	margin-bottom: 100px;

	@include mq(small) {
		margin-bottom: 60px;
	}

	&.half-mb {
		margin-bottom: 50px;
	}

	&.align-center {
		align-items: center;
	}

	&.align-start {
		align-items: flex-start;
	}

	&.no-gutter {
		margin-right: 0;

		.col_1x2 {
			width: 50%;
			margin-right: 0;
		}
		.col_1x3 {
			width: 33.33%;
			margin-right: 0;
		}
		.col_2x3 {
			width: 65%;
			margin-right: 0;
		}

		.col_1x4 {
			width: 25%;
			margin-right: 0;
		}

		.col_2x5 {
			width: 40%;
			margin-right: 0;
		}

		.col_3x5 {
			width: 60%;
			margin-right: 0;
		}
	}

	&.no-mb {
		margin-bottom: 0px;
	}
}

.grow {
	flex-grow: 1;
}

.col_1x1 {
	width: 100%;

	&.center {
		text-align: center;
	}

	&.right {
		text-align: right;
	}
}

.col_1x2,
.col_1x3,
.col_1x4 {
	position: relative;
}

.col_1x2 {
	width: 48%;
	margin-right: 2%;

	&.right {
		text-align: right;
	}
}

.col_1x3 {
	width: 31%;
	margin-right: 2%;
}

.col_1x4 {
	width: 23%;
	margin-right: 2%;
}

.col_2x5 {
	width: 38%;
	margin-right: 2%;
}

.col_3x5 {
	width: 58%;
	margin-right: 2%;
}
