.c-dispatch {
	position: relative;
	box-shadow: 0px 0px 50px rgba($grey-3, 0.08);
	background-color: white;
	z-index: 2;

	.col_1x4,
	.col_1x3 {
		border-right: 1px solid $grey-border-2;
		overflow: hidden;

		@include mq(medium) {
			width: 100% !important;
		}

		&:nth-of-type(1) {
			.c-dispatch__item-icon {
				img {
					width: 40px;
				}
			}
		}

		&:nth-of-type(2) {
			.c-dispatch__item-icon {
				img {
					width: 40px;
				}
			}
		}

		&:nth-of-type(3) {
			.c-dispatch__item-icon {
				img {
					width: 50px;
				}
			}
		}

		&:nth-of-type(4) {
			.c-dispatch__item-icon {
				img {
					width: 60px;
				}
			}
		}
	}

	&__item {
		padding: 120px 40px;
		text-align: center;

		&:before {
			content: "";
			position: absolute;
			right: -800px;
			bottom: -800px;
			display: block;
			width: 1000px;
			height: 1000px;
			transform: rotate(45deg);
			background: $red;
			transition: all 500ms ease;

			@include mq(medium) {
				right: -1560px;
				bottom: -1750px;
				width: 2000px;
				height: 2000px;
			}

			@include mq(xsmall) {
				right: -800px;
				bottom: -800px;
				width: 1000px;
				height: 1000px;
			}
		}

		&:hover {
			&:before {
				right: -300px;
				bottom: -300px;
				margin: auto;
			}

			.c-dispatch__item-hover {
				opacity: 1;
				transition-delay: 200ms;

				&:before {
					opacity: 1;
					transition-delay: 200ms;
				}
			}
		}

		h3 {
			font-size: 2.5rem;
			line-height: 3rem;
		}

		&-icon {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			width: 115px;
			height: 115px;
			margin: 0 auto 30px;
			border: 3px solid $grey-1;
			border-radius: 100%;
			align-item: center;
		}

		&-plus {
			position: absolute;
			right: 10px;
			bottom: 10px;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			width: 24px;
			height: 24px;
			align-item: center;

			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				display: block;
				width: 18px;
				height: 2px;
				background: white;
			}

			&:after {
				transform: rotate(90deg);
			}
		}

		&-hover {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			width: 100%;
			height: 100%;
			opacity: 0;
			background-repeat: no-repeat;
			background-size: cover;
			align-center: center;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				width: 100%;
				height: 100%;
				opacity: 0;
				background: rgba(236, 63, 52, 0.6);
				transition: all 500ms ease;
				transition-delay: 100ms;
				z-index: 1;
				align-center: center;
			}

			& > * {
				position: relative;
				z-index: 2;
			}

			img {
				margin: 0 auto 30px;

				&.one {
					width: 70px;
				}

				&.two {
					width: 70px;
				}

				&.three {
					width: 80px;
				}

				&.four {
					width: 90px;
				}
			}

			h3 {
				span {
					display: flex;
					align-items: center;
					flex-flow: row nowrap;
					justify-content: center;
					color: white;
					font-family: $helv-bold;

					&:after {
						content: "\f0dd";
						display: inline-block;
						width: 0px;
						height: 0px;
						margin-bottom: -16px;
						transform: rotate(-90deg);
						color: $blue;
						font-family: fontAwesome;
					}
				}
			}
		}
	}

	&.commercial {
		.c-dispatch__item {
			&-hover {
				align-items: flex-start;
				padding: 30px 50px;

				h3 {
					margin-bottom: 10px;
					color: white;

					span {
						text-align: left;
						line-height: 2.4rem;

						&:after {
							display: none;
						}
					}
				}

				p {
					color: white;
					text-align: left;
					font-size: 1.5rem;
					line-height: 2.2rem;
				}
			}
		}
	}
}
.c-dispatch__item-icon.-refrigeration img {
	padding: 12px;
}
