@keyframes response-pop-up {
	0% {
		transform: translate3d(-50%, 24px, 0);
		opacity: 0;
	}

	5% {
		transform: translate3d(-50%, 0, 0);
		opacity: 1;
	}

	95% {
		transform: translate3d(-50%, 0, 0);
		opacity: 1;
	}

	100% {
		transform: translate3d(-50%, 24px, 0);
		opacity: 0;
	}
}

@keyframes response-pop-up-loading {
	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}
}

.wpcf7-response-output {
	position: fixed;
	bottom: 50%;
	left: 50%;
	width: 30rem;
	padding: 2rem 2rem 2.5rem 2rem !important;
	transform: translate3d(-50%, -50%, 0);
	border: 2px solid #3c5058 !important;
	background-color: #ffffff;
	color: #3f474e;
	animation: response-pop-up 5s ease forwards;
	font-family: $helv-regular;
	font-weight: 700;
	line-height: 1.2;
	z-index: 40;
}

.wpcf7-response-output::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 6px;
	transform-origin: left;
	background-color: #ef4035;
	animation: response-pop-up-loading 4.5s 0.25s linear forwards;
}

.c-form {
	padding: 100px 0px;
	background-color: $grey-bg;

	h2 {
		margin-bottom: 40px;
	}

	form {
		.col_1x2 {
			width: 48% !important;

			@include mq(xsmall) {
				width: 100% !important;
			}

			.wpcf7-form-control-wrap {
				@include mq(xsmall) {
					width: 100% !important;
				}
			}
		}
	}

	input[type="text"],
	input[type="email"],
	input[type="tel"] {
		width: 100%;
		margin-bottom: 40px;
		padding: 15px 30px;
		outline: 0;
		border: 0px;
		border-radius: 50px;
		// background: $grey-input;
		color: $grey-2;
		font-family: $helv-regular;
		font-size: 1.6rem;
		line-height: 2.4rem;
		border: 1px solid #d0dce8;
		border-radius: 8px;


		&::placeholder {
			color: $grey-1;
			font-family: $helv-regular;
			font-size: 18px;
			font-weight: 400;
		}
	}

	select {
		width: 100%;
		margin-bottom: 40px;
		padding: 15px 30px;
		outline: 0;
		border: 0px;
		border-radius: 50px;
		background: $grey-input;
		color: $grey-2;
		font-family: $helv-regular;
		font-size: 1.6rem;
		line-height: 2.4rem;
		appearance: none;
	}

	input[type="submit"] {
		margin-top: 0px;
		outline: 0;
		border: 0px;
		cursor: pointer;
		transition: all 300ms ease;
	}

	.posteConvoite + .fa {
		position: relative;
		margin-left: -35px;
		color: $grey-2;
		font-size: 1.6rem;
		pointer-events: none;
		z-index: 999;
	}

	.wpcf7-form-control-wrap {
		position: relative;
		display: inline-block;
	}
	.wpcf7-form-control-wrap.message {
		width: 100%;
	}

	.wpcf7-list-item {
		display: block;
		line-height: 25px;

		&-label {
			color: #3f474e;
			font-family: "Helvetica-neue-condensed-regular";
			font-size: 1.6rem;
			line-height: 2rem;
		}
	}

	span[role="alert"] {
		position: absolute;
		top: 60px;
		left: 0px;
		display: block;
		width: 100%;
		color: $red;
		font-family: $helv-bold;
		font-size: 1.4rem;
		line-height: 2rem;
	}

	textarea {
		width: 100%;
		margin-bottom: 40px;
		padding: 15px 30px;
		outline: 0;
		border: 0px;
		border-radius: 8px;

		color: $grey-2;
		font-family: $helv-regular;
		font-size: 1.6rem;
		line-height: 2.4rem;
		border: 1px solid #d0dce8;
		border-radius: 8px;


		&::placeholder {
			color: $grey-1;
			font-family: $helv-regular;
			font-size: 18px;
			font-weight: 400;
		}
	}

	input[type="file"] {
		display: none;
		appearance: none;
	}

	.col_1x3 {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	.ajax-loader {
		display: none !important;
	}

	#c-form__label {
		width: 100%;
		margin-bottom: 40px;
		padding: 15px 30px;
		outline: 0;
		border: 0px;
		border-radius: 50px;
		background: $grey-2;
		color: white;
		cursor: pointer;
		transition: all 300ms ease;
		font-family: $helv-regular;
		font-size: 1.6rem;
		line-height: 2.4rem;

		&:hover {
			background-color: $grey-3;
		}
	}

	.filename {
		position: absolute;
		bottom: -40px;
		left: 0;
		display: block;
		color: $grey-2;
		font-family: $helv-regular;
		font-size: 1.4rem;
		line-height: 2rem;

		@include mq(xsmall) {
			right: 0;
		}
	}

	div.wpcf7-response-output {
		margin: 40px 0px 0px;
		padding: 0px;
		border: 0px;
		font-family: $helv-bold;
		font-size: 1.4rem;
		line-height: 2rem;

		&.wpcf7-validation-errors,
		&.wpcf7-mail-sent-ng {
			color: $red;
		}

		&.wpcf7-mail-sent-ok {
			color: #6bb54e;
		}
	}

	.wpcf7-form-control-wrap.type {
		display: block;
		margin-bottom: 30px;
		padding-bottom: 10px;
		border-bottom: 1px solid $grey-border-2;

		span.first {
			margin-left: 0px;
		}
	}

	.wpcf7-radio {
		display: flex;
		align-items: center;
		flex-flow: row wrap;
		justify-content: flex-start;
		height: 50px;

		@include mq(xsmall) {
			align-items: flex-start;
			flex-flow: column nowrap;
			height: auto;
			margin-bottom: 20px;
		}

		label {
			color: $grey-2;
			font-family: $helv-regular;
			font-size: 1.6rem;
			line-height: 2.4rem;
		}
	}

	.wpcf7-list-item {
		@include mq(xsmall) {
			margin-left: 0;
		}
	}

	.career-buttons {
		@include mq(xsmall) {
			flex-flow: column nowrap;
		}

		.col_1x2 {
			@include mq(xsmall) {
				text-align: center !important;
			}

			&:first-of-type {
				margin-bottom: 60px;
			}
		}
	}

	.wpcf7-list-item {
		margin-right: 24px !important;
	}
	
}
