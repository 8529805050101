.covid {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	flex-flow: row wrap;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 20px;
	background: rgba(white, 0.9);
	z-index: 99999;

	&__container {
		display: flex;
		flex-flow: row nowrap;
		width: 100%;
		max-width: 900px;
		box-shadow: 0px 0px 30px rgba(black, 0.1);
		background: white;

		@include mq(medium) {
			flex-flow: column nowrap;
		}
	}

	&__image {
		width: 100%;
		max-width: 420px;
		object-fit: contain;

		@include mq(medium) {
			display: none;
		}
	}

	&__text {
		position: relative;
		flex-grow: 1;
		padding: 40px 30px 30px;

		h3 {
			margin-bottom: 10px;

			@include mq(medium) {
				font-size: 2.5rem;
			}
		}

		p {
			font-size: 17px;
			line-height: 24px;

			a {
				color: $blue;
			}
		}
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 15px 20px;
		color: $red;
		cursor: pointer;
		font-family: $helv-regular;
		font-size: 16px;

		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 14px;
			height: 0px;
			margin: auto 0;
			transform-origin: center;
			border-bottom: 2px solid $red;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}
