.c-tabs {
	
	.tab {
		display:none;

		&.active {
			display:block;
		}
	}

	&__links {
		display:flex;
		flex-flow:row wrap;
		justify-content: flex-start;
		align-items: center;
		padding-bottom:30px;
		margin-bottom:30px;
		border-bottom:1px solid $grey-border-2;
		list-style-type: none;
		margin-left:0;

		@include mq(medium) {
			justify-content:center;
		}

		@include mq(xsmall) {
			flex-flow:column nowrap;
		}

		li {
			position:relative;
			margin-right:40px;

			@include mq(medium) {
				margin:0px 20px 10px;
			}

			&:after {
				content: "\f0dd";
				display: inline-block;
				font: normal normal normal 14px/1 FontAwesome;
				text-rendering: auto;
				transform:rotate(-90deg);
				color:$blue;
				opacity: 0;
				transition:all 300ms ease;
			}

			&:hover,
			&.active {

				&:after {
					opacity:1;
					transform:translateX(10px) rotate(-90deg);
				}
			}

			a {
				font-family: $helv-regular;
				font-size: 1.8rem;
				line-height: 2rem;
				color: $grey-2;

				@include mq(small) {
					font-family:$helv-bold;
				}

				&:hover {
					color:$grey-3;
				}

			}
		}
	}

	&__content {

		@include mq(xsmall) {
			text-align:center;
		}

		h3 {
			margin-bottom:30px;
		}

		a {
			font-family: $helv-regular;
			font-size: 1.8rem;
			line-height: 2rem;
			color: $blue;
		}
	}
}
