.c-home {

	&__about {
		background-color:$grey-bg;

		p {
			max-width:445px;
			margin-bottom: 50px;
		}

		&-rebate {
			margin-bottom: 100px;

			@include mq(small) {
				margin-bottom: 50px;
			}

			@include mq(xsmall) {
				margin-bottom: 30px;
			}

			img {

				&:first-of-type {
					margin-bottom: 10px;
				}
			}
		}

		.container {

			@include mq(medium) {
				padding-left:40px;
				padding-right:40px;
			}
		}

		.row:first-of-type {
			margin-bottom: 50px;

			@media (max-width: 767px) {
				margin-bottom: 24px;
			}
		}

		.col_1x2 {

			@include mq(small) {
				width:100%;
			}

			&:last-of-type {

				@include mq(small) {
					margin-top:40px;
				}
			}
		}

		&-1968 {
		    position: absolute;
		    top: -80px;
		    right: 40px;
		    max-width: none;
		    width: 100%;
		    backface-visibility: hidden;

		    @media screen and (max-width:1200px) {
		    	width:90%;
		    }

		    @include mq(medium) {
		    	top:0;
		    }

		    @include mq(small) {
		    	position:initial;
		    	width:100%;
		    }
		}

		&-corner {
			position:absolute;
			bottom:0;
			right:0;
			max-width:900px;

			@include mq(medium) {
				max-width:600px;
			}

			@include mq(small) {
				max-width:500px;
			}

			@include mq(xsmall) {
				max-width:470px;
			}
		}
	}
}