.c-checkbox {
	.wpcf7-list-item {
		margin: 0;

		label {
			display: flex;
			align-items: center;
			gap: 20px;
			cursor: pointer;
		}
	}

	input {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		border: 1px solid #d0dce8;
		border-radius: 6px;
		background-color: #eef4ff;

		&:checked {
			background-color: #eef4ff;
		}
	}
}
