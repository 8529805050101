// Spacing units
// ==========================================================================
$spacing-unit-xxxs: 4px;
$spacing-unit-xxs: 8px;
$spacing-unit-xs: 16px;
$spacing-unit-sm: 24px;
$spacing-unit: 32px;
$spacing-unit-lg: 40px;
$spacing-unit-xl: 48px;
$spacing-unit-xxl: 64px;
$spacing-unit-xxxl: 80px;
$spacing-unit-xxxxl: 120px;
$spacing-unit-xxxxl: 160px;

/* =======================================================================
Helpers
========================================================================== */

.h-centered {
	text-align: center;
}

.h-clearfix:before,
.h-clearfix:after {
	content: " "; /* 1 */
	display: table; /* 2 */
}

.h-clearfix:after {
	clear: both;
}

.h-clearfix {
	*zoom: 1;
}

.h-unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.h-visuallyhidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
}

.h-embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;
}

.h-embed-responsive iframe,
.h-embed-responsive embed,
.h-embed-responsive object,
.h-embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.h-embed-responsive-16by9 {
	padding-bottom: 56.25%;
}

.h-embed-responsive-4by3 {
	padding-bottom: 75%;
}

// Utility classes to put specific spacing values onto elements.
// The below loop will generate us a suite of classes like:
//
//   .u-margin-top {}
//   .u-padding-left-lg {}
//   .u-margin-right-auto {}
//   .u-padding-right-none {}

$spacing-directions: (
	"-top": "-top",
	"-right": "-right",
	"-bottom": "-bottom",
	"-left": "-left",
);

$spacing-properties: (
	"padding": "padding",
	"margin": "margin",
);

$spacing-sizes: (
	"-xxxs": $spacing-unit-xxxs,
	"-xxs": $spacing-unit-xxs,
	"-xs": $spacing-unit-xs,
	"-sm": $spacing-unit-sm,
	null: $spacing-unit,
	"-lg": $spacing-unit-lg,
	"-xl": $spacing-unit-xl,
	"-xxl": $spacing-unit-xxl,
	"-xxxl": $spacing-unit-xxxl,
	"-none": 0,
	"-auto": auto,
);

@mixin responsive-breakpoints {
	@if (variable-exists(responsive-breakpoints)) {
		@each $breakpoint-name, $breakpoint-value in $responsive-breakpoints {
			$breakpoint-name: $breakpoint-name !global;
			$breakpoint-value: $breakpoint-value !global;
			@media (max-width: #{$breakpoint-value}) {
				@content;
			}
		}
	}
}

$breakpoint-separator: \@;

$gap-sizes: (
	"-xxs": $spacing-unit-xxs,
	"-xs": $spacing-unit-xs,
	"-sm": $spacing-unit-sm,
	null: $spacing-unit,
	"-lg": $spacing-unit-lg,
	"-xl": $spacing-unit-xl,
	"-xxl": $spacing-unit-xxl,
	"-xxxl": $spacing-unit-xxxl,
);

$grid-size: 8;

$from-desktop-l: 2560px;
$from-desktop: 1920px;
$from-laptop-l: 1440px;
$from-laptop-m: 1200px;
$from-laptop: 1000px;
$from-tablet: 700px;
$from-mobile-l: 425px;
$from-mobile-m: 375px;

$to-desktop: $from-desktop + 1; //1921px
$to-laptop-l: $from-laptop-l + 1; //1441px
$to-laptop-m: $from-laptop-m + 1; //1201px
$to-laptop: $from-laptop + 1; //1001px
$to-tablet: $from-tablet + 1; //701px
$to-mobile-l: $from-mobile-l + 1; //426px
$to-mobile-m: $from-mobile-m + 1; //376px

// Core media query breakpoints used for generating responsive classes
$responsive-breakpoints: (
	desktop: $from-laptop-l,
	laptop: $from-laptop,
	tablet: $from-tablet,
	mobile: $from-mobile-l,
);

.o-grid {
	display: grid;

	@for $i from 1 through $grid-size {
		&.-cols-#{$i} {
			grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
		}

		&.-rows-#{$i} {
			grid-template-rows: repeat(#{$i}, minmax(0, 1fr));
		}
	}

	@include responsive-breakpoints {
		@for $i from 1 through $grid-size {
			&.-cols-#{$i}#{$breakpoint-separator}#{$breakpoint-name} {
				grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
			}
			&.-rows-#{$i}#{$breakpoint-separator}#{$breakpoint-name} {
				grid-template-rows: repeat(#{$i}, minmax(0, 1fr));
			}
		}
	}

	@each $suffix, $gap-size in $gap-sizes {
		&.-gap#{$suffix} {
			gap: $gap-size;
		}

		&.-gap-y#{$suffix} {
			row-gap: $gap-size;
		}

		&.-gap-x#{$suffix} {
			column-gap: $gap-size;
		}
	}

	@include responsive-breakpoints {
		@each $suffix, $gap-size in $gap-sizes {
			&.-gap#{$suffix}#{$breakpoint-separator}#{$breakpoint-name} {
				gap: $gap-size;
			}

			&.-gap-y#{$suffix}#{$breakpoint-separator}#{$breakpoint-name} {
				row-gap: $gap-size;
			}

			&.-gap-x#{$suffix}#{$breakpoint-separator}#{$breakpoint-name} {
				column-gap: $gap-size;
			}
		}
	}
}
