.c-complex-header {
	background-color: $blue;
}

.c-complex-header_content {
	display: flex;
	justify-content: space-between;

	@media (max-width: 1000px) {
		flex-direction: column;
		gap: 24px;
	}
}

.c-complex-header_text {
	width: 40%;

	@media (max-width: 1000px) {
		width: 100%;
	}

	&.-center {
		width: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
	}
}

.c-complex-header_media {
	position: relative;
	display: flex;
	flex: 1;
	justify-content: flex-end;
	width: 40%;
	transform: translateX(115px);

	@media (max-width: 1000px) {
		justify-content: center;
		width: 100%;
		transform: unset;
	}

	img {
		width: 600px;
		height: 600px;
		border-radius: 100%;

		@media (max-width: 1000px) {
			width: 400px;
			height: 400px;
		}
	}
}

.c-complex-header_play {
	position: absolute;
	top: 275px;
	right: 275px;
	cursor: pointer;

	@media (max-width: 1000px) {
		top: 175px;
		right: 0;
		left: 0;
		display: flex;
		justify-content: center;
		margin: auto;
	}

	&::after {
		content: url("../images/svg/play.svg");
	}
}

.c-complex-header_cards {
	display: flex;
	gap: 24px;
	margin-top: 160px;

	@media (max-width: 1000px) {
		flex-wrap: wrap;
		gap: 72px 24px;
		margin-top: 100px;
	}
}

.c-complex-header_card {
	position: relative;
	width: 33.333%;
	padding: 80px 40px 40px;
	border-radius: 8px;
	background-color: $white;

	@media (max-width: 1250px) {
		padding: 80px 20px 40px;
	}

	@media (max-width: 1000px) {
		width: 48%;
	}

	@media (max-width: 768px) {
		width: 100%;
	}

	h3 {
		margin-bottom: 10px;
		text-transform: uppercase;
		font-size: 48px;
		font-weight: 900;

		@media (max-width: 1250px) {
			font-size: 38px;
		}
	}
}

.c-complex-header_count {
	position: absolute;
	top: -48px;
	left: 40px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 96px;
	height: 96px;
	border-radius: 48px;
	background-color: $red;

	@media (max-width: 1250px) {
		left: 20px;
	}

	h4 {
		color: $white;
	}
}

.subtitle {
	margin-bottom: 20px;
	color: $blue;
	font-size: 18px;
	font-weight: 400;
}

.desc {
	color: $grey-2;
	font-size: 18px;
	font-weight: 400;
}
