.c-eval-form {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($grey-2, 0.9);
    z-index: 999999;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: all 200ms ease;
    padding: 40px 20px 20px;
    overflow: auto;

    &.active {
        visibility: visible;
        opacity: 1;
    }

    .wrapper {
        max-width: 800px;
        width: 100%;
        margin: auto;
        background: $grey-bg;
        padding: 50px;
        border-radius: 10px;
        position: relative;

        @media (max-width: 600px) {
            padding: 50px 40px;
        }

        @include mq(xsmall) {
            padding: 50px 30px 30px;
        }

        .close-eval-form {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background-color: $blue;
            color: white;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            position: absolute;
            top:20px;
            right:20px;
            box-shadow: 0px 0px 20px rgba(black,0.3);
            cursor: pointer;

            @include mq(xsmall) {
                top: -20px;
                right: 0;
                left: 0;
                margin: 0 auto;
            }

            i {
                font-size: 18px;
            }
        }
    }

    h2 {
        margin-bottom: 10px;

        @include mq(xsmall) {
            font-size: 2.2rem;
            line-height: 2.6rem;
            margin-bottom: 20px;
        }
    }

    p {
        margin-bottom: 40px;

        @include mq(xsmall) {
            display: none;
        }
    }

    &.c-form {
        input[type="text"],
        input[type="tel"],
        input[type="email"], {
            margin-bottom: 30px;

            @media (max-width: 600px) {
                padding: 10px 20px;
                border-radius: 5px;
            }

            @include mq(xsmall) {
                margin-bottom: 20px;
            }
        }

        textarea {
            @media (max-width: 600px) {
                padding: 10px 20px;
                border-radius: 5px;
                height: 140px;
            }
        }
    }
}