.c-latest {
	padding: 50px 0px;
	background: $grey-bg;

	.row {
		justify-content: flex-start;
		margin-right: -4%;
		margin-bottom: 35px;
	}

	.col_1x3 {
		@include mq(small) {
			width: 100%;
			margin-bottom: 25px;
		}
	}

	&__item {
		height: 100%;
		margin-bottom: 30px;
		box-shadow: 0px 0px 50px rgba($grey-3, 0.2);

		&-image {
			position: relative;
			display: block;
			height: 300px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			overflow: hidden;

			@include mq(medium) {
				height: 230px;
			}

			@include mq(small) {
				height: 300px;
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				transition: all 300ms ease;
			}

			&:hover {
				&:before {
					background: rgba($grey-3, 0.75);
				}

				.c-latest__item-plus {
					right: -75px;
					bottom: -75px;
				}
			}
		}

		&-content {
			padding: 40px 40px 20px;

			.date {
				display: block;
				margin-bottom: 8px;
				color: $grey-1;
				text-transform: uppercase;
				font-family: $helv-bold;
				font-size: 1.5rem;
				line-height: 2rem;
			}

			h4 {
				margin-bottom: 20px;
				font-size: 2.2rem;
				line-height: 2.8rem;

				a {
					color: $grey-2;

					&:hover {
						color: $grey-3;
					}
				}
			}

			.read-more {
				display: block;
				color: $grey-1;
				text-transform: uppercase;
				font-family: $helv-bold;
				font-size: 1.5rem;
				line-height: 2rem;

				&:hover {
					color: $grey-2;

					i {
						margin-left: 0;
						opacity: 1;
					}
				}

				i {
					margin-left: -10px;
					transform: rotate(-90deg);
					opacity: 0;
					color: $blue;
					transition: all 300ms ease;
					font-size: 1.8rem;
				}
			}
		}

		&-plus {
			position: absolute;
			right: -150px;
			bottom: -150px;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			width: 150px;
			height: 150px;
			transform: rotate(45deg);
			background: $red;
			transition: all 300ms ease;
			align-item: center;

			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 0;
				right: 80px;
				bottom: 0;
				left: 0;
				display: block;
				width: 18px;
				height: 2px;
				margin: auto;
				background: white;
			}

			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(135deg);
			}
		}
	}
}
