.c-bubbles-header {
	position: relative;
	background-color: $blue;
}

.c-bubbles-header_content {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 40px;
	text-align: center;

	h4 {
		color: #d0dce8;
		text-transform: uppercase;
		font-family: $helv-regular;
		font-size: 32px;
		font-weight: 700;
	}

	p {
		width: 60%;
		color: #d0dce8;

		@media (max-width: 768px) {
			width: 100%;
		}
	}
}

.c-bubbles-header_avatars {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.c-bubbles-header_avatar {
	position: absolute;

	img {
		width: 100%;
		height: 100%;
		border-radius: 100px;
		object-fit: cover;
	}

	&:nth-child(1) {
		top: 80px;
		left: 280px;
		width: 80px;
		height: 80px;

		@media (max-width: 1000px) {
			top: 40px;
			left: 160px;
		}

		@media (max-width: 768px) {
			display: none;
		}
	}

	&:nth-child(2) {
		top: 225px;
		left: -20px;
		width: 120px;
		height: 120px;

		@media (max-width: 500px) {
			display: none;
		}
	}

	&:nth-child(3) {
		bottom: 80px;
		left: 120px;
		width: 160px;
		height: 160px;

		@media (max-width: 1000px) {
			display: none;
		}
	}

	&:nth-child(4) {
		top: 80px;
		right: 120px;
		width: 160px;
		height: 160px;

		@media (max-width: 1000px) {
			display: none;
		}
	}

	&:nth-child(5) {
		right: -30px;
		bottom: 240px;
		width: 120px;
		height: 120px;

		@media (max-width: 500px) {
			display: none;
		}
	}

	&:nth-child(6) {
		right: 275px;
		bottom: 80px;
		width: 80px;
		height: 80px;

		@media (max-width: 1000px) {
			right: 160px;
			bottom: 40px;
		}

		@media (max-width: 768px) {
			display: none;
		}
	}
}
