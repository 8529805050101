.c-grid {
	position: relative;
	padding: 150px 0px 50px;
	background-color: white;

	&:before {
		content: "";
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		height: 330px;
		background: $grey-bg;
	}

	.col_1x2 {
		@include mq(small) {
			width: 100%;
		}
	}

	.pre-title {
		color: white;
	}

	&__item {
		position: relative;
		display: flex;
		flex-flow: column wrap;
		height: 100%;
		padding: 40px;
		box-shadow: 0px 0px 50px rgba($grey-3, 0.5);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		cursor: pointer;
		overflow: hidden;

		@include mq(xsmall) {
			padding: 35px 25px;
		}

		h3 {
			font-size: 3.2rem;
			line-height: 4rem;

			@include mq(xsmall) {
				font-size: 2.2rem;
				line-height: 2.8rem;
			}

			br {
				@include mq(xsmall) {
					display: none;
				}
			}
		}

		* {
			z-index: 1;
		}

		&:hover {
			.c-grid__item-plus {
				right: -75px;
				bottom: -75px;
			}
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition: all 300ms ease;
		}

		&.grey {
			justify-content: flex-end;

			&:before {
				background: rgba($grey-3, 0.5);
			}

			&:hover {
				&:before {
					background: rgba($grey-3, 0.75);
				}
			}
		}

		&.orange {
			justify-content: center;

			&:before {
				background: rgba($red, 0.9);
			}

			&:hover {
				&:before {
					background: rgba($red, 1);
				}
			}

			.pre-title {
				&:before {
					border-left: 12px solid white;
				}
			}

			.c-grid__item-plus {
				background: white;

				&:before,
				&:after {
					background: $red;
				}
			}
		}

		&.full {
			height: 700px;

			@include mq(small) {
				height: 500px;
				margin-bottom: 20px;
			}

			@include mq(xsmall) {
				height: 400px;
				margin-bottom: 20px;
			}
		}

		&.half {
			height: 340px;

			&:last-child {
				margin-top: 20px;
			}
		}

		&-plus {
			position: absolute;
			right: -150px;
			bottom: -150px;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			width: 150px;
			height: 150px;
			transform: rotate(45deg);
			background: $red;
			transition: all 300ms ease;
			align-item: center;

			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 0;
				right: 80px;
				bottom: 0;
				left: 0;
				display: block;
				width: 18px;
				height: 2px;
				margin: auto;
				background: white;
			}

			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(135deg);
			}
		}
	}
}
