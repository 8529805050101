.c-career {
	background:$grey-bg;
	padding:100px 0px 50px;

	.row {
		margin-bottom: 0;
	}

	&__links {
		padding-right:80px;

		li {

			a {
				font-family:$helv-regular;
				font-size: 2.2rem;
				line-height:3rem;
				color:$grey-1;
				padding:15px 0px;
				display:block;
				border-bottom:1px solid $grey-1;


				&:before {
					content: "\f0dd";
					display: inline-block;
					font: normal normal normal 24px/1 FontAwesome;
					text-rendering: auto;
					transform:rotate(-90deg);
					color:$blue;
					opacity: 0;
					transition:all 300ms ease;
					margin-left:-14px;
				}

				&:hover {

					&:before {
						opacity:1;
						transform:translateX(10px) rotate(-90deg);
						margin-right:30px;
						margin-left:-14px;
					}
				}
			}

			&.active {

				a {
					color:$grey-2;

					&:before {
						opacity:1;
						transform:translateX(10px) rotate(-90deg);
						margin-right:30px;
						margin-left:-14px;
					}
				}
			}

			&:last-child {

				a {
					border-bottom:0px;
				}
			}
		}
	}

	&__content {

		.tab {
			display:none;

			&.active {
				display:block;
			}
		}
	}
}