.c-products {
	padding: 120px 80px 0;
	background-color: $lightblue;

	@media (max-width: 768px) {
		padding: 60px 40px 0;
	}

	.c-products_header {
		max-width: 1200px;
		margin: auto;

		h2 {
			margin-bottom: 80px;
			color: $blue;
			text-transform: uppercase;
			font-size: 7em;
			font-family: "Helvetica-neue-condensed-black";

			@media (max-width: 768px) {
				margin-bottom: 40px;
				font-size: 4em;
			}
		}
	}
}

.c-products-listing {
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	max-width: 1200px;
	margin: auto;
	padding-bottom: 120px;
	border-bottom: 1px solid #d0dce8;

	@media (max-width: 768px) {
		padding-bottom: 60px;
	}
}

.c-products-listing_single {
	display: flex;
	flex-direction: column;
	width: 30%;

	@media (max-width: 1000px) {
		width: 48%;
	}

	@media (max-width: 768px) {
		width: 100%;
	}

	&:hover {
		.c-products_footer {
			background-color: lighten($blue, 10%);
		}
	}
}
.c-product-listings_carousel {
	.slick-track {
		display: flex !important;
	}

	.slick-slide {
		height: inherit !important;
	}

	.slick-list {
		overflow: inherit;
	}

	img {
		opacity: 1 !important;
	}
}

.c-products-listing_arrow {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	background-color: $white;
	color: $grey-3;
	cursor: pointer;
	transition: background-color 0.3s ease;

	@media (max-width: 700px) {
		width: 60px;
		height: 60px;
	}

	&:hover {
		background-color: lighten($blue, 10%);
	}

	&.-prev {
		border-radius: 8px 0 0 8px;
	}

	&.-next {
		border-radius: 0 8px 8px 0;
	}
}

.c-product-listings_slide {
	margin-right: 24px;
}
