.c-service-header {
	padding: 80px 0;
	background-color: $darkblue;
	color: $white;

	@media (max-width: 991px) {
		padding: 0px 0 40px;
	}
}

.c-service-header_inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 90px;

	@media (max-width: 991px) {
		align-items: normal;
		flex-direction: column;
	}
}

.c-service-header_heading {
	position: relative;
	max-width: 55%;

	@media (max-width: 991px) {
		max-width: inherit;
	}
}

.c-service-header_title,
.c-service-header_overline,
.c-service-header_content {
	color: $white;
}

.c-service-header_overline {
	margin-bottom: 40px;
	font-size: 32px;
	font-family: $helv-regular;
}

.c-service-header_title {
	margin-bottom: 40px;
	font-size: 96px;

	@media (max-width: 991px) {
		font-size: 68px;
	}

	@media (max-width: 767px) {
		font-size: 58px;
	}

	@media (max-width: 500px) {
		font-size: 48px;
	}
}

.c-service-header_content {
	max-width: 80%;
	padding-bottom: 40px;
	border-bottom: 1px solid rgba($white, 0.2);

	@media (max-width: 991px) {
		max-width: inherit;
	}
}

.c-service-header_medias {
	position: relative;
	flex: 1;
}

.c-service-header_media {
	position: relative;
	margin-right: -120px;
	border-radius: 50%;
	overflow: hidden;
	aspect-ratio: 1/1;

	@media (max-width: 991px) {
		margin-right: 0;
	}
}

.c-service-header_img {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
}

.c-service-header_icon {
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 128px;
	height: 128px;
	margin-top: auto;
	margin-bottom: auto;
	border-radius: 50%;
	background-color: $red;

	@media (min-width: 992px) {
		bottom: 0;
		left: -64px;
	}

	@media (max-width: 991px) {
		top: -64px;
		right: 0;
		left: 0;
		margin-right: auto;
		margin-left: auto;
	}

	img {
		max-width: 48px;
	}
}

.c-service-header_scroll {
	display: inline-flex;
	padding-top: 40px;
	color: $white;
	font-size: 18px;
	font-family: $helv-regular;

	&::before {
		content: url("../images/svg/continueScroll.svg");
		margin-right: 12px;
	}
}
