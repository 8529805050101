.c-timeline {
	.c-timeline_events {
		display: flex;
		flex-direction: column;
		gap: 80px;

		@media (max-width: 768px) {
			gap: 40px;
		}
	}

	.c-timeline_event {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transform: translate(40px);
		opacity: 0;
		transition: all 1s ease-in;

		@media (max-width: 768px) {
			flex-direction: column;
			gap: 20px;
		}

		h3 {
			width: 30%;
			text-align: center;
			font-size: 7em;

			@media (max-width: 768px) {
				width: 100%;
				margin-bottom: 0;
				font-size: 3rem;
				text-align: start;
			}

			&::before {
				content: "";
				position: absolute;
				top: 35px;
				right: 0;
				left: 0;
				width: 95px;
				height: 1px;
				margin: auto;
				transform: rotateZ(270deg);
				border-top: 1px dashed #d0dce8;

				@media (max-width: 768px) {
					display: none;
				}
			}
		}

		.c-timeline_dot-border {
			position: absolute;
			top: 74px;
			right: 0;
			left: 0;
			display: flex;
			justify-content: center;
			margin: auto;
			border-radius: 32px;
			transition: all 350ms ease-in-out;

			@media (max-width: 768px) {
				display: none;
			}
		}

		.c-timeline_dot {
			display: flex;
			border: none;
			transition: all 350ms ease-in-out;

			&::after {
				content: "";
				width: 10px;
				height: 10px;
				transform-origin: center;
				border-radius: 32px;
				background-color: $red;
				transition: all 350ms ease-in-out;
			}
		}

		.c-timeline_img {
			width: 30%;

			@media (max-width: 768px) {
				width: 100%;
			}

			img {
				width: 100%;
				border-radius: 8px;
				object-fit: cover;
			}
		}

		.c-timeline_desc {
			width: 30%;

			@media (max-width: 768px) {
				width: 100%;
			}

			h6 {
				margin: 0;
				margin-bottom: 12px;
				color: $grey-3;
				font-family: $helv-regular;
				font-size: 24px;
				font-weight: 400;
			}
		}

		&:nth-child(even) {
			flex-direction: row-reverse;

			@media (max-width: 768px) {
				flex-direction: column;
			}
		}

		&.is-active {
			.c-timeline_dot-border {
				top: 70px;

				@media (max-width: 768px) {
					display: none;
				}
			}

			.c-timeline_dot {
				border: 1px solid $grey-1;
				border-radius: 100%;

				&::after {
					content: "";
					width: 20px;
					height: 20px;
					margin: 10px;
					background-color: $red;
				}
			}
		}

		&.is-inview {
			transform: translateY(0);
			opacity: 1;
		}
	}

	.c-timeline_today {
		padding-top: 120px;

		@media (max-width: 768px) {
			padding-top: 60px;
		}

		h3 {
			margin: 0 auto 100px;
			color: #d0dce8;
			text-align: center;
			text-transform: uppercase;
			font-size: 7em;

			@media (max-width: 768px) {
				font-size: 4em;
			}
		}
	}

	.c-timeline_today-wrapper {
		display: flex;
		justify-content: center;
		gap: 24px;

		@media (max-width: 1000px) {
			flex-wrap: wrap;
			gap: 80px 24px;
		}

		.c-timeline_today-card {
			position: relative;
			display: flex;
			align-items: center;
			flex-direction: column;
			width: 33.333%;
			padding: 40px;
			padding-top: 80px;
			border: 1px solid #d0dce8;

			@media (max-width: 1000px) {
				width: 48%;
			}

			@media (max-width: 768px) {
				width: 100%;
				padding: 20px;
				padding-top: 80px;
			}

			img {
				position: absolute;
				top: -50px;
				right: 0;
				left: 0;
				margin: auto;
			}

			h6 {
				margin: 0 0 40px;
				color: $grey-3;
				text-align: center;
				font-family: $helv-regular;
				font-size: 24px;
				font-weight: 400;
			}

			p {
				color: $grey-2;
				text-align: center;
			}
		}
	}
}
