body.menu-is-open {
	overflow: hidden !important;

	.c-massive {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
		animation: slideIn 500ms forwards alternate;

		@include mq(small) {
			display: flex;
		}

		&__links {
			padding: 180px 80px;
		}

		&__contact {
			@media (max-width: 768px) {
				display: none;
			}
		}
	}

	.c-header__burger {
		position: fixed;
		top: 30px !important;
		left: 0;
		background: transparent;
		z-index: 999999;
	}
}

body.menu-is-close {
	.c-massive {
		animation: slideOut 500ms forwards alternate;
	}

	.icon {
		.bar {
			background: white;
		}
	}
}

@keyframes slideIn {
	0% {
	}

	5% {
		transform: translateY(-100%);
		box-shadow: 0px 0px 30px rgba($grey-3, 0.3);
		z-index: 0;
	}

	100% {
		transform: translateY(0%);
		box-shadow: 0px 0px 30px rgba($grey-3, 0.2);
		z-index: 99999;
	}
}
@keyframes slideOut {
	0% {
		transform: translateY(0%);
		box-shadow: 0px 0px 30px rgba($grey-3, 0.2);
		z-index: 99999;
	}

	95% {
		transform: translateY(-100%);
		box-shadow: 0px 0px 30px rgba($grey-3, 0.3);
		z-index: -1;
	}

	100% {
		left: 0px;
		transform: translateY(0%);
		box-shadow: none;
		z-index: -1;
	}
}

.c-massive {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;

	@media (max-width: 768px) {
		flex-direction: column;
	}

	.section-name {
		margin-bottom: 12px;
		opacity: 0.5;
		color: $white;
		list-style-type: none;
		font-family: $helv-bold;
		font-size: 32px;
	}

	&__links {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		gap: 40px;
		width: 50%;
		padding: 96px 80px;
		background: $blue;

		@media (max-width: 768px) {
			width: 100%;
			padding: 40px;
		}

		.row {
			@media (max-width: 768px) {
				gap: 40px;
			}
		}

		ul {
			margin: 0;
			list-style-type: none;

			li {
				margin-bottom: 8px;

				a {
					color: $white;
					font-size: 18px;

					&:hover {
						opacity: 0.5;
					}
				}
			}
		}
	}

	&__contact {
		position: relative;
		position: relative;
		display: flex;
		flex-direction: column;
		width: 50%;
		padding: 96px 80px;
		border-top: 1px solid #d0dce8;
		background: $white;
		overflow: hidden;

		@media (max-width: 768px) {
			width: 100%;
			padding: 40px;
		}

		&::after {
			content: "";
			position: absolute;
			right: -185px;
			bottom: -150px;
			width: 340px;
			height: 400px;
			transform: rotate(38deg);
			background-color: $red;

			@media (max-width: 767px) {
				width: 200px;
			}
		}

		.section-name {
			margin-bottom: 40px;
			color: $grey-1;
			font-size: 32px;
		}

		h4 {
			margin-bottom: 24px;
			color: $grey-2;
			font-family: $helv-regular;
			font-size: 24px;
			font-weight: 400;
		}

		.c-massive__contact-address {
			color: $grey-2;
			font-family: $helv-regular;
			font-size: 18px;
		}

		.c-massive__contact-numbers {
			display: flex;
			flex-direction: column;
			gap: 8px;
			margin-top: 24px;
			margin-bottom: 40px;
			font-size: 18px;

			a {
				width: fit-content;
				color: $grey-2;
				transition: color 150ms ease-in-out;
				font-family: $helv-regular;
				font-size: 18px;

				&:hover {
					color: $red;
				}
			}

			strong {
				color: $red;
			}
		}

		.c-massive__contact-emergency {
			display: flex;
			flex-direction: column;
			gap: 24px;
			color: $grey-2;
			font-family: $helv-regular;
			font-size: 24px;

			a {
				width: fit-content;
				color: $grey-2;
				transition: color 150ms ease-in-out;
				font-family: $helv-regular;
				font-size: 18px;

				&:hover {
					color: $red;
				}
			}

			strong {
				color: $red;
			}
		}

		.c-massive__contact-socials {
			display: flex;
			gap: 16px;
		}

		.c-massive__contact-social {
			width: fit-content;
			margin-top: 40px;
			padding: 32px;
			border: 1px solid #d0dce8;
			border-radius: 8px;
			transition: all 150ms ease-in-out;

			&:hover {
				border: 1px solid $grey-3;
				background-color: $grey-3;

				&::after {
					filter: grayscale(1) invert(1);
				}
			}

			&::after {
				content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3602_635)'%3E%3Cpath d='M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z' fill='%23000000'/%3E%3Cpath d='M16.6711 15.4688L17.2031 12H13.875V9.75C13.875 8.80102 14.34 7.875 15.8306 7.875H17.3438V4.92188C17.3438 4.92188 15.9705 4.6875 14.6576 4.6875C11.9166 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4688H10.125V23.8542C11.3674 24.0486 12.6326 24.0486 13.875 23.8542V15.4688H16.6711Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3602_635'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E ");
			}

			&.-instagram {
				&::after {
					content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' class='bi bi-instagram' viewBox='0 0 16 16'%3E%3Cpath d='M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z'%3E%3C/path%3E%3C/svg%3E%0A");
				}
			}
		}
	}

	.col_1x2 {
		display: flex;
		flex-flow: column nowrap;

		@include mq(small) {
			width: 100% !important;
		}
	}

	.button-wrapper {
		display: flex;
		align-items: flex-start;
		flex-flow: column wrap;
		justify-content: flex-end;

		@include mq(small) {
			align-items: center;
		}

		a {
			flex-shrink: 0;
		}
	}

	&__nav {
		margin: 0;
		margin-bottom: 30px;
		list-style-type: none;

		li,
		li a {
			font-family: $helv-bold;
		}

		&.standard {
			li {
				margin-bottom: 8px;

				a {
					display: inline-flex;
					align-items: center;
					flex-flow: row wrap;
					color: $grey-1;
					font-size: 1.9rem;
					line-height: 1.3rem;

					@media screen and (max-width: 1200px) {
						font-size: 2.2rem;
						line-height: 2.8rem;
					}

					&:after {
						content: "\f0dd";
						display: block;
						width: 0;
						height: 0;
						margin-bottom: -12px;
						transform: rotate(-90deg);
						opacity: 0;
						transition: all 300ms ease;
						font-family: fontAwesome;
					}

					&:hover {
						color: $grey-2;

						&:after {
							margin-left: 8px;
							opacity: 1;
						}
					}
				}
			}
		}

		&.large {
			padding-top: 20px;

			@include mq(small) {
				display: flex;
				flex-flow: row wrap;
				text-align: center;
			}

			li {
				width: 100%;
				margin-bottom: 25px;

				@include mq(medium) {
					width: 50%;
				}

				@media (max-width: 767px) {
					width: 100%;
					margin-bottom: 15px;
				}

				&:not(.always-visible) {
					display: none;

					@include mq(small) {
						display: block;
					}
				}

				a {
					display: inline-flex;
					align-items: center;
					flex-flow: row wrap;
					color: $grey-1;
					font-size: 2.5rem;
					line-height: 1.2rem;

					@include mq(small) {
						font-size: 2.2rem;
						line-height: 2.6rem;
					}

					&:hover {
						color: $grey-2;
					}
				}
			}
		}
	}
}

.c-massive__copyright {
	display: flex;
	align-items: center;
	padding: 40px 80px;

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 40px;
		padding: 20px 40px;
	}

	.c-massive_left,
	.c-massive_right {
		width: 50%;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	.c-massive_left {
		display: flex;
		border-right: 1px solid #d0dce8;

		p {
			gap: 40px;
			width: 50%;
			margin: 0;
			font-size: 14px;
		}

		a {
			color: $grey-1;
			
			&:hover {
				color: $grey-2;
			} 
		}
	}

	.c-massive_right {
		display: flex;
		padding-left: 80px;
		@media (max-width: 768px) {
			padding-left: 0px;
		}

		a {
			gap: 40px;
			width: 50%;
			color: $grey-2;
			font-family: $helv-bold;
			font-size: 14px;

			&:hover {
				color: $red;
			} 
		}

		.backToTop {
			cursor: pointer;
		}
	}
}
