.c-caroussel {
	background-color: $lightblue;
}

.c-caroussel_header {
	position: relative;
	margin-bottom: 92px;
}

.c-caroussel_slider {
	.slick-arrow {
		position: absolute;
		top: -165px;
		right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		height: 80px;
		background-color: $white;
		cursor: pointer;
		transition: all 150ms ease-in-out;

		@media (max-width: 768px) {
			top: -145px;
			width: 60px;
			height: 60px;
		}

		@media (max-width: 360px) {
			top: -80px;
			border-radius: 8px !important;
		}

		&:hover {
			background-color: $blue;
		}

		&::after {
			content: url("../images/svg/arrow_forward_black.svg");
		}
	}

	.prevArrow {
		right: 90px;
		border-right: 1px solid #d0dce8;
		border-radius: 8px 0 0 8px;

		@media (max-width: 768px) {
			right: 70px;
		}

		@media (max-width: 360px) {
			right: 240px;
			border-right: none;
		}
	}

	.nextArrow {
		border-radius: 0 8px 8px 0;

		&::after {
			margin-bottom: 4px;
			transform: scale(-1);
		}
	}
}

.c-caroussel_card {
	margin-right: 24px;
	border-radius: 8px;
	background-color: $white;

	img {
		width: 100%;
		max-height: 400px;
		border-radius: 8px;
		object-fit: cover;
	}
}

.c-caroussel_content {
	padding: 40px;

	h5 {
		margin: 0;
		margin-bottom: 20px;
		color: $grey-3;
		font-family: $helv-regular;
		font-size: 32px;
		font-weight: 400;
	}
}
