.c-blog-listing {
	background-color: $grey-bg;
	padding: 100px 0px 0px;

	& + .c-latest {
		padding: 100px 0px;
	}

	&__last-post {
		height: 600px;
		padding: 60px 100px;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		align-items: flex-start;
		position: relative;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		@include mq(small) {
			padding: 60px 40px;
			height: 500px;
		}

		@include mq(xsmall) {
			padding: 20px 20px;
			height: 400px;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 9;
			background: linear-gradient(45deg, rgba($grey-2, 1) 25%, rgba(0, 0, 0, 0) 100%);
		}

		& > * {
			position: relative;
			z-index: 10;
		}

		.date {
			font-family: $helv-bold;
			font-size: 1.5rem;
			line-height: 2rem;
			color: white;
			text-transform: uppercase;
		}

		h3 {
			max-width: 500px;
			margin: 10px 0px;

			@media (max-width: 700px) {
				font-size: 2rem;
			}
		}
	}
}

.c-latest__item-content h4 {
	min-height: 9rem;
}

.js-blog-slider {
	.slick-list {
		box-sizing: initial !important;
	}

	&__arrows-container {
		position: relative;
	}

	&-prev,
	&-next {
		position: absolute;
		cursor: pointer;
		font-family: $helv-bold;
		font-size: 1.6rem;
		line-height: 2.4rem;
		color: white;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		top: 10px;

		i {
			color: $blue;
			font-size: 2.5rem;
			margin-left: 10px;
		}
	}

	&-prev {
		left: 0;
	}
	&-next {
		right: 0;
	}
}

.slider-container-large {
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
}

.blog-item__container {
	// margin:30px 40px 0 40px;
	margin-bottom: 40px;
}
