.c-contact {

	.row {
		margin-right:0;
	}
	
	strong {
		color: $red;
	}
	
	.col_2x3,
	.col_1x3 {

		@media screen and (max-width:1050px) {
			width:100%;
		}
	}

	.col_1x3 {
		@media screen and (max-width:1150px) {
			display: none;
		}

		
	} 

	.col_1x1.right {

		@media screen and (max-width:1050px) {
			text-align: center;
		}
	}

	a {
		display: block;
		margin-bottom:6px;
		font-family: $helv-regular;
		font-weight:400;
		font-size:2rem;
		line-height: 2.4rem;
		color:$grey-1;
	}
  button[type='submit']{
    cursor: pointer;
    border-color: transparent;
  }
}