.woocommerce {
  font-family: $helv-regular;
  font-size: 16px;
  color: $grey-1;

  a,
  button {
    color: $blue;

    &.button.alt {
      background: $blue;
      color: white;
      font-family: $helv-regular;
      text-transform: uppercase;
      margin-top: 20px;
      font-size: 1.5rem;
      letter-spacing: 1px;
      font-weight: 300;

      &:hover {
        background-color: $blue;
        color: white;
      }
    }
  }

  h3 {
    font-family: $helv-regular;

    &#order_review_heading {
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
}

.woocommerce-MyAccount-navigation {

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;

    li {
      margin-right: 15px;
      margin-bottom: 10px;
    }
  }
}

.woocommerce-billing-fields,
.woocommerce-account-fields,
.woocommerce-additional-fields,
.woocommerce-shipping-fields,
.woocommerce-EditAccountForm {

  h3 {
    font-family: $helv-regular;
  }

  p.form-row {
    width: 100%;
  }
  label {
    display: block;
    margin-bottom: 0px;
  }
  .woocommerce-input-wrapper {
    width: 100%;
  }

  input {
    height: 40px;
    border: 1px solid $grey-1;
    padding: 10px;
    font-family: $helv-regular;
  }
  
  .form-row {
    textarea.input-text {
      border: 1px solid $grey-1;
      padding: 10px;
      height: 150px;
      font-family: $helv-regular;
    }
  }

  .woocommerce-Button.button {
    background: $blue;
    color: white;
    font-family: $helv-regular;
    text-transform: uppercase;
    margin-top: 20px;
    font-size: 1.5rem;
    letter-spacing: 1px;
    font-weight: 300;

    &:hover {
      background-color: $blue;
      color: white;
    }
  }
}

.woocommerce-message {
  font-family: $helv-regular;
  font-size: 16px;
  color: $grey-1;
  border-top-color: $blue;

  &:before {
    color: $blue;
  }

  a {
    color: $blue;
  }
}

.woocommerce-shipping-calculator {
  display: none;
}