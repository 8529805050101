.c-avantages {
	background-color:$grey-border;
	background-size:contain;
	background-position:right center;
	background-repeat:no-repeat;
	position:relative;

	@include mq(small) {
		background-image: none !important;
	}

	&:before {
		content:'';
		background:linear-gradient(to right, rgba(240,240,240,1) 0%,rgba(240,240,240,0) 100%);
		position:absolute;
		left:0;
		top:0;
		bottom:0;
		width:100%;
	}

	@include mq(medium) {
		padding-top: 60px;
		background-size:cover;
	}

	@include mq(xsmall) {
		background-position:right center;
	}

	& + .gap-filler {
		height:200px;
		background:$grey-bg;
	}

	.container {
		display:flex;
		flex-flow:row wrap;
		align-items:center;
		min-height:600px;
		position:relative;

		.row {
			width:100%;
		}
	}

	.col_2x5 {

		@media screen and (max-width:1100px) {
			width:100%;
			max-width:500px;
		}
	}

	&__boxes {
		position:absolute;
		right:40px;
		bottom:0;
		display:flex;
		flex-flow:row wrap;
		justify-content: space-between;;
		width:60%;
		transform:translateY(50%);

		@include mq(medium) {
			position:initial;
			right:initial;
			left:initial;
			bottom:initial;
			width:100%;
			transform:translateY(30%);
		}

		@include mq(xsmall) {
			transform:translateY(20%);

		}

		.c-red-box {
			width:48%;

			@include mq(xsmall) {
				width:100%;
				margin-bottom:10px;
				padding:40px;
			}
		}
	}
}