.c-video {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	aspect-ratio: 16/9;
}

.c-video_video {
	position: absolute;
	inset: 0;
	display: none;
	width: 100%;
	height: 100%;
	pointer-events: none;

	.is-init & {
		display: block;
		pointer-events: all;
	}
}

.c-video_placeholder,
.c-video_thumbnail {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	transition: opacity 0.2s ease;
	pointer-events: none;
	pointer-events: none;

	.is-init & {
		opacity: 0;
	}
}

.c-video_play {
	position: absolute;
	inset: 0;
	width: 48px;
	height: 48px;
	margin: auto;
	cursor: pointer;

	&:before {
		content: url("../images/svg/play.svg");
	}

	.c-video.is-init & {
		transform: translate3d(-50%, -50%, 0) scale(0);
		opacity: 0;
		pointer-events: none;
	}

	.c-video.has-replay.is-ended & {
		transform: translate3d(-50%, -50%, 0) scale(1);
		opacity: 1;
		pointer-events: all;
	}
}
