.c-logo-header {
	.c-logo-header_content {
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;

		img {
			width: 130px;
			height: 65px;
			margin-bottom: 80px;

			@media (max-width: 768px) {
				margin-bottom: 40px;
			}
		}

		h4 {
			text-transform: uppercase;
		}

		p {
			margin-top: 80px;

			@media (max-width: 768px) {
				margin-top: 40px;
			}
		}
	}
}
