.c-home-banner {
	height:calc(100% - 75px);
	display:flex;
	flex-flow:row nowrap;
	justify-content:space-between;
	position:relative;
	box-shadow:0px 0px 50px rgba($grey-3, 0.1);
	z-index: 10;

	@media (max-width: 768px) {
		height: auto;
	}

}