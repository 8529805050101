.c-lightbox {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 0;
	height: 0;
	opacity: 0;
	background-color: fade-out($color: #000, $amount: 0.1);
	overflow: hidden;
	overflow: auto;
	pointer-events: none;
	z-index: 50;

	&.is-active {
		width: 100%;
		height: 100%;
		opacity: 1;
		pointer-events: all;
	}
}

.c-lightbox_clear {
	position: absolute;
	top: 16px;
	right: 16px;
	padding: 16px;
	cursor: pointer;

	&::after {
		content: url("../images/svg/close.svg");
	}

	&:hover,
	&:focus {
		transform: rotate(90deg);
	}
}

.c-lightbox_inner {
	display: flex;
	align-items: center;
	min-height: 100%;
	padding-top: 80px;
	padding-bottom: 80px;

	&.-stretch {
		align-items: stretch;
	}
}

.c-lightbox_video {
	width: 100%;
	height: auto;
}
