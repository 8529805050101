.c-slider {
	flex-grow:1;
	display:flex;
	flex-flow:row wrap;
	justify-content:space-between;
	position:relative;
	overflow:hidden;
	background:white;

	@include mq(medium) {
		flex-flow:column nowrap;
	}

	@include mq(small) {
		justify-content:flex-start;
	}

	.slick-slide{

		&:focus {
			outline:0;
		}
	}

	h2 {
		text-transform:uppercase;
		font-family:$helv-black;
		font-weight:800;
		color:$grey-2;
		font-size:7rem;
		line-height:7rem;

		@media screen and (max-width:1250px) {
			font-size:5rem;
			line-height:5.5rem;
		}

		@include mq(xsmall) {
			font-size:3.5rem;
			line-height:4rem;
		}
	}

	p.medium {
		@media screen and (max-width:1250px) {
			font-size:2rem;
			line-height:2.5rem;
		}

		@include mq(xsmall) {
			font-size:1.6rem;
			line-height:2.4rem;
		}
	}

	&--image,
	&--content {
		width:50%;
		height:100%;
	}

	&--content {
		padding:40px;
		overflow:hidden;
		background:white;

		@include mq(medium) {
			width:100%;
			height:60%;
		}
		
		@media (max-width: 768px) {
			height:auto;
			margin-top:40px;
			padding-bottom:60px;
		}

		.slick-list {
		display: flex;
	    flex-flow: column wrap;
	    justify-content: center;
	    align-items: center;
	    height: 100%;
	    max-width:500px;
	    margin:0 auto;
	    overflow:visible;
		}

	}

	&--image {
		position:relative;

		@include mq(medium) {
			order:-1;
			width:100%;
			height:40%;
		}

		@media (max-width: 768px) {
			height: 360px;
		}

		@include mq(small) {
			height: 320px;
		}

		&:before {
			content:'';
			display:block;
			width:22px * 4;
			height:27px * 4;
			position:absolute;
			bottom:0;
			right:0;
			background-image:url('../images/svg/sliced-rectangle.svg');
			background-size:100%;
			background-repeat:no-repeat;
			z-index: 9999;

			@include mq(medium) {
				display:none;
			}
		}

		&__slide {
			height:100%;
			background-size:cover;
			background-position:center;
			background-repeat:no-repeat;
		}

		.slick-track,
		.slick-list {
			height:100%;
		}
	}

	&--nav {
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		margin:auto;
		width:50px;
		height:200px;
		background:white;
		border-radius:50px;
		display:flex;
		flex-flow:column wrap;
		justify-content: center;
		align-items:center;
		z-index: 9999;

		@include mq(medium) {
			transform:rotate(-90deg);
			top:-20%;
		}

		@include mq(small) {
			top:225px;
			bottom:initial;
		}

		ul {
			list-style-type: none;
			margin:0;
		}

		li {
			margin:8px 0px;
			position:relative;

			&:before {
				content:'';
				display:block;
				width:0px;
				height:0px;
				border-top:5px solid transparent;
				border-right:5px solid $blue;;
				border-bottom:5px solid transparent;
				border-left:5px solid transparent;
				background:transparent;
				position:absolute;
				left:-20px;
				top:0px;
				bottom:0px;
				margin:auto 0;
				opacity:0;
				transition:all 300ms ease;
			}

			&.slick-active {

				&:before {
					opacity:1;
					left:-30px;
				}
			}

			button {
				border:0px;
				background: $blue;
				font-size: 18px;
				color: white;
				border-radius: 50%;
				padding: 6px 10px 4px;
				// font-size:0px;
				// width:30px;
				// height:30px;
				// padding:0px;
				// display:block;
				// background-position:center;
				// background-repeat:no-repeat;
				// background-color:transparent;
				// cursor: pointer;

				@include mq(medium) {
					transform:rotate(90deg);
				}

				&:focus {
					outline:0;
				}

			}

			// &:nth-of-type(1) {

			// 	button {
			// 		background-image: url('../images/svg/icon-climatisation.svg');
			// 		background-size:22px;
			// 	}
			// }

			// &:nth-of-type(2) {

			// 	button {
			// 		background-image: url('../images/svg/icon-chauffage.svg');
			// 		background-size:20px
			// 	}
			// }

			// &:nth-of-type(3) {

			// 	button {
			// 		background-image: url('../images/svg/icon-ventilation.svg');
			// 		background-size:25px;
			// 	}
			// }

			// &:nth-of-type(4) {

			// 	button {
			// 		background-image: url('../images/svg/icon-geothermie.svg');
			// 		background-size:30px;
			// 	}
			// }
		}
	}

	// &--scroll {
	//     font-size: 1.6rem;
	// 	position:absolute;
	// 	bottom: 0;
	//     right: 0;
	//     left: -60px;
	// 	transform:rotate(90deg);
	//     margin: 0 auto;
	//     width: 350px;
	//     z-index: 9999;
	//     color:$grey-1;
	//     display:flex;
	//     flex-flow:row wrap;
	//     align-items:center;

	//     @include mq(medium) {
	//     	display:none;
	//     }

	//     &:hover {

	//     	i {
	//     		margin-left:20px;
	//     	}
	//     }

	//     i {
	//     	margin-left:10px;
	//     	font-size:2.5rem;
	//     	color:$orange;
	//     	margin-bottom: -2px;
	//     	transition:all 300ms ease;
	//     }
	// }
}