.c-testimonies {
	.c-testimonies_slider {
		position: relative;

		.slick-arrow {
			position: absolute;
			right: 40px;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
			border: 1px solid #d0dce8;
			border-radius: 50px;
			background-color: $white;
			cursor: pointer;
			z-index: 5;

			@media (max-width: 768px) {
				right: 0;
				bottom: 165px;
			}

			@media (max-width: 660px) {
				bottom: 190px;
			}

			@media (max-width: 470px) {
				bottom: 210px;
			}

			@media (max-width: 360px) {
				bottom: 235px;
			}

			&::after {
				content: url("../images/svg/slider-arrow.svg");
			}
		}

		.prevArrow {
			right: 110px;

			@media (max-width: 768px) {
				display: none !important;
			}
		}

		.nextArrow {
			transform: scaleX(-1);

			@media (max-width: 768px) {
				display: none !important;
			}
		}

		.slick-dots {
			position: absolute;
			right: 0;
			bottom: 0px;
			left: 0;
			display: flex !important;
			justify-content: center;
			gap: 8px;
			margin: auto;
			padding-left: 110px;
			cursor: pointer;

			@media (max-width: 768px) {
				position: static;
				margin: 0;
				padding: 0;
				margin-top: 16px;
			}

			li {
				list-style-type: none;

				.slider-dot-wrapper {
					padding: 6px;
				}

				.slider-dot {
					width: 12px;
					height: 12px;
					border: none;
					border-radius: 50px;
					background-color: #d0dce8;
					list-style-type: none;
				}
			}

			.slick-active {
				.slider-dot-wrapper {
					border: 1px solid #d0dce8;
					border-radius: 50px;
				}
				.slider-dot {
					background-color: $red;
				}
			}
		}

		@media (max-width: 768px) {
			display: flex !important;
			flex-direction: column;
		}
	}

	.c-testimonies_single {
		display: flex;
		gap: 64px;

		@media (max-width: 991px) {
			flex-direction: column;
			gap: 32px;
		}
	}
	.c-testimonies_media {
		width: 50%;

		@media (max-width: 991px) {
			width: 100%;
		}

		img {
			width: 100%;
			height: 100%;
			border-radius: 8px;
			object-fit: cover;
		}
	}
	.c-testimonies_content {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 48px;
		width: 50%;

		@media (max-width: 768px) {
			gap: 24px;
			width: 100%;
		}

		h3 {
			text-transform: uppercase;
		}

		p {
			margin-bottom: 0;

			span {
				color: $red;
			}
		}
	}

	.c-testimonies_footer {
		padding-bottom: 48px;
		border-bottom: 1px solid #d0dce8;

		@media (max-width: 768px) {
			padding-bottom: 24px;
		}

		p {
			margin-bottom: 0;
		}
	}

	.c-testimonies_name {
		font-family: $helv-regular;
		font-size: 18px;
		font-weight: 400;
		line-height: 25px;
		letter-spacing: 0em;
	}

	.c-testimonies_poste {
		font-family: $helv-regular;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0em;
	}

	&.no-dots {
		.slick-dots {
			display: none;
		}
	}
}
