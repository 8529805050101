.c-single-product {
	display: flex;

	@media (max-width: 1000px) {
		flex-direction: column;
	}
}

.c-single-product_info {
	padding: 80px;
	background-color: $darkblue;
	color: $white;

	@media (max-width: 1000px) {
		padding: 40px;
	}

	@media (max-width: 700px) {
		padding: 40px 16px;
	}
}

.c-single-product_heading {
	margin: 40px 0;
	padding: 40px 0;
	border-top: 1px solid rgba($white, 0.2);
	border-bottom: 1px solid rgba($white, 0.2);
}

.c-single-product_back {
	display: inline-flex;
	align-items: center;
	gap: 10px;
	color: $white;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 20px;

	i {
		color: $red;
	}
}

.c-single-product_categories {
	margin-bottom: 24px;
	text-transform: uppercase;
	font-family: $helv-bold;
	font-size: 24px;
}

.c-single-product_title {
	margin-bottom: 24px;
	color: $white;
	font-size: 64px;
	line-height: 1;

	@media (max-width: 1000px) {
		font-size: 48px;
	}
}

.c-single-product_spec {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
	color: $white;
	font-size: 24px;

	@media (max-width: 700px) {
		flex-direction: column;
	}

	span {
		&:first-child {
			text-transform: uppercase;
			font-family: $helv-bold;
		}
	}

	&.-small {
		font-size: 18px;
		span {
			&:first-child {
				text-transform: inherit;
				font-family: $helv-bold;
			}
		}
	}
}

.c-single-product_list {
	margin-bottom: 32px;
}

.c-single-product_info,
.c-single-product_medias {
	flex: 1;
}

.c-single-product_medias {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid $border-100;
	background-color: $white;

	@media (max-width: 1000px) {
		display: none;
		aspect-ratio: 1/0.6;
	}

	&.-mobile {
		display: none;

		@media (max-width: 1000px) {
			display: flex;
			margin-bottom: 40px;
			aspect-ratio: 1/0.6;
		}

		@media (max-width: 768px) {
			aspect-ratio: 1/1;
		}
	}
}

.c-single-product_media {
	position: relative;
	flex: 1;

	img {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		padding: 40px;
		object-fit: contain;

		@media (max-width: 768px) {
			padding: 20px;
		}
	}
}

.c-single-product_brand {
	position: relative;
	border-top: 1px solid $border-100;
	text-align: center;

	img {
		max-height: 100px;
		margin: 0 auto;
		padding: 20px;
	}
}

.c-single-product_tag-img {
	filter: brightness(0) invert(1);
}

.c-single-product_file {
	padding: 24px;
	border: 1px solid $border-100;
	border-radius: 8px;

	@media (max-width: 700px) {
		padding: 16px;
	}
}

.c-single-product_file-title {
	display: flex;
	align-items: center;
	gap: 24px;
}

.c-single-product_content-title {
	margin-bottom: 24px;
	color: $darkblue;
	text-transform: uppercase;
	font-family: $helv-regular;
	font-size: 48px;
	line-height: 1;

	@media (max-width: 700px) {
		font-size: 40px;
	}
}

.c-single-product_content {
	max-width: 1062px;
	margin: 120px auto 0;

	@media (max-width: 700px) {
		margin: 40px auto 0;
	}
}

.c-single-product_icon {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	justify-content: center;
	width: 64px;
	height: 64px;
	border-radius: 50%;
	background-color: $darkblue;

	.-red & {
		background-color: $red-700;
	}

	.-green & {
		background-color: $green-700;
	}
}

.c-single-produt_download {
	display: flex;
	justify-content: end;
}

.c-single-product_action {
	display: flex;
	align-items: center;
	gap: 40px;
	margin-bottom: 24px;
	padding: 40px;
	border-radius: 16px;

	@media (max-width: 700px) {
		align-items: baseline;
		flex-direction: column;
		gap: 16px;
		padding: 16px;
	}

	h4,
	p {
		color: $white;
	}

	h4 {
		margin-bottom: 8px;
		text-transform: uppercase;
	}

	&.-red {
		background-color: $red;
	}

	&.-green {
		background-color: $green-2;
	}
}

.c-single-product_details {
	margin-bottom: 40px;
}

.c-single-product_desc {
	margin-bottom: 40px;
}

.c-single-product_tags {
	display: flex;
	justify-content: space-between;
}

.c-single-product_cats {
	display: flex;
	gap: 8px;
}
