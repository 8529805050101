.c-footer {
	display:none;
	height:100%;
	pointer-events:none;

	@include mq(small) {
		pointer-events:initial;
		height:auto;
    display: block;
	}

	&__mobile {
		display:none;
		flex-flow:column nowrap;
		align-items:center;
		padding:60px 40px;
		text-align:center;
		background:$grey-bg;
		position:relative;

		@include mq(small) {
			display:flex;
		}

		.mobile-footer-corner {
			position:absolute;
			bottom:0;
			right:0;
			width:100px;
			backface-visibility: hidden;
		}


		.mobile-logo {
			margin-bottom:40px;
		}

		h3 {
			font-family:$helv-bold;
			font-size:3.5rem;
			line-height:4rem;
			margin-bottom:5px;
		}

		h4 {
			font-family:$helv-bold;
			font-size:2rem;
			line-height:2.5rem;
		}

		p {
			font-size:2rem;
			line-height:2.5rem;
		}

		a:not(.c-button) {
			font-size:2rem;
			line-height:2.5rem;
			color:$grey-1;
			margin-bottom:5px;
		}
		.conditions{
			font-size: 16px !important;
		}

		.facebook-link {
			margin:10px 0px;

			i {
				font-size:2.5rem;
			}
		}

		.urgence {
			text-align:center;
			margin-top:20px;
			margin-bottom:20px;

			span {
				font-family:$helv-bold;
				font-size:2.2rem;
				line-height:2.8rem;
				color:$grey-2;
			}

			& > * {
				display:block;
			}

			.c-button {
				margin-top:30px;
			}
		}

		&-copyright {
			margin-top:80px;
			font-family:$helv-regular;
			font-size:1.6rem;
			line-height:2.4rem;
			color:$grey-1;

			& > div {
				display:flex;
				flex-flow:row nowrap;
				justify-content:center;
				align-items:center;
				margin-bottom:5px;
			}

			img {
				width:22px;
				margin-left:10px;
			}
		}
	}
}