.c-commercial {
	&__banner {
		position: relative;
		display: flex;
		align-items: center;
		flex-flow: column wrap;
		justify-content: center;
		height: calc(100% - 75px);
		min-height: 700px;
		background-size: cover;
		overflow: hidden;

		@include mq(small) {
			min-height: 500px;
		}

		&-corner {
			position: absolute;
			right: 0px;
			bottom: 0;
			width: 250px;
			height: 307px;

			@include mq(small) {
				width: 150px;
				height: auto;
			}

			a {
				position: absolute;
				right: 30px;
				bottom: 20px;
				width: 12px;
				margin: 0 auto;
				color: white;
				text-align: center;
				font-size: 2.5rem;
				z-index: 9999;
			}
		}

		&-subtitle {
			color: white;
			font-size: 3rem;
			line-height: 3.5rem;
			font-family: $helv-bold;

			@include mq(small) {
				font-size: 2.2rem;
				line-height: 2.7rem;
			}
		}

		h1 {
			max-width: 800px;
			margin: 20px auto 60px;
			color: white;
			text-transform: initial;
			font-size: 6rem;
			font-weight: 300;
			line-height: 1;
			font-family: $helv-regular;

			@include mq(small) {
				font-size: 4rem;
			}
		}
	}

	&__partners {
		padding: 100px 0px;

		h2 {
			margin-bottom: 100px;
		}

		ul {
			display: flex;
			align-items: center;
			flex-flow: row wrap;
			justify-content: center;
			margin: 0;
			list-style-type: none;

			li {
				width: 20%;
				margin-bottom: 70px;
				padding: 0px 20px;

				@include mq(small) {
					width: 33.33%;
				}

				@include mq(xsmall) {
					width: 50%;
				}

				&:nth-child(1) {
					img {
						width: 120px;
					}
				}

				&:nth-child(2) {
					img {
						width: 110px;
					}
				}

				&:nth-child(3) {
					img {
						width: 90px;
					}
				}

				&:nth-child(4) {
					img {
						width: 150px;
					}
				}

				&:nth-child(5) {
					img {
						width: 100px;
					}
				}

				&:nth-child(6) {
					img {
						width: 120px;
					}
				}

				&:nth-child(7) {
					img {
						width: 110px;
					}
				}

				&:nth-child(8) {
					img {
						width: 90px;
					}
				}

				&:nth-child(9) {
					img {
						width: 120px;
					}
				}

				img {
					backface-visibility: hidden;
				}
			}
		}
	}

	&__secteurs {
		padding: 120px 0px;

		@include mq(medium) {
			padding-bottom: 60px;
		}

		.col_1x2 {
			width: 45%;

			@include mq(small) {
				width: 100%;
			}

			&:first-of-type {
				@include mq(small) {
					margin-bottom: 40px;
				}
			}

			p {
				a {
					color: $blue;
				}
			}
		}
	}

	&__equipe {
		position: relative;
		box-shadow: 0px 0px 50px rgba($grey-2, 0.1);

		&-image {
			height: 100%;
			min-height: 650px;

			.slick-list,
			.slick-track {
				height: 100%;
			}

			&-slide {
				outline: 0;
				background-repeat: no-repeat;
				background-position: centr;
				background-size: cover;
			}
		}

		&-content {
			position: relative;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			height: 100%;
			padding: 40px 100px;
			overflow: hidden;

			&:before {
				content: "";
				position: absolute;
				right: -285px;
				bottom: -285px;
				width: 500px;
				height: 500px;
				transform: rotate(45deg);
				background: $red;
				transition: all 300ms ease;
			}

			.pre-title {
				margin-bottom: 40px;
			}

			&-slide {
				outline: 0;

				h3 {
					margin-bottom: 5px;
					color: $grey-2;
					font-size: 3.5rem;
					line-height: 4rem;
					font-family: $helv-bold;
				}

				h4 {
					color: $grey-2;
					font-size: 2.6rem;
					font-weight: 300;
					line-height: 3.2rem;
					font-family: $helv-regular;
				}

				p {
					max-width: 600px;
					font-weight: 300;
					line-height: 3.5rem;
				}

				a {
					color: $grey-2;
					font-size: 1.8rem;
					font-weight: 300;
					line-height: 2.4rem;
					font-family: $helv-regular;

					&:hover {
						color: $red;
					}

					i {
						margin-right: 10px;
					}
				}
			}
		}

		&-next {
			position: absolute;
			right: 30px;
			bottom: 30px;
			display: flex;
			align-items: center;
			flex-flow: row wrap;
			color: white;
			cursor: pointer;
			font-size: 1.6rem;
			line-height: 2.4rem;
			font-family: $helv-bold;

			i {
				margin-left: 10px;
				color: $blue;
				font-size: 2.5rem;
			}
		}

		&-dots {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			align-items: center;
			flex-flow: column wrap;
			justify-content: center;
			width: 50px;
			height: 200px;
			margin: auto;
			border-radius: 50px;
			background: white;
			z-index: 9999;

			ul {
				li {
					&.slick-active {
						button {
							background-color: $grey-2;
						}
					}

					button {
						width: 15px;
						height: 15px;
						margin: 5px 0px;
						outline: 0;
						border: 0px;
						border-radius: 100%;
						background-color: $grey-border-2;
						cursor: pointer;
						transition: all 300ms ease;
						font-size: 0px;
					}
				}
			}
		}
	}

	&__projets {
		padding: 50px 0px;

		h2 {
			margin-bottom: 50px;
		}

		&-wrapper {
			position: relative;
		}

		&-prev,
		&-next {
			position: absolute;
			top: 0;
			bottom: 0;
			display: flex;
			flex-flow: column wrap;
			justify-content: center;
			cursor: pointer;
			z-index: 9999;

			@include mq(small) {
				display: none !important;
			}

			i {
				color: $red;
				font-size: 3rem;
			}
		}

		&-next {
			right: 0px;
		}

		&-prev {
			left: 0px;
		}

		&-dots {
			ul {
				display: flex;
				align-items: center;
				flex-flow: row wrap;
				justify-content: center;
				margin-top: 50px;
				margin-left: 0;
				list-style-type: none;

				li {
					&.slick-active {
						button {
							background-color: $grey-2;
						}
					}

					button {
						width: 15px;
						height: 15px;
						margin: 0px 5px;
						outline: 0;
						border: 0px;
						border-radius: 100%;
						background-color: $grey-1;
						cursor: pointer;
						transition: all 300ms ease;
						font-size: 0px;
					}
				}
			}
		}

		&-slider {
			padding: 0px 60px;

			@include mq(small) {
				padding: 0;
			}
		}

		.row {
			margin-right: 0;
		}

		&-slide {
			position: relative;
			display: flex !important;
			flex-flow: column wrap;
			justify-content: flex-end;
			min-height: 600px;
			padding: 40px;
			outline: 0;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			text-align: left;

			@include mq(small) {
				min-height: 350px;
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: linear-gradient(45deg, rgba(63, 71, 78, 1) 0%, rgba(63, 71, 78, 0) 100%);
				pointer-events: none;
				z-index: -1;
			}

			& > * {
				position: relative;
				z-index: 9999;
			}

			.pre-title:before {
				border-left: 12px solid white;
			}

			h3 {
				color: white;
				text-transform: uppercase;
				font-size: 2.2rem;
				line-height: 2.8rem;
				font-family: $helv-bold;
			}
		}
	}

	&__footer {
		position: relative;

		h2 {
			max-width: 300px;
			font-size: 3.4rem;
			line-height: 4.4rem;
		}

		.corner {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 200px;
			margin-bottom: 0px;
			backface-visibility: hidden;

			@include mq(medium) {
				display: none;
			}
		}

		img {
			width: 100px;
			margin-bottom: 30px;
		}

		.row {
			& > * {
				display: flex;
				align-items: flex-start;
				flex-flow: column wrap;
				justify-content: center;
				padding: 120px 40px 60px;
			}

			.col_2x5,
			.col_3x5 {
				@include mq(medium) {
					width: 100%;
				}
			}

			.col_2x5 {
				padding-left: 60px;
				background: $grey-bg-2;
				text-align: left;

				@include mq(medium) {
					align-items: center;
					padding-left: 0;
					text-align: center;
				}

				.copyright {
					margin-top: 60px;
					color: $grey-1;
					font-size: 1.8rem;
					line-height: 2.5rem;
					font-family: $helv-regular;
				}
			}

			.col_3x5 {
				background: $grey-massive;
			}
		}

		&-right {
			&-top {
				display: flex;
				flex-flow: row wrap;
				width: 100%;
				margin-bottom: 50px;

				@media screen and (max-width: 1250px) {
					margin-bottom: 30px;
				}

				& > * {
					margin-right: 100px;

					@include mq(medium) {
						margin: 0 auto;
					}
				}

				h3 {
					margin-bottom: 10px;
					font-size: 3rem;
					line-height: 3.6rem;
					font-family: $helv-bold;
				}

				h4 {
					margin-bottom: 10px;
					font-size: 2.2rem;
					line-height: 3rem;
					font-family: $helv-bold;
				}

				p {
					font-size: 1.8rem;
					line-height: 2.6rem;
				}

				.coordonnees {
					@media screen and (max-width: 600px) {
						width: 100%;
						margin-bottom: 30px;
						text-align: center;
					}

					a {
						display: block;
						margin-bottom: 3px;
						color: $grey-1;
						font-size: 1.8rem;
						line-height: 2.6rem;
						font-family: $helv-regular;

						@media screen and (max-width: 1150px) {
							margin-bottom: 20px;
						}

						&:hover {
							color: $blue;
						}
					}
				}

				.links {
					@media screen and (max-width: 600px) {
						width: 100%;
						margin-bottom: 30px;
						text-align: center;
					}

					h3 {
						margin-bottom: 20px;
					}

					a {
						display: block;
						margin-bottom: 10px;
						color: $grey-2;
						font-size: 2rem;
						line-height: 3rem;
						font-family: $helv-bold;

						&:hover {
							color: $blue;
						}
					}
				}

				.social {
					display: flex;
					align-items: center;
					flex-flow: column wrap;
					margin-right: 0px;

					@media screen and (max-width: 1250px) {
						flex-flow: row wrap;
						justify-content: flex-start;
						width: 100%;
						margin-top: 30px;
					}

					@include mq(medium) {
						align-items: center;
						justify-content: center;
						margin: 20pxpx 10px;
						text-align: center;
					}

					a {
						@media screen and (max-width: 1250px) {
							margin-right: 15px;
						}

						&:hover {
							i {
								color: $blue;
							}
						}
					}

					i {
						margin-bottom: 30px;
						color: $grey-1;
						transition: all 300ms ease;
						font-size: 2.2rem;
					}
				}
			}

			&-bottom {
				h2 {
					font-size: 4.4rem;

					&:hover {
						a {
							color: $grey-3;
						}

						i {
							margin-left: 10px;
						}
					}

					a {
						display: flex;
						align-items: center;
						flex-flow: row wrap;
						color: $grey-2;
					}

					i {
						transform: rotate(-90deg);
						color: $blue;
						transition: all 300ms ease;
						font-size: 3.5rem;
					}
				}
			}
		}
	}
}
