.c-page-header {
	background-color: $white;

	.c-page-header_wrapper {
		display: flex;
		align-items: center;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	.c-page-header_content {
		width: 50%;
		padding: 0 80px;

		@media (max-width: 768px) {
			width: 100%;
			padding: 40px;
		}

		h1 {
			margin-bottom: 40px;
			color: $blue;
		}

		p {
			color: $grey-2;
			font-size: 18px;
		}

		li {
			font-size: 18px;
		}
	}

	.c-page-header_img {
		position: relative;
		width: 50%;
		height: 600px;
		overflow: hidden;

		@media (max-width: 768px) {
			width: 100%;
			height: 400px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::after {
			content: "";
			position: absolute;
			right: -130px;
			bottom: -110px;
			width: 250px;
			height: 320px;
			transform: rotate(217deg);
			background-color: $red;

			@media (max-width: 768px) {
				display: none;
			}
		}
	}
}
