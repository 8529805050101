.c-home-hero {
	position: relative;
	height: 86vh;

	@media (max-width: 820px) {
		height: initial;
	}

	.c-home-hero_slider {
		height: 100%;
	}

	.slick-list,
	.slick-track {
		height: 100%;
	}

	.c-home-hero_slide {
		display: flex;
		align-items: center;
		height: 100%;

		@media (max-width: 820px) {
			flex-direction: column-reverse;
		}
	}

	.c-home-hero_wrapper {
		width: 45%;
		height: 100%;

		@media (max-width: 820px) {
			width: 100%;
		}
	}

	.c-home-hero_content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		margin: 0 80px;
		padding: 130px 0 0;

		@media (max-width: 820px) {
			padding: 70px 0 0;
		}

		@media (max-width: 600px) {
			margin: 0 40px;
		}

		h1 {
			color: $grey-2;
			font-size: 56px;

			@media (max-width: 820px) {
				font-size: 35px;
			}
		}

		h5 {
			margin: 10px 0;
			color: $red;
			text-transform: uppercase;
			font-family: $helv-bold;
			font-size: 32px;
		}

		.c-home-hero_subtitle {
			margin-bottom: 40px;
			color: $grey-2;
		}
	}

	.c-slider--scroll {
		display: flex;
		align-items: center;
		gap: 12px;
		margin-top: 40px;
		border-top: 1px solid #d0dce8;
		color: $grey-1;
		font-size: 18px;

		@media (max-width: 820px) {
			margin-top: 80px;
		}

		&::before {
			content: url("../images/svg/continueScroll.svg");
			margin-top: 5px;
		}
	}

	.c-home-hero_img {
		width: 55%;
		height: 100%;

		@media (max-width: 820px) {
			width: 100%;
			height: 275px;
			max-height: 275px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.c-tags {
		display: flex;
		gap: 10px;

		.c-tag {
			padding: 6px 10px;
			border-radius: 4px;
			background-color: $lightblue;
			color: $blue;
			font-size: 14px;
		}
	}

	.slick-arrow {
		position: absolute;
		top: 47%;
		right: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		border-radius: 50px;
		background-color: $white;
		cursor: pointer;
		z-index: 5;

		@media (max-width: 820px) {
			top: 39%;
		}

		&::after {
			content: url("../images/svg/slider-arrow.svg");
		}
	}

	.prevArrow {
		right: unset;
		left: 47%;

		@media (max-width: 820px) {
			left: 40px;
		}
	}

	.nextArrow {
		transform: scaleX(-1);
	}

	.slick-dots {
		position: absolute;
		top: 80px;
		left: 80px;
		display: flex;
		gap: 8px;
		margin: 0;

		@media (max-width: 820px) {
			top: 40.3%;
			left: 40%;
		}

		@media (max-width: 500px) {
			left: 33%;
		}

		li {
			list-style-type: none;

			.slider-dot-wrapper {
				padding: 6px;
			}

			.slider-dot {
				width: 12px;
				height: 12px;
				border: none;
				border-radius: 50px;
				background-color: #d0dce8;
				list-style-type: none;
			}
		}

		.slick-active {
			.slider-dot-wrapper {
				border: 1px solid #d0dce8;
				border-radius: 50px;
			}
			.slider-dot {
				background-color: $blue;
			}
		}
	}
}
