.c-single-marque {
	.c-single-marque_banner {
		width: 100%;
		height: 400px;
		object-fit: cover;
	}

	.c-single-marque_wrapper {
		padding: 80px 80px 120px;

		@media (max-width: 768px) {
			padding: 40px 40px 60px;
		}

		.c-single-marque_heading {
			margin-bottom: 80px;
		}

		.c-single-marque_content {
			display: flex;
			justify-content: space-between;

			@media (max-width: 768px) {
				flex-direction: column;
			}

			.c-single-marque_title {
				width: 50%;

				@media (max-width: 768px) {
					width: 100%;
				}

				h1 {
					color: $blue;
				}

				.subtitle {
					color: $grey-2;
					font-size: 24px;
				}
			}

			.c-single-marque_logo {
				display: flex;
				justify-content: center;
				width: 40%;
				margin-bottom: 80px;
				padding: 48px 0;
				border: 1px solid #d0dce8;
				border-radius: 8px;

				@media (max-width: 768px) {
					width: 100%;
				}
			}
		}

		.c-single-marque_media {
			display: flex;
			justify-content: space-between;

			@media (max-width: 768px) {
				flex-direction: column;
			}

			.c-single-marque_description {
				width: 50%;

				@media (max-width: 768px) {
					width: 100%;
				}
			}

			.c-single-marque_avantages {
				width: 40%;

				@media (max-width: 768px) {
					width: 100%;
				}
			}

			h3 {
				margin-bottom: 24px;
				color: $blue;
				text-transform: uppercase;
				font-family: $helv-regular;
				font-size: 32px;
			}

			p {
				color: $grey-2;
				font-size: 18px;
			}

			li {
				color: $grey-2;
				font-size: 18px;
			}
		}
	}
}
