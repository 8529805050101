.c-cta {
	padding: 120px 80px;

	@media (max-width: 768px) {
		padding: 60px 40px;
	}

	.c-cta_wrapper {
		display: flex;
		gap: 24px;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	.c-cta_single {
		display: flex;
		flex-direction: column;
		width: 100%;
		border-radius: 16px;
		background-color: $lightblue;

		&.-center {
			.c-cta_content {
				display: flex;
				align-items: center;
				flex-direction: column;
			}
		}
	}

	.c-cta_media {
		position: relative;
		flex: 1;
		min-height: 300px;

		@media (max-width: 768px) {
			min-height: 200px;
		}

		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			border-radius: 16px 16px 0 0;
			object-fit: cover;
		}
	}

	.c-cta_content {
		display: flex;
		flex: 2;
		flex-direction: column;
		gap: 20px;
		padding: 40px;

		@media (max-width: 768px) {
			padding: 20px;
		}

		h5 {
			margin: 0;
			font-size: 32px;
			font-family: $helv-bold;
		}

		p {
			color: $grey-2;
			font-size: 24px;
		}

		.c-cta_action {
			width: fit-content;
			margin: 16px 0 20px;

			@media (max-width: 768px) {
				margin: 20px 0;

				a {
					font-size: 12px;
				}
			}
		}
	}
}
