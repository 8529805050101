.c-team-members {
	background-color: $lightblue;

	.c-team-members_header {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 80px;
		text-align: center;

		img {
			width: 96px;
			height: 96px;
			margin-bottom: 80px;
		}
	}

	.c-team-members_wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 72px 24px;
		padding-bottom: 120px;
		border-bottom: 2px dashed #d0dce8;
	}

	.c-team-members_single {
		width: 30%;
		border-radius: 8px;
		background-color: $white;

		@media (max-width: 1000px) {
			width: 48%;
		}

		@media (max-width: 768px) {
			width: 100%;
		}

		img {
			width: 100%;
			border-radius: 8px 8px 0 0;
			object-fit: cover;
		}
	}

	.c-team-members_body {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 40px;

		h4 {
			text-transform: uppercase;
		}

		h6 {
			margin-top: 0;
			margin-bottom: 20px;
			color: $grey-2;
			font-family: $helv-regular;
			font-size: 18px;
			font-weight: 400;
		}

		a {
			margin-bottom: 20px;
			color: $grey-3;
			font-family: $helv-regular;
			font-size: 18px;
			font-weight: 400;

			&:hover {
				color: lighten($grey-3, 60);
			}
		}
	}

	.c-team-members_seemore {
		position: absolute;
		bottom: -35px;
		cursor: pointer;

		&::after {
			content: url("../images/svg/viewmore.svg");
		}
	}

	.c-team-members_single-modal {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: none;
		width: 100%;
		height: 100%;
		padding: 150px;
		background-color: #2121213e;
		z-index: 9999;

		@media (max-width: 768px) {
			padding: 85px 20px;
		}

		.c-team-members_modal-wrapper {
			height: auto;
			padding: 40px;
			border-radius: 8px;
			background-color: $white;
			overflow: auto;

			@media (max-width: 768px) {
				padding: 20px;
			}

			.c-team-members_body {
				position: relative;
				flex-direction: row;
				gap: 40px;
				padding: 0;
				padding-bottom: 40px;

				@media (max-width: 768px) {
					flex-direction: column;
					padding-bottom: 20px;
				}

				img {
					width: 200px;
					height: 170px;
					border-radius: 8px;
					object-fit: cover;

					@media (max-width: 768px) {
						width: unset;
						height: unset;
					}
				}
			}

			.c-team-members_desc {
				padding-top: 40px;
				border-top: 1px solid #d0dce8;

				@media (max-width: 768px) {
					padding-top: 20px;
				}
			}

			.c-team-members_content {
				width: 70%;

				@media (max-width: 768px) {
					width: 100%;
				}
			}

			.c-team-members_close {
				position: absolute;
				top: 0px;
				right: 0px;
				border-radius: 100%;
				cursor: pointer;

				&::after {
					content: url("../images/svg/close.svg");
				}
			}

			.c-tag {
				margin-top: 20px;
			}
		}

		&.is-active {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
}
