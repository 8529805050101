.c-media {
	max-width: 1062px;
	margin: 0 auto;
}

.c-media_media {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	aspect-ratio: 16/9;

	img {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
	}
}
