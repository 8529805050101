.c-marque-listing {
	padding: 0 80px;
	background: #eef4ff;

	@media (max-width: 768px) {
		padding: 0 40px;
	}

	.c-marque-listing_single {
		display: flex;
		align-items: center;
		gap: 24px;
		padding-top: 120px;
		padding-bottom: 120px;

		@media (max-width: 768px) {
			flex-direction: column-reverse;
			gap: 48px;
			padding-top: 60px;
			padding-bottom: 60px;
		}

		&:not(:last-child) {
			border-bottom: 1px solid #d0dce8;
		}
	}

	.c-marque-single_content {
		width: 50%;

		@media (max-width: 768px) {
			width: 100%;
		}

		h2 {
			margin-bottom: 20px;
			color: $blue;
			text-transform: uppercase;
			font-family: $helv-black;
			font-size: 64px;
			line-height: 5.5rem;
		}

		h4 {
			margin-bottom: 24px;
			font-family: $helv-regular;
			font-size: 24px;
		}

		p {
			margin-bottom: 48px;
			color: $grey-2;
			font-size: 18px;
		}
	}

	.c-marque-single_media {
		display: flex;
		flex-direction: column;
		width: 50%;
		border-radius: 8px;
		background-color: $white;

		@media (max-width: 768px) {
			width: 100%;
		}

		.c-marque-single_img {
			img {
				width: 100%;
				height: 100%;
				border-radius: 8px 8px 0 0;
				object-fit: cover;
			}
		}

		.c-marque-single_logo {
			display: flex;
			justify-content: center;
			padding: 20px;
		}
	}
}
