.c-text-video {
	background-color: $lightblue;
}

.c-text-video_content {
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;

	h2 {
		text-transform: uppercase;
	}
}

.c-text-video_desc {
	display: flex;
	flex-direction: column;
	gap: 12px;

	h6 {
		margin: 0;
		color: $grey-2;
		font-family: $helv-regular;
		font-size: 24px;
		font-weight: 400;
		line-height: 34px;
		letter-spacing: 0em;
	}
}

.c-text-video_media {
	position: relative;
}

.c-text-video_video {
	display: none;
}

.c-text-video_play {
	position: absolute;
	top: 46%;
	right: 0;
	left: 0;
	margin: auto;
	cursor: pointer;

	&::after {
		content: url("../images/svg/play.svg");
	}
}
