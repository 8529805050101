.c-products_body {
	// display: flex;
	flex: 1;
	// flex-direction: column;
	padding: 40px;
	border-radius: 8px 8px 0 0;
	background-color: $white;
}

.c-products_footer {
	padding: 20px;
	border-radius: 0 0 8px 8px;
	background-color: $blue;
	transition: all 150ms ease-in-out;
	text-align: center;

	p {
		margin-bottom: 0;
		color: $white;
	}
}

.c-products_img {
	height: 300px;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.c-products_content {
	text-align: center;

	h5 {
		margin: 20px 0;
		color: $grey-3;
		font-family: $helv-bold;
		font-size: 32px;
	}
}

.c-products_marque {
	text-align: center;
}

.c-products_tag {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;

	p {
		padding: 6px 10px;
		border-radius: 4px;
		background-color: $lightblue;
		color: $blue;
	}

	.c-tag_promo {
		background-color: $red;
		color: $white;
	}
}
