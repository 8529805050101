.c-text_title {
	margin-bottom: 24px;
	font-size: 48px;
	line-height: 1;
	font-family: $helv-regular;

	@media (max-width: 700px) {
		font-size: 32px;
	}
}

.c-text_subtitle {
	margin-bottom: 24px;
	font-family: $helv-regular;
}
