.c-anchor-scroll {
	position: absolute;
	bottom: 100px;
	left: 226px;
	display: flex;
	align-items: center;
	flex-flow: row wrap;
	width: 350px;
	margin: 0 auto;
	color: $grey-1;
	font-size: 1.6rem;
	z-index: 10;

	@include mq(medium) {
		display: none;
	}

	&::before {
		content: url("../images/svg/continueScroll.svg");
		margin-top: 5px;
		margin-right: 12px;
	}

	&:hover {
		i {
			margin-left: 20px;
		}
	}

	i {
		margin-bottom: -2px;
		margin-left: 10px;
		color: $red;
		transition: all 300ms ease;
		font-size: 2.5rem;
	}
}
