.c-breadcrumb {
  background: $lightblue;
  padding: 20px 40px;

  @include mq(xsmall) {
    display: none;
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
    margin: 0;

    li {
      position: relative;

      a {
        &:after {
          content: "\f0dd";
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          text-rendering: auto;
          transform: rotate(-90deg);
          margin: 0px 15px 0px 10px;
          color: $blue;
        }
      }
    }

    li,
    li h1,
    a {
      font-family: $helv-regular;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $grey-2;
    }
    li h1 {
      margin-bottom: 0;
      text-transform: inherit;
      font-weight: lighter;
    }

    a {
      &:hover {
        color: $grey-3;
      }
    }
  }
}
