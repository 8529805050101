.c-feature-link {
	padding: 40px;
	border-radius: 16px;
	background-color: $lightblue;

	@media (max-width: 700px) {
		padding: 16px;
	}
}

.c-feature-link_title {
	margin-bottom: 16px;
	color: $grey-3;
	text-transform: uppercase;
	font-family: $helv-regular;
}

.c-feature-link_content {
	margin-bottom: 40px;
}

.c-feature-link_btn {
	display: inline-block;
}
