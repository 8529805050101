.c-red-box {
	background-color:$orange;
	padding:80px 40px;
	position:relative;
	overflow:hidden;

	&:hover {

		.c-red-box__plus {
			right:-90px;
			bottom:-90px;
		}
	}

	.pre-title:before {
		border-left:12px solid white;
	}

	&__plus {
		position:absolute;
		bottom:-150px;
		right:-150px;
		display:flex;
		flex-flow:row wrap;
		justify-content: center;
		align-item:center;
		width:150px;
		height:150px;
		background:white;
	    transform: rotate(45deg);
	    transition:all 300ms ease;


		&:before,
		&:after {
			content:'';
			position:absolute;
			top:0;
			right:105px;
			bottom:0;
			left:0;
			display:block;
			width:14px;
			height:2px;
			background:$orange;
			margin:auto;
		}

		&:before {
			transform:rotate(45deg);
		}

		&:after {
			transform:rotate(135deg);
		}
	}
}