.c-form-section {
	.c-form-section_header {
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 80px;
		margin-bottom: 80px;

		.c-form-section_desc {
			text-align: center;
		}
	}

	.c-form_input {
		margin-right: 0;
		margin-bottom: 40px;

		@media (max-width: 700px) {
			width: 100%;
		}
	}

	.c-form_label {
		p {
			margin: 0;
			color: $grey-3;
			font-size: 18px;
			font-weight: 400;
			font-family: $helv-regular;

			span {
				color: $red;
			}
		}
	}

	.c-form_text {
		p {
			margin-bottom: 0;
		}

		input,
		textarea {
			width: 100%;
			margin-top: 10px;
			padding: 20px;
			border: 1px solid #d0dce8;
			border-radius: 8px;

			&::placeholder {
				color: $grey-1;
				font-size: 18px;
				font-weight: 400;
				font-family: $helv-regular;
			}
		}
	}

	.c-form_footer {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	#c-form__label {
		display: block;
		width: 100%;
		margin-top: 10px;
		margin-right: 0;
		padding: 20px;
		border: 1px dashed #d0dce8;
		border-radius: 8px;
	}
}

.c-form-section_icon {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 96px;
	height: 96px;
	border-radius: 50%;
	background-color: $darkblue;

	img {
		position: absolute;
		max-width: 48px;
		object-fit: contain;
	}
}

.c-form_checkbox {
	.wpcf7-list-item {
		margin: 0;
	}
}

.c-form_checkbox-list {
	.wpcf7-checkbox {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.wpcf7-list-item {
		width: 50%;
		margin: 0;

		@media (max-width: 500px) {
			width: 100%;
		}
	}
}
