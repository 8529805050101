.c-card {
	display: flex;
	flex-direction: column;
	height: 100%;
	border-radius: 8px;
	background-color: $white;
	overflow: hidden;
	text-align: center;
}

.c-card_body {
	padding: 40px;

	@media (max-width: 500px) {
		padding: 16px;
	}
}

.c-card_media {
	position: relative;
	margin-bottom: 20px;
	aspect-ratio: 1/0.8;

	img {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.c-card_categories {
	margin-bottom: 12px;
	color: $grey-3;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 700;
}

.c-card_title {
	margin-bottom: 20px;
	color: $grey-3;
	text-transform: uppercase;
	font-size: 32px;
	line-height: 1;

	@media (max-width: 700px) {
		font-size: 24px;
	}
}

.c-card_footer {
	margin-top: auto;
	margin-bottom: 0;
	padding: 20px 40px;
	background-color: $darkblue;
	color: $white;
	overflow: hidden;
	transition: background-color 0.3s ease;
	text-transform: uppercase;
	font-size: 14px;

	.c-card:hover & {
		background-color: lighten($blue, 10%);
	}
}

.c-card_tags {
	display: flex;
	justify-content: center;
	gap: 10px;
}

.c-card_tag {
	padding: 6px 10px;
	border-radius: 4px;
	background-color: $lightblue;
	color: $darkblue;
	font-size: 14px;
	line-height: 20px;
	font-family: $helv-regular;

	&.-red {
		background-color: $red;
		color: $white;
	}

	&.-black {
		background-color: $grey-3;
		color: $white;
	}

	&.-green {
		background-color: $green-2;
		color: $white;
	}
}

.c-card_subtitle {
	color: $grey-2;
	font-size: 18px;
	font-family: $helv-regular;
}
