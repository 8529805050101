.c-accordion {
	padding: 100px 0px;
	background: white;

	&--career {
		padding: 0;
		background: $grey-bg;

		.c-accordion-item {
			padding: 40px 50px 40px 40px;

			@media (max-width: 768px) {
				padding: 30px 40px 30px 30px;
			}

			& > h3 {
				@media (max-width: 768px) {
					font-size: 1.8rem;
					line-height: 2.4rem;
				}
			}
		}
	}

	&-item {
		position: relative;
		margin-bottom: 30px;
		padding: 40px 30% 40px 40px;
		box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
		cursor: pointer;
		transition: all 300ms ease;
		border-radius: 8px;

		&:last-of-type {
			margin-bottom: 0;
		}

		&:hover {
			box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
		}

		.toggle-icon {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			width: 50px;
			height: 100%;

			@media (max-width: 768px) {
				top: 20px;
				right: -20px;
			}

			.plus {
				position: absolute;
				right: 28px;
				bottom: 40px;
				z-index: 999;

				&:before,
				&:after {
					content: "";
					display: block;
					width: 10px;
					height: 2px;
					background: white;
				}

				&:after {
					transform: rotate(90deg) translateX(-2px);
				}
			}

			.triangle {
				position: absolute;
				margin-top: 10px;
				transform: rotate(45deg);
				border-top: 30px solid transparent;
				border-right: 30px solid transparent;
				border-bottom: 30px solid transparent;
				border-left: 30px solid $red;
			}
		}
	}

	.accordion-content {
		display: none;
		margin-top: 40px;

		span.accordion-sub {
			display: block;
			margin-bottom: 20px;
			color: $grey-2;
			font-family: $helv-regular;
			font-size: 2.2rem;
			line-height: 3rem;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: 0px;
			margin-bottom: 10px;
			color: $grey-2;
			text-transform: initial;
			font-family: $helv-bold;
		}

		h1 {
			font-size: 3rem;
			line-height: 3.5rem;

			@media (max-width: 768px) {
				font-size: 2rem;
				line-height: 2.5rem;
			}
		}

		h2 {
			font-size: 2.8rem;
			line-height: 3.3rem;

			@media (max-width: 768px) {
				font-size: 1.8rem;
				line-height: 2.3rem;
			}
		}

		h3 {
			font-size: 2.4rem;
			line-height: 2.9rem;

			@media (max-width: 768px) {
				font-size: 1.6rem;
				line-height: 2.1rem;
			}
		}

		h4 {
			font-size: 2.2rem;
			line-height: 2.7rem;

			@media (max-width: 768px) {
				font-size: 1.4rem;
				line-height: 1.9rem;
			}
		}

		h5 {
			font-size: 2rem;
			line-height: 2.5rem;

			@media (max-width: 768px) {
				font-size: 1.2rem;
				line-height: 1.7rem;
			}
		}

		h6 {
			font-size: 1.8rem;
			line-height: 2.3rem;

			@media (max-width: 768px) {
				font-size: 1rem;
				line-height: 1.5rem;
			}
		}

		p {
			font-size: 1.6rem;
			line-height: 2.2rem;

			@media (max-width: 768px) {
				font-size: 1rem;
				line-height: 1.5rem;
			}
		}

		p:last-of-type {
			margin-bottom: 50px;
		}

		strong {
			font-family: $helv-bold;
		}

		ul {
			margin: 20px 0px 30px 20px;

			li {
				font-size: 1.6rem;
				line-height: 2.2rem;
			}
		}

		ul:last-of-type {
			margin-bottom: 50px;
		}
	}

	&.-lightblue {
		background-color: $lightblue;
	}

	.c-accordion_header {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-bottom: 40px;
		text-align: center;
	}
	.c-accordion_content {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
	.c-accordion_single {
		padding: 24px 40px;
		border-radius: 8px;
		background-color: $white;

		@media (max-width: 768px) {
			padding: 20px 20px;
		}

		&.-job {
			.c-accordion_body {
				border: none;
			}
		}
	}
	.c-accordion_head {
		position: relative;
		cursor: pointer;

		h5 {
			margin: 0;
			color: $grey-3;
			font-family: $helv-regular;
			font-size: 32px;
			font-weight: 400;

			@media (max-width: 768px) {
				font-size: 28px;
			}
		}
	}

	.c-accordion_head-infos {
		display: flex;
		justify-content: space-between;
		margin-top: 26px;
		padding-top: 20px;
		border-top: 1px solid #d0dce8;

		@media (max-width: 500px) {
			flex-direction: column;
		}

		p {
			width: 33.333%;

			@media (max-width: 500px) {
				width: 100%;
			}
		}
	}

	.c-accordion_toggle {
		position: absolute;
		top: -6px;
		right: 0;
		cursor: pointer;

		@media (max-width: 768px) {
			top: -30px;
			right: -40px;
		}

		&.-center {
			top: 36px;
			right: 40px;

			&.is-open {
				&:after {
					content: "-";
					background-color: $grey-3;
				}
			}
		}

		&::after {
			content: "+";
			display: inline-flex;
			justify-content: center;
			width: 48px;
			height: 48px;
			border-radius: 24px;
			background-color: $red;
			color: $white;
			transition: all ease-in 150ms;
			font-size: 36px;

			@media (max-width: 768px) {
				width: 36px;
				height: 36px;
				font-size: 28px;
			}
		}
	}

	.c-accordion_body {
		display: none;
		margin-top: 32px;
		border-top: 1px solid #d0dce8;

		h3 {
			margin-bottom: 12px;
			color: $blue;
			font-family: $helv-regular;
			font-size: 24px;
			font-weight: 400;
		}

		ul {
			margin-bottom: 40px;
		}
	}

	.c-accordion_footer {
		padding: 40px 0;
		border-top: 1px solid #d0dce8;
	}

	.c-accordion_action {
		margin-top: 40px;
	}

	.c-accordion_single {
		&.is-open {
			.c-accordion_toggle {
				&::after {
					content: "-";
					background-color: $grey-3;
				}
			}

			.c-accordion_body {
				display: block;
			}
		}
	}
}
