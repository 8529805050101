html {
	overflow-x: hidden;
	font-size: 10px;
}

body {
	margin: 0px;
	background: $grey-bg;
}

html,
body {
	&.hidden {
		overflow: hidden;
	}
}

* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

section:last-of-type {
	//box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.05);
}

h1,
h2,
h3,
h4 {
	margin-top: 0px;

	&.darkblue {
		color: $blue;
		text-transform: uppercase;
	}

	&.red {
		color: $red;
		text-transform: uppercase;
	}

	&.white {
		color: $white;
		text-transform: uppercase;
	}
}

h1 {
	max-width: 600px;
	margin-bottom: 20px;
	color: $grey-2;
	text-transform: uppercase;
	font-size: 7rem;
	line-height: 7rem;
	font-family: $helv-black;

	@include mq(medium) {
		font-size: 5rem;
		line-height: 5.5rem;
	}

	@include mq(medium) {
		font-size: 4rem;
		line-height: 4.5rem;
	}

	@include mq(xsmall) {
		font-size: 3rem;
		line-height: 3.5rem;
	}

	span {
		display: block;
		color: $grey-2;
		text-transform: uppercase;
		font-size: 5rem;
		line-height: 6rem;
		font-family: $helv-bold;
	}

	&.-blue {
		color: $blue;
	}

	&.medium {
		max-width: inherit;
		color: $grey-2;
		text-transform: initial;
		font-size: 4.8rem;
		line-height: 5.5rem;
		font-family: $helv-bold;
		@include mq(small) {
			font-size: 3.8rem;
			line-height: 4.5rem;
		}

		@include mq(xsmall) {
			font-size: 3rem;
			line-height: 3.5rem;
		}
		&.left {
			text-align: left;
		}
	}

	&.small {
		max-width: inherit;
		color: $grey-2;
		text-transform: initial;
		font-size: 4rem;
		line-height: 4.5rem;
		font-family: $helv-bold;
		@media screen and (max-width: 1200px) {
			font-size: 3rem;
			line-height: 3.5rem;
		}

		@media screen and (max-width: 1200px) {
			font-size: 2.5rem;
			line-height: 3rem;
		}

		@include mq(xsmall) {
			font-size: 2rem;
			line-height: 2.5rem;
		}
	}
}

h2 {
	margin-bottom: 20px;
	color: $grey-2;
	font-size: 4.8rem;
	line-height: 5.4rem;
	font-family: $helv-bold;

	@media screen and (max-width: 1200px) {
		font-size: 4rem;
		line-height: 4.5rem;
	}

	@include mq(small) {
		font-size: 3rem;
		line-height: 3.5rem;
	}

	&.medium {
		font-size: 4rem;
		line-height: 4.5rem;

		@include mq(medium) {
			font-size: 3.5rem;
			line-height: 4rem;
		}

		@include mq(small) {
			font-size: 2.8rem;
			line-height: 3.5rem;
		}
	}

	&.small {
		font-size: 2.4rem;
		line-height: 3rem;
	}

	&.white {
		color: white;
		font-size: 2.2rem;
		line-height: 2.8rem;
	}
}

h3 {
	margin-bottom: 0px;
	color: $grey-2;
	font-size: 3rem;
	line-height: 3.8rem;
	font-family: $helv-black;

	&.large {
		display: block;
		font-size: 4rem;
		line-height: 4.6rem;
		font-family: $helv-bold;

		@include mq(medium) {
			font-size: 3rem;
			line-height: 3.5rem;
		}
	}

	&.small {
		font-size: 2.8rem;
		line-height: 3.2rem;
		font-family: $helv-bold;

		@include mq(small) {
			font-size: 2.2rem;
			line-height: 2.8rem;
		}
	}

	span {
		display: block;
		font-size: 2.4rem;
		line-height: 3rem;
	}

	&.white {
		color: white;
	}
}

h4 {
	margin-bottom: 10px;
	color: $grey-2;
	font-size: 2.6rem;
	line-height: 3.4rem;
	font-family: $helv-bold;

	@include mq(medium) {
		font-size: 2rem;
		line-height: 2.5rem;
	}
}

p {
	margin-top: 0px;
	color: $grey-1;
	font-size: 2rem;
	line-height: 3rem;
	font-family: $helv-regular;

	@include mq(small) {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}

	&.large {
		font-size: 2rem;
		line-height: 3rem;

		@include mq(small) {
			font-size: 1.8rem;
			line-height: 2.4rem;
		}
	}

	&.medium {
		font-size: 2.2rem;
		line-height: 3rem;

		@include mq(medium) {
			font-size: 2rem;
			line-height: 2.5rem;
		}
	}

	&.small {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}

	&.white {
		color: white;
		font-size: 1.3rem;
		line-height: 1.9rem;
	}
}

a {
	transition: all 300ms ease;
	text-decoration: none;
	font-family: $helv-bold;
}

img {
	max-width: 100%;
}

ul {
	margin: 0 0 20px 18px;
	padding: 0;
	list-style-type: circle;
	list-style-position: outside;

	li {
		color: $grey-1;
		font-size: 2rem;
		line-height: 2.5rem;
		font-family: $helv-regular;
	}
}

textarea {
	resize: vertical;
}

span.blue {
	color: $blue;
}

span.orange {
	color: $orange;
}

.pre-title {
	display: block;
	margin-bottom: 10px;
	color: $grey-1;
	text-transform: uppercase;
	font-size: 1.8rem;
	line-height: 2.6rem;
	font-family: $helv-bold;

	&:before {
		content: "";
		display: inline-block;
		width: 0px;
		height: 0px;
		margin-bottom: -12px;
		margin-left: 10px;
		transform: rotate(45deg);
		border-top: 12px solid transparent;
		border-right: 12px solid transparent;
		border-bottom: 12px solid transparent;
		border-left: 12px solid $blue;
	}
}

.arrow-list {
	margin: 30px 0px 50px;

	li {
		display: flex;
		align-items: flex-start;
		flex-flow: row nowrap;
		justify-content: flex-start;
		margin: 5px 0px;
		color: $grey-1;
		font-size: 2.2rem;
		line-height: 3rem;
		font-family: $helv-regular;

		@include mq(xsmall) {
			color: $grey-2;
			font-size: 1.8rem;
			line-height: 2.8rem;
		}

		&:before {
			content: "\f0dd";
			display: inline-block;
			margin-top: 5px;
			margin-right: 10px;
			transform: rotate(-90deg);
			color: $blue;
			text-rendering: auto;
			font: normal normal normal 22px/1 FontAwesome;
		}
	}
}

//Inclure dans votre fichier .scss

// .page-loader {
// 	position: fixed;
// 	top: 0;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	background: $grey-bg;
// 	z-index: 9999;
// 	padding: 40px;
// 	opacity: 0;
// 	animation: loading 1s forwards ease;
// 	display: flex;
// 	flex-flow: row nowrap;
// 	justify-content: center;
//     align-items: center;

// 	img {
// 		width: 250px;
// 		animation: fadeIcon 0.5s 0.25s forwards ease;
//         opacity: 0;
//     }
// }

.page-content {
	position: relative;
	height: 100%;
	opacity: 0;
	transition: all 0.3s ease;
	z-index: 99999;

	&.active {
		opacity: 1;
	}
}

@keyframes loading {
	0% {
		visibility: hidden;
		opacity: 0;
	}

	20% {
		visibility: visible;
		opacity: 1;
	}

	70% {
		visibility: visible;
		opacity: 1;
	}

	100% {
		visibility: hidden;
		opacity: 0;
	}
}

@keyframes fadeIcon {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.cky-modal > * {
	font-family: $helv-regular !important;
}
