.c-parallax {
	height:700px;

	@media screen and (max-width:1300px) {
		height:600px;
	}

	@media screen and (max-width:1150px) {
		height:500px;
	}

	@include mq(medium) {
		height:400px;
	}

	@include mq(small) {
		height:350px;
	}

	@include mq(xsmall) {
		height:300px;
	}
}