.c-split {
	background:white;
	position:relative;

	.c-split__badge {
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		margin:auto;
		width:100px;
		height:100px;
		z-index: 10;
		background:white;
		border-radius:100%;
		display:flex;
		flex-flow:row wrap;
		justify-content: center;
		align-items: center;
		flex-shrink:0;

		@include mq(medium) {
			top:350px;
			bottom:initial;
		}

		@include mq(small) {
			top:250px;
		}

		@include mq(xsmall) {
			display: none;
		}

		&.service {

			&:before {
				content:'';
				position:absolute;
				top:0;
				bottom:0;
				right:0;
				left:0;
				margin:auto;
				width:65px;
				height:65px;
				border-radius:100%;
				border:3px solid $grey-1;
			}

			img {
				width:35px;
			}
		}
	}

	.col_1x2 {
		padding:40px;
		min-height:700px;
		display:flex;
		flex-flow:column wrap;
		justify-content: center;
		align-items:flex-start;

		@include mq(medium) {
			width:100%;
			min-height:400px;
		}

		@include mq(small) {
			min-height:300px;
		}
		@include mq(xsmall) {
			min-height:250px;
		}

		&:first-child {
			max-width:600px;
			margin:0 auto;
		}

		&:last-child {
			background-size:cover;
			background-repeat:no-repeat;
			background-position: center;

			@include mq(medium) {
				order:-1;
			}
		}

		&.no-padding {
			padding:0px;
		}

		p {
			max-width: 475px;

			@include mq(medium) {
				font-size:1.6rem;
				line-height:2.4rem;
				max-width: none;
			}
		}
	}

	hr {
		border-color:$grey-bg;
		width:100%;
		margin-bottom:20px;

		& + p {
			color:$grey-2;
			margin-bottom: 10px;
		}
	}

	.phone {
		font-family: $helv-bold;
		font-size:2.4rem;
		line-height:3rem;
		color: $grey-1;

		&:after {
		    content: "\f0dd";
		    display: inline-block;
		    font: normal normal normal 14px/1 FontAwesome;
		    font-size:24px;
		    text-rendering: auto;
		    transform: rotate(-90deg);
		    margin: 0 15px 0 5px;
		    color: $orange;
		    transition:all 300ms ease;
		}

		&:hover {

			&:after {
				margin:0 15px 0 10px;
			}
		}
	}

	#map {
		width:100%;
		height:100%;
	}
}