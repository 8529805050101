.c-subvention {
	background-color:$grey-bg;

	.row {
		padding:100px 0px;

		@include mq(medium) {
			padding:60px 0px;
		}

		&:not(:last-child) {
			border-bottom:2px solid $grey-border-2;
		}

		.col_1x2 {

			@include mq(medium) {
				width:100%;
				padding:20px 40px;
			}

			@include mq(medium) {
				padding:20px;
			}

			&:first-of-type {

				@include mq(medium) {
					text-align:center;
				}
			}

			img {

				@include mq(medium) {
					margin-bottom:30px;
					width:100%;
					max-width:250px;
				}
			}
		}
	}

	h2 {
		font-size:4rem;
		line-height:4.5rem;
		margin-bottom: 10px;

		@include mq(small) {
			font-size: 3rem;
			line-height:4rem;
		}
	}

	p, li {
		@include mq(small) {
			font-size: 1.6rem;
			line-height:2.4rem;
		}
	}

	img {
		max-width:400px;
	}
}