.c-filters {
	display: flex;
	gap: 40px;
	margin-bottom: 48px;

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 10px;
		margin-bottom: 24px;
	}

	&.-job {
		justify-content: space-between;

		.c-filter_wrapper {
			display: flex;
			justify-content: space-between;
			width: 33%;

			@media (max-width: 768px) {
				flex-direction: column;
				width: 100%;
			}
		}
	}
}

.c-filters_link {
	color: $grey-1;
	cursor: pointer;
	font-family: $helv-regular;
	font-size: 24px;

	@media (max-width: 768px) {
		width: fit-content;
	}

	&.is-active {
		border-bottom: 1px solid $blue;
		color: $blue;
	}
}
