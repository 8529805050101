.c-advantages {
	h2 {
		text-transform: uppercase;
		font-family: $helv-bold;
		font-size: 64px;
		font-weight: 900;
		line-height: 70.4px;

		@media (max-width: 768px) {
			font-size: 48px;
		}

		@media (max-width: 500px) {
			font-size: 2.8em;
			line-height: 34px;
		}
	}
	.c-advantages_content {
		display: flex;
		flex-wrap: wrap;
		gap: 40px 0;
		margin-top: 80px;

		@media (max-width: 768px) {
			margin-top: 40px;
		}

		&.-compact {
			.c-advantages_single {
				width: 50%;

				@media (max-width: 768px) {
					width: 100%;
					padding: 0 20px;
					border-right: 1px solid #d0dce8;
				}
			}
		}
	}

	.c-advantages_single {
		width: 33.333%;
		padding: 0 40px;
		border-left: 1px solid #d0dce8;

		@media (max-width: 991px) {
			width: 50%;
		}

		@media (max-width: 768px) {
			width: 100%;
			padding: 0 20px;
			border-right: 1px solid #d0dce8;
		}

		h5 {
			margin: 0;
			margin-bottom: 12px;
			color: $grey-3;
			font-family: $helv-regular;
			font-size: 32px;
			font-weight: 400;
		}

		p {
			margin: 0;
		}

		&:nth-child(3) {
			border-right: 1px solid #d0dce8;

			@media (max-width: 991px) {
				border-right: initial;
			}

			@media (max-width: 768px) {
				border-right: 1px solid #d0dce8;
			}
		}

		&:nth-child(even) {
			@media (max-width: 991px) {
				border-right: 1px solid #d0dce8;
			}

			@media (max-width: 768px) {
				border-right: 1px solid #d0dce8;
			}
		}

		&:last-child {
			border-right: 1px solid #d0dce8;
		}
	}

	.c-advantages_count {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 96px;
		height: 96px;
		margin-bottom: 48px;
		border-radius: 48px;
		background-color: $lightblue;
	}

	.c-advantages_icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 96px;
		height: 96px;
		margin-bottom: 48px;
		padding: 24px;
		border-radius: 48px;
		background-color: $lightblue;
	}
}
