.c-distinctions {
	padding: 120px 80px;

	@media (max-width: 768px) {
		padding: 60px 40px;
	}
}

.c-distinctions_header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 80px;

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 20px;
		margin-bottom: 40px;
	}

	h1 {
		color: $blue;
	}

	p {
		width: 40%;

		@media (max-width: 768px) {
			width: 100%;
		}
	}
}

.c-distinctions_wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
}

.c-distinctions_single {
	flex-grow: 1;
	width: 23%;

	@media (max-width: 768px) {
		width: 100%;
	}
}

.c-distinctions_media {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 48px;
	padding: 40px;
	border: 1px solid #d0dce8;
	border-radius: 8px;

	@media (max-width: 768px) {
		margin-bottom: 20px;
	}
}

.c-distinctions_content {
	h6 {
		margin: 0 0 12px;
		font-family: $helv-regular;
		font-size: 24px;
		font-weight: 400;
	}

	p {
		color: $grey-2;
	}

	a {
		display: flex;
		align-items: center;
		width: fit-content;
		color: $grey-2;
		transition: all 150ms ease-in-out;
		text-transform: uppercase;
		font-size: 14px;

		&::before {
			content: url("../images/svg/arrow_forward_orange.svg");
			margin-right: 10px;
		}

		&:hover {
			transform: translateX(10px);
		}
	}
}
