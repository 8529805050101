.c-partners {
	border-top:1px solid $grey-border-2;
	padding:80px 0px;
	background:white;
	box-shadow:0px 0px 50px rgba($grey-3, 0.1);

	.slick-track {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
	}

	&__slide {
		margin: 0 20px;
		outline: 0;

		img {
			max-width: 150px;
			margin: 0 auto;
			backface-visibility: hidden;
		}
	}

}