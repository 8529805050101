.c-splash {
	height:100%;
	display:flex;
	flex-flow:column wrap;
	justify-content:space-between;

	@include mq('medium') {
		height:auto;
	}

	&__email {
		font-family:$helv-bold;
		font-size: 2rem;
		line-height: 2.6rem;
		color: white;

		i {
			margin-right: 5px;
		}
	}

	&__header {
		display:flex;
		flex-flow:row wrap;
		justify-content: space-between;

		@include mq('small') {
			flex-flow:column wrap;
		}

		.logo {
			display:flex;
			flex-flow:row wrap;
			justify-content: center;
			align-items:center;
			padding:20px 40px;

			img {
				width: 235px;
			}
		}

		.cta {
			display:flex;

			@include mq('small') {
				width:100%;
			}

			@include mq('xsmall') {
				width:100%;
				flex-flow:column wrap;
			}

			a {
				margin-left:30px;
				display:flex;
				flex-flow:row wrap;
				justify-content: center;
				align-items:center;
				font-size:1.5rem;

				@include mq('small') {
					width:50%;
					margin:0px;
				}

				@include mq('xsmall') {
					width:100%;
				}

				&.find-us {
					color:$grey-1;
					font-family:$helv-regular;
					font-size:1.6rem;

					@include mq('small') {
						padding:20px 20px;
					}

					&:hover {
						color:$grey-2;
					}

					i {
						margin-right:10px;
						color:$orange;
					}
				}

				&.emergency {
					background:$orange;
					padding:20px 40px;
					color:white;
					text-transform: uppercase;
					font-family: $helv-bold;

					&:hover {
						background:lighten($orange, 7%);
					}
				}
			}
		}
	}

	&__dispatch {
		flex-grow:1;
		display:flex;
		flex-flow:row wrap;
		justify-content: space-between;
		background:white;

		@include mq('medium') {
			flex-flow:column wrap;
		}

		.dispatch-col {
			width: 49.5%;
			display: flex;
			flex-flow: column nowrap;

			@include mq('medium') {
				width:100%;
				height:600px;
			}

			@include mq('small') {
				width:100%;
				height:500px;
			}

			@include mq('xsmall') {
				width:100%;
				height:400px;
			}
		}

		.secteur-info {
			background: $orange;
			padding: 20px 30px;
			text-align: center;

			@media (max-width: 550px) {
				display: flex;
				flex-flow: column nowrap;
			}

			a {
				color: white;
				font-size: 1.8rem;
				margin: 0px 20px;

				@media (max-width: 550px){
					margin: 0 0 10px;
				}

				i {
					margin-right: 5px;
				}
			}
		}

		&-item {
			width:100%;
			height: 100%;
			background-size:cover;
			background-position:center;
			background-repeat:no-repeat;
			display:flex;
			flex-flow:column wrap;
			justify-content: center;
			align-items:center;
			overflow:hidden;
			position:relative;
			cursor: pointer;


			&:hover {

				&:before {
					opacity:0.75;
				}

				.plus-icon {
					bottom:-100px;
					right:-100px;
				}
			}

			&:before {
				content:'';
				background: $grey-2;
				position:absolute;
				top:0;
				right:0;
				bottom:0;
				left:0;
				opacity:0.5;
				transition:all 300ms ease;

			}

			& > * {
				position:relative;
				z-index: 10;
			}

			h2 {
				color:white;
				text-transform:uppercase;
				font-family:$helv-black;
				font-size:8rem;
				line-height: 8rem;
				text-align: center;

				@media screen and (max-width:1200px) {
					font-size:6rem;
					line-height: 6rem;
				}

				@include mq('xsmall') {
					font-size:3rem;
					line-height: 3rem;
				}

				span {
					display:block;
					font-family:$helv-bold;
					font-size:4.5rem;
					line-height:4.5rem;
					margin-bottom: 10px;

					@include mq('small') {
						font-size:3rem;
						line-height: 3rem;
					}

					@include mq('xsmall') {
						font-size:2.5rem;
						line-height: 2.5rem;
					}

				}
			}

			.plus-icon {
				position:absolute;
				bottom:-200px;
				right:-200px;
				display:flex;
				flex-flow:row wrap;
				justify-content: center;
				align-item:center;
				width:200px;
				height:200px;
				background:$orange;
			    transform: rotate(45deg);
			    transition:all 300ms ease;


				&:before,
				&:after {
					content:'';
					position:absolute;
					top:0;
					right:110px;
					bottom:0;
					left:0;
					display:block;
					width:18px;
					height:2px;
					background:white;
					margin:auto;
				}

				&:before {
					transform:rotate(45deg);
				}

				&:after {
					transform:rotate(135deg);
				}
			}
		}
	}

	&__footer {
		padding:40px 0px;

		&-links {
			display:flex;
			flex-flow:row wrap;
			justify-content: center;
			align-items:center;
			list-style-type:none;
			margin-bottom:0;

			li {
				margin:10px 20px;


				a {
					font-size:1.6rem;
					color:$grey-2;
					text-transform:uppercase;

					&:hover {
						color:$blue;
					}
				}

				&:last-child {
					a {
						margin:0px 8px;
					}
				}
			}
		}
	}
}