.c-marquee {
	overflow: hidden;
}

.c-marquee_wrapper {
	display: inline-flex;
	will-change: transform;
}

.c-marquee_content {
	display: flex;
	align-items: center;
	gap: 40px;

	h1 {
		display: flex;
		align-items: center;

		&::after {
			content: url("../images/svg/dot.svg");
			margin-left: 40px;
		}

		&:last-child {
			margin-right: 40px;
		}
	}
}
