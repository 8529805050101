.c-button {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 22px 40px;
	border-radius: 8px;
	background-color: $red;
	color: $white;
	text-transform: uppercase;
	font-size: 14px;

	&:hover {
		background-color: lighten($red, 10%);
	}
	&.btn-white {
		background: $white;
		color: $blue;
	}

	&.-disabled {
		cursor: wait;
	}
}

.btn-red {
	padding: 22px 40px;
	border-radius: 8px;
	background-color: $red;
	color: $white;
	text-transform: uppercase;
	font-size: 14px;

	&:hover {
		background-color: lighten($red, 10%);
	}
}

.btn-blue {
	padding: 22px 40px;
	border-radius: 8px;
	background-color: $blue;
	color: $white;
	text-transform: uppercase;
	font-size: 14px;

	&:hover {
		background-color: lighten($blue, 10%);
	}
}

.btn-black {
	flex-shrink: 0;
	padding: 22px 40px;
	border-radius: 8px;
	background-color: $grey-3;
	color: $white;
	text-transform: uppercase;
	font-size: 14px;

	&:hover {
		background-color: lighten($grey-3, 10%);
	}
}
