.c-blog-single {
	padding: 100px 0px 0px;
	background: $grey-bg;

	h1 {
		max-width: 100%;
		margin-bottom: 40px;
	}

	p {
		@include mq(small) {
			font-size: 1.6rem;
			line-height: 2.4rem;
		}
	}

	.date {
		display: block;
		margin-bottom: 10px;
		color: $grey-1;
		text-transform: uppercase;
		font-family: $helv-bold;
		font-size: 1.5rem;
		line-height: 2rem;
	}

	&__main-img {
		width: 100%;
		height: 700px;
		margin-bottom: 60px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		@include mq(medium) {
			height: 500px;
		}

		@include mq(small) {
			height: 400px;
		}

		@include mq(xsmall) {
			height: 300px;
		}
	}

	.col_1x1 {
		padding: 0px 50px;

		@include mq(small) {
			padding: 0px;
		}
	}

	h3 {
		margin-bottom: 20px;
		color: $grey-2;
		font-family: $helv-regular;
		font-weight: 300;
		line-height: 3rem;
		fnt-size: 2.2rem;

		&:not(:first-of-type) {
			margin-top: 40px;
		}
	}

	&__nav {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		margin: 60px 0px;
		padding: 40px 0px !important;
		border-top: 1px solid $grey-border-2;
		border-bottom: 1px solid $grey-border-2;

		@include mq(xsmall) {
			align-items: center;
			flex-flow: column nowrap;
			justify-content: center;
		}

		a {
			display: flex;
			align-items: center;
			flex-flow: row wrap;
			color: $grey-2;

			&:hover {
				color: $grey-3;
			}

			i {
				color: $red;
				font-size: 1.6rem;
			}
		}

		span {
			display: inline-block;
			margin: 0px 10px;
			color: $grey-2;
			font-family: $helv-regular;
			font-size: 1.6rem;
			font-weight: 300;
			line-height: 2.4rem;
		}
	}
}
