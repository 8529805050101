.c-modal {
	position: fixed;
	top: 0;
	right: -3000px;
	bottom: 0;
	width: 66%;
	padding: 40px;
	background-color: $white;
	overflow: auto;
	transition: all ease-in-out 500ms;
	z-index: 999999;

	@media (max-width: 767px) {
		width: 100%;
	}

	&.is-active {
		right: 0;
	}
}

.c-modal_header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
	padding-bottom: 40px;
	border-bottom: 1px solid #d0dce8;

	h1 {
		max-width: 100%;
		margin: 0;
	}

	img {
		cursor: pointer;
		transition: all ease-in-out 150ms;

		&:hover {
			opacity: 0.8;
		}
	}
}

.c-modal_form {
	padding: 0;
	background-color: $white;

	label {
		color: #212121;
		font-size: 18px;

		span {
			color: $red;
		}
	}

	input[type="text"],
	input[type="tel"],
	input[type="email"] {
		margin-bottom: 20px;
	}

	.wpcf7-form-control-wrap {
		width: 100%;
		margin-top: 10px;
	}

	.c-button {
		border: none;
		cursor: pointer;
	}

	.wpcf7-response-output {
		right: 10%;
		bottom: 10%;
		left: unset;
	}
}

.c-modal_button {
	position: fixed;
	right: 32px;
	bottom: 0;
	display: flex;
	gap: 10px;
	padding: 16px 24px;
	background-color: $darkblue;
	cursor: pointer;
	transition: all ease-in-out 150ms;

	&:hover {
		background-color: #0054a4;
	}

	p {
		margin: 0;
		color: $white;
	}
}
