/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	"xsmall": (
		max-width: 479px,
	),
	"small": (
		max-width: 768px,
	),
	"medium": (
		max-width: 992px,
	),
	"large": (
		max-width: 1200px,
	),
	"xxl": (
		max-width: 1440px,
	),
);

/* =======================================================================
Font-face
========================================================================== */

@font-face {
	font-family: "Helvetica-neue-condensed-regular";
	src: url("../fonts/HelveticaNeueCondensed/HelveticaNeue-Condensed.ttf") format("truetype"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-Condensed.woff") format("woff"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-Condensed.woff2") format("woff2"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-Condensed.eot"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-Condensed.eot#iefix") format("embedded-opentype");
}

@font-face {
	font-family: "Helvetica-neue-condensed-bold";
	src: url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBold.ttf") format("truetype"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBold.woff") format("woff"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBold.woff2") format("woff2"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBold.eot"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBold.eot#iefix") format("embedded-opentype");
}

@font-face {
	font-family: "Helvetica-neue-condensed-black";
	src: url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBlack.ttf") format("truetype"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBlack.woff") format("woff"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBlack.woff2") format("woff2"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBlack.eot"), url("../fonts/HelveticaNeueCondensed/HelveticaNeue-CondensedBlack.eot#iefix") format("embedded-opentype");
}

/* =======================================================================
Variables
========================================================================== */

// Colors
$blue: #0f46a0;
$darkblue: #24388a;
$lightblue: #eef4ff;
$orange: #eb0a0f;
$red: #eb0a0f;
$red-700: #c2271d;
$grey-massive: #edf0f0;
$grey-bg: #fbfbfb;
$grey-bg-2: #f4f5f5;
$grey-border: #f0f0f0;
$grey-border-2: #e0e0e0;
$grey-1: #89949f;
$grey-2: #3f474e;
$grey-3: #212121;
$grey-input: #ebecec;

$green: #01a400;
$green-2: #14ae5c;
$green-700: #0f954d;
$white: #fff;

$border-100: #d0dce8;

// Typography
$helv-regular: "Helvetica-neue-condensed-regular";
$helv-bold: "Helvetica-neue-condensed-bold";
$helv-black: "Helvetica-neue-condensed-black";
